import { takeLatest, put, call, select, actionChannel } from "redux-saga/effects";
import { COLLECTION_USER_NAME } from '../../Constants';

import fetch from "node-fetch";
import path from 'path';
import axios from "axios";
import MyDocument from '../exportpdf/mydocument';
import { pdf } from '@react-pdf/renderer';

import React, { Component } from "react";

import {
  UPDATE_FIELD_ATTRIBUTE,
  INIT_FORM_SECTION_STEP1,
  INIT_ALL_FORMS,
  SUBMIT_FORM,
  REDUX_CHANGE,
  INCREASE_STEP,
  SALVAR_STEP,
  SUBMIT_FINAL_FORM,
  FORM_NAME_STEP1,
  FORM_NAME_STEP2,
  FORM_NAME_STEP3,
  FORM_NAME_STEP4,
  FORM_NAME_STEP5,
  FORM_NAME_STEP6,
  LOAD_CATALOG,
  CREATE_CATALOG,
  CHARGE_CATALOG_DINAMIC,
  SAVE_FORM_FILES,
  SUMAR_DATA,
  REDUX_BLUR,
  INIT_FORMS,
  ADD_RECURSO_AHORRO,
  NEW_RECURSO_ADD,
  DELETE_RECURSO_TABLE,
  getRecursosEconomicos,
  ADD_INTHOGAR,
  UPLOAD_FILE,
  REMOVE_FILE,
  SAVE_FIRMA,
  RENDER_PDF,
  DELETE_ANEXO,
  REQUIRED_INFORMACION_LABORAL2,
  REQUIRED_INFORMACION_BASICA2,
  REQUIRED_DATOS_POSTULANTE2,
  REQUIRED_CONDICION_HOGAR2,
  REQUIRED_ENTIDAD_CREDITO2,
  REQUIRED_INFORMACION_POSTULACION2,
  REQUIRED_MODALIDAD_VIVIENDA2,
  REQUIRED_VALOR_CONSTRUCCION2,
  REQUIRED_AHORRO_PREVIO,
  REQUIRED_RECURSO_COMPLEMENTARIOS,
  REQUIRED_INTHOGAR_POSTULANTE2,
  REQUIRED_FINANCIACION_TOTAL2,
  DISABLED,
  REQUIRED_CONDICION_HOGAR,
  DELETE_FIRMA,
  HEADERS_WS,
  LOGOUT_SESION,
  getAhorroPrevio,
  getAhorroPrevio2,
  getRecursosComplementarios,
  GET_FIREBASE_USER_SESSION,
} from './constants';

import {
  saveEraserSuccess,
  loadCatalogSuccess,
  formInitialized,
  saveFinalSuccess,
  loadDocumentSuccess,
  chargedCatalog,
  cargarCatalogos,
  updateIngreStep2,
  updateDeleteStep2,
  updateIngreStep4,
  updateDeleteStep4,
  callDinamicDisable,
  hiddenDisable,
  chargedValidator,
  activateRadio,
  hiddenClose,
  optionsTable,
  addRecursoAhorro,
  base64Add,
  base64Remove,
  contactoAdd,
  reloadPage,
  saveRefFiles,
  generatePdf,
  anexosStorage,
  saveDataExt,
  initValidate,
  referenceFiles,
  increaseSiguiente,
  saveRefirmas,
  guardarItems,
  limpiarAdjuntos,
  incrementDecrement,
  enabledDisabledArray,
  rebootForm,
  saveCatalogoCiclos,
  changeStep,
  integrantesObligation,
  saveToUploadFile,
  step3Completo,
  openModalEspera,
  setFirebaseUserSession,
  saveUserColaborador,
} from './actions';

import { reset, change, initialize } from "redux-form";
import {
  getFormValuesStep4,
  getIdFormulario,
  fullFormSelector,
  getFormInitialized,
  getUserId,
  getCatalogosCargados,
  getCompanyValuesStep2,
  getUpdateIngreStep2,
  getUpdateIngreStep4,
  getCompanyValuesStep4,
  getCompanyValuesStep3,
  getCompanyValuesStep1,
  getAddRecursoAhorro,
  getAddRecursoComplementario,
  getCuentasCredito,
  getFormFiles,
  getbase64Add,
  getFormFirmas,
  getRefFiles,
  getHoodId,
  getActiveStep,
  getDynamicOptions,
  getReferenceFiles,
  getRefFirmas,
  getItemSaved,
  getCatalogoCiclos,
  getFormValuesStep2,
  getFormValuesStep1,
  getFormValuesStep3,
  getIntegrantesObligatorios,
  getFirebaseUserInfo,
  getUserColaborador
} from "./selectors";

import { firebaseDatabase, firebaseStorage } from "../../controller/firebase";
import { getFromSession, logout, saveInSession } from "../../controller/session";
import { emptyObject, getAsyncCatalog, emptyCero, getBase64, emptyObjectEmp, getAsyncCollection, onlyUnique, exist, removeAccents, cortarMSM, letras_numbers, notificaFalloWBS, cuantosSalarios, validOportunidad, emptyJson } from "../../controller/utils";
import { dataAuth } from "../contactos/actions";
import { toInteger, values } from "lodash-es";
import { CallToActionOutlined } from "@material-ui/icons";
import { userInfo } from "os";



function* submitForm(action) {
  const { value } = action;

  try {
  } catch (error) {
    console.error(error);
  }
}

function definirParametros(action) {
  /* Función que recibe un parametro del plan de vivienda y retorna el codigo 
      correspóndiente que es el que se envia a C4C
  */
  const { tp, pv, sh } = action;
  var res = '';
  if (action) {
    if (tp === "urbano") {
      if (pv === "VIS") {
        if (sh === "1") {
          res = "2-1"
        } else {
          res = "2-2"
        }
      }
      if (pv === "VIP") {
        if (sh === "1") {
          res = "2-3"
        } else {
          res = "2-4"
        }
      }
      if (pv === "VIPA") {
        if (sh === "1") {
          res = "2-5"
        } else {
          res = "2-6"
        }
      }
    } else {
      if (pv === "VIS") {
        if (sh === "1") {
          res = "2-7"
        } else {
          res = "2-8"
        }
      }
      if (pv === "VIP") {
        if (sh === "1") {
          res = "2-9"
        } else {
          res = "2-10"
        }
      }
      if (pv === "VIPA") {
        if (sh === "1") {
          res = "2-11"
        } else {
          res = "2-12"
        }
      }
    }
  }
  return res;
}

function* updateSingleField(action) {
  const { value } = action;

  try {
    yield put(change(FORM_NAME_STEP1, 'requestDescription', 'test.jvera'));
  } catch (error) {
    console.error(error);
  }
}

function* consumeValidador(action) {
  /* Función que recibe el documento y tipo de documento del afiliado
      y consulta el validador de derechos y retorna la información del afiliado+
   */

  const { id, tipo } = action;
  const messageid = tipo + id;
  var date = "";
  var date = new Date();

  const urlConfenalco = process.env.REACT_APP_URL_COMFENALCO_ENDPOINT;
  const headers = HEADERS_WS;
  const body = {

    "requestMessageOut": {
      "header": {
        "systemId": "FORMULARIOSWEB",
        "invokerDateTime": date,
        "destination": {
          "namespace": "http://co/com/comfenalcovalle/esb/ws/PksiryacValidarServicios",
          "name": "ValidadorServiciosCaja",
          "operation": "execute"
        },
        "messageId": tipo + "|" + id,
        "securityCredential": {
          "userToken": "",
          "userName": ""
        },
        "moduleId": "VALIDADOR",
        "logginData": {
          "sourceSystemId": "",
          "destinationSystemId": ""
        },
        "classification": {
          "classification": ""
        }
      },
      "body": {
        "request": {
          "validadorRequest": {
            "idPers": "-1",
            "abreviatura": tipo,
            "identificacion": id,
          }
        }
      }
    }

  }

  var resul = {}
  try {
    console.time("inicio");

    const res = yield consumeServiceGenerico({ body: body, url: urlConfenalco })
    console.log("respuesta validador", res)
    if (res.data.responseMessageOut.header.responseStatus.statusCode === "SUCESS") {
      resul = res.data.responseMessageOut.body.response.validadorResponse;
    } else {
      yield put(contactoAdd({ message: "No fue posible acceder al formulario refresque la página o intentelo nuevamente mas tarde, Comuniquese a atención al cliente de Comfenalco Valle Delagente.", error: true }));
    }

    console.timeEnd("inicio");
    return resul;
  } catch (error) {
    console.error(error, "error")
    return error;
  }
}

function* obtenerIp(action) {
  /* Funcion que consulta la dirección ip desde la cual se esta haciendo la peticion  
  y la compara con la que tiene registrada para determinar si tiene o no acceso al sitio 
   */
  const { ip } = action;
  var resul = false;
  try {
    const response = yield call(axios.get, 'https://api.ipify.org?format=json');
    if (response.data?.ip) {
      console.log("hola tu ip es : ", response.data.ip);
      ip.forEach(element => {
        if (element.value === response.data.ip) {
          resul = true;
          return;
        }
      });
    }
    return resul;
  } catch (error) {
    console.error(error);
    throw `optenerIp - ${error}`;
  }
}

function* pdfgenera(action) {
  /* función que recibe los datos del formulario, genera el pdf con esos datos
    luego saca el base64 de ese pdf y lo almacena en local con los otros archivos adjuntos
    con el nombre de pdfFormulario
   */
  const { id, items } = action;
  try {
    const resul = yield pdf(<MyDocument data={items} />).toBlob();

    if (resul) {
      const res = yield getBase64(resul)
      const type = resul.type;
      const aux = res.split(",")
      var base = yield select(getbase64Add)
      const temBase = []
      base.forEach(element => {
        if (element.name !== "pdfFormulario") {
          temBase.push(element)
        }
      });
      const temp = {
        name: "pdfFormulario",
        doc: {
          contenidoArchivo: aux[1],
          extensionArchivo: type,
          nombreArchivo: items.oportunidad,
        }
      }
      temBase.push(temp)
      yield put(base64Remove(temBase));
    }
  } catch (error) {
    console.error("function.pdfGenera", error);
    throw `pdfGenera - ${error}`;
  }
}

function* consumeActualizaOportunidad(action) {
  /*Función que actualiza la oportunidad
   *Recibe, el tipo de identificacion y el numero, la fase de venta, y una nota si la hay
   *Si el formulario de vivienda genero algun error se envia un json diferente 
   */
  const { id, tipo, faseVenta, idOportunidad, ban, nota } = action;
  const messageid = tipo + id;
  var date = "";
  var date = new Date();
  const base = yield select(getbase64Add);
  var archivo = [];
  if (base && base.length > 0) {
    base.forEach(element => {
      if (ban) {
        archivo.push(element.doc)
      } else {
        if (element.name === "pdfFormulario") {
          archivo.push(element.doc)
        }
      }

    });

  } else {
    archivo = null;
  }

  var infoOportu = {}
  if (faseVenta === "Z01") {
    infoOportu = {
      "idOportunidad": idOportunidad,
      "faseVenta": faseVenta,
      "archivo": archivo,
      "nota": nota
    }
  } else {
    infoOportu = {
      "idOportunidad": idOportunidad,
      "motivoInhabilita": "Z75",
      "estado": "5",
      "faseVenta": faseVenta,
      "archivo": archivo,
      "nota": nota
    }
  }

  const urlConfenalco = process.env.REACT_APP_URL_COMFENALCO_ENDPOINT;
  const token = Buffer.from('FORMULARIOSWEB:Yk2190_w', 'utf8').toString('base64')
  const autho = {};
  const headers = HEADERS_WS;
  const bodyOp = {
    "requestMessageOut": {
      "header": {
        "invokerDateTime": date,
        "moduleId": "OPORTUNIDAD",
        "systemId": "FORMULARIOSWEB",
        "messageId": messageid,
        "logginData": {
          "sourceSystemId": "",
          "destinationSystemId": ""
        },
        "destination": {
          "namespace": "http://co/com/comfenalcovalle/esb/ws/CRMCreaActualizaOportunidad",
          "name": "CRMCreaActualizaOportunidad",
          "operation": "execute"
        },
        "securityCredential": {
          "userName": "",
          "userToken": ""
        },
        "classification": {
          "classification": ""
        }
      },
      "body": {
        "request": {
          "oportunidad": {
            "accion": "2",
            "infoOportunidad": infoOportu,
          }
        }
      }
    }
  }
  var resul = {}
  try {
    console.time("inicio");
    console.log("body actualiza oportunidad", bodyOp)

    const res = yield consumeServiceGenerico({ body: bodyOp, url: urlConfenalco })
    console.log(res)
    console.log("respuesta actualiza oportunidad", res)
    if (res.data.responseMessageOut.header.responseStatus.statusCode === "SUCESS") {
      resul = res.data.responseMessageOut.body.response.idOportunidad;
    } else {
      var motivoInha = "";
      if (emptyObject(infoOportu?.motivoInhabilita).toString().length > 0) {
        motivoInha = " Motivo inhabilita : Z75 ";
      }
      var estado = '';
      if (emptyObject(infoOportu?.estado).toString().length > 0) {
        estado = " Estado : 5, ";
      }
      //const dataF = `Fase de venta : ${emptyObject(infoOportu.faseVenta)} ${estado} ${motivoInha}`
      //yield enviarEmail({ oportunidad: idOportunidad, data: dataF })
      notificaFalloWBS({ body: bodyOp, webService: "Actualiza la oportunidad", response: res.data.responseMessageOut.body })
    }

    console.timeEnd("inicio");
  } catch (error) {
    console.error(error, "error")
  }
  return resul;
}

function* consumeOportunidad(action) {

  const { id, tipo, info, items, idFormulario } = action;
  const messageid = tipo + id;
  var date = "";
  var date = new Date();
  var middlename = ""
  if (info?.middleName) {
    middlename = info?.middleName;
  }
  var apell = info?.lastName?.split(" ");
  if (apell?.length < 2) {
    apell.push(" ")
  }

  var tp = items?.step3?.informacionbasica?.homeType;
  var pv = items?.step3?.informacionbasica?.planHomeVivienda;
  var sh = '1';
  if (items?.step2?.integrantesHogar?.inthogarpostulantes?.length > 0) {
    sh = '2'
  }
  var idOporFormulario = definirParametros({ tp: tp, pv: pv, sh: sh })
  var archivo = [];
  const ip = yield call(axios.get, 'https://api.ipify.org?format=json');

  const urlConfenalco = process.env.REACT_APP_URL_COMFENALCO_ENDPOINT;
  const token = Buffer.from('FORMULARIOSWEB:Yk2190_w', 'utf8').toString('base64')
  const autho = {};
  const headers = HEADERS_WS;
  const body = {

    "requestMessageOut": {
      "header": {
        "invokerDateTime": date,
        "moduleId": "OPORTUNIDAD",
        "systemId": "FORMULARIOSWEB",
        "messageId": messageid,
        "logginData": {
          "sourceSystemId": "",
          "destinationSystemId": ""
        },
        "destination": {
          "namespace": "http://co/com/comfenalcovalle/esb/ws/CRMCreaActualizaOportunidad",
          "name": "CRMCreaActualizaOportunidad",
          "operation": "execute"
        },
        "securityCredential": {
          "userName": "",
          "userToken": ""
        },
        "classification": {
          "classification": ""
        }
      },
      "body": {
        "request": {
          "oportunidad": {
            "accion": "1",
            "cliente": {
              "tipoIdentificacion": emptyObject(info.documentType),
              "identificacion": emptyObject(info.numberId),
              "primerNombre": emptyObject(info.firstName),
              "primerApellido": emptyObject(apell[0]),
              "segundoNombre": emptyObject(middlename),
              "segundoApellido": emptyObject(apell[1]),
              "fechaNacimiento": emptyObject(info.birthdate),
              "genero": emptyObject(info.sex),
              "celular": emptyObject(info.companyPhone),
              "telefono": emptyObject(info.phone),
              "email": emptyObject(info.companyEmail),
              "fuenteHabeasData": true,
              "json": `IP:${ip?.data?.ip}`
            },
            "infoOportunidad": {
              "nombre": "POSTULACIÓN AL SUBSIDIO DE VIVIENDA",
              "estado": null,
              "origen": null,
              "prioridad": null,
              "calificacion": null,
              "servicio": null,
              "categoria": null,
              "unidadVenta": null,
              "canalDistribucion": null,
              "idProducto": "AFVT12",
              "idFormularioOpor": idOporFormulario,
              "nota": "Oportunidad creada por medio del formulario de subsidio de vivienda Web",
              "archivo": null,
            }
          }
        }
      }
    }
  }

  var resul = {}
  try {
    console.time("inicio");
    console.log("body oportunidad", body)
    // const data = [];
    // data.push(urlConfenalco)
    // data.push(headers)
    // data.push(body)
    // const ax = (data) => {
    //   return axios({ method: "post", url: data[0], data: data[2], Headers: data[1] })
    // }
    // console.log("respuesta oportunidad", res)
    const res = yield consumeServiceGenerico({ body, url: urlConfenalco })
    console.log("respuesta oportunidad", res)

    if (res.data.responseMessageOut.header.responseStatus.statusCode === "SUCESS") {
      resul = res.data.responseMessageOut.body.response.idOportunidad;
      items.oportunidad = resul;
      yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_VIVIENDA).doc(idFormulario).set({ oportunidad: resul }, { merge: true });

      yield pdfgenera({ id: idFormulario, items: items });
      yield consumeWsVivienda({ info, items: items, oportunidad: resul, idFormulario });


    } else {
      notificaFalloWBS({ body: body, webService: "Crea oportunidad", response: res.data.responseMessageOut.body })
      yield put(reloadPage({ reload: true }));
      yield put(contactoAdd({ message: "La petición ha finalizado, su solicitud no pudo ser procesada comuniquese a servicio al cliente: ", error: true }));
      let respuesta = res.data.responseMessageOut?.body?.exceptionList?.exception?.description ? res.data.responseMessageOut?.body?.exceptionList?.exception?.description : "Time out";

      yield enviarEmail({ oportunidad: "no se creo oportunidad", data: respuesta })
    }
    console.timeEnd("inicio");
  } catch (error) {
    console.error(error, "error")
  }
  return resul;
}

function getFechaHoy() {
  const fecha = new Date();
  let dia = fecha.getDate();
  let mon = fecha.getMonth() + 1;
  if (fecha.getDate() < 10) {
    dia = `0${fecha.getDate()}`;
  }
  if (mon < 10) {
    mon = `0${mon}`;
  }

  return `${fecha.getFullYear()}-${mon}-${dia}`
}

function* consumeWSConsultarPlanes(action) {
  //ciclo : si es urbano o rural, Z02-Z03
  //plan vivienda VIS=1, VIP=2, VIPA=3
  //modalidad, vivienda nueva, usada, construccion en sitio propio o mejoramiento

  const { id, tipo, ingreso } = action;
  const messageid = tipo + id;
  var date = "";
  var date = new Date();

  const urlConfenalco = process.env.REACT_APP_URL_COMFENALCO_ENDPOINT;
  const headers = HEADERS_WS;
  const body = {
    "requestMessageIn": {
      "header": {
        "invokerDateTime": date,
        "moduleId": "VIVIENDA",
        "systemId": "PORTAL",
        "messageId": `${messageid}|122231`,
        "logginData": {

        },
        "destination": {
          "namespace": "http://co/com/comfenalcovalle/esb/ws/CRMConsultarPlanesVivienda",
          "name": "CRMConsultarPlanesVivienda",
          "operation": "execute"
        },
        "securityCredential": {
          "userName": "",
          "userToken": ""
        },
        "classification": {

        }
      },
      "body": {
        "request": {
          "consultaPlanesViviendaRequest": {
            //"ingresoMensual": "2500000",
            // "fechaSubsidio": "2021-12-15",
            "ingresoMensual": ingreso,
            "fechaSubsidio": getFechaHoy(),
            "cicloVenta": "",
            "planVivienda": "",
            "modalidadSubsidio": ""
          }
        }
      }
    }
  }
  var resul = false;
  console.log("Body de consulta de planes", body);
  try {

    const res = yield consumeServiceGenerico({ body: body, url: urlConfenalco })
    console.log("respuesta wsConsultarPlanes", res)
    if (res.data.responseMessageOut.header.responseStatus.statusCode === "SUCESS") {
      resul = res.data.responseMessageOut.body.response.planesViviendaResponse;
    }
    return resul;
  } catch (error) {
    console.error(error, "error")
    return error;
  }
}

function* homologarRespuesta(action) {

  try {
    const catalogo = yield select(getCatalogosCargados);
    const homolog = catalogo.respuesta;

    var result = "No cumple con criterios de validación del sistema de postulación, comuniquese a servicio al cliente"
    if (action) {
      const temp = action.split("Note: ");
      const res = temp[1].split("\nTypeID");
      const resul = res[0].split(",");

      if (resul.length > 1 && resul[1]) {
        result = resul[1];
      } else {
        homolog.forEach(element => {
          //var aux = element.split(";");
          var temp = res[0].split(" ");
          if (element.value === temp[4]) {
            result = element.label;
            return;
          }

        });
      }

    }
    return result;
  } catch (error) {
    console.error("function.homologarRespuesta", error)
  }

}

function* mayor(action) {
  try {
    const valor = (parseInt(action[0].safeValueA.value) + parseInt(action[1].safeValueA.value)).toString();
    var respuesta = {
      openDateA: action[0].openDateA.value,
      entityA: action[0].entityA.value,
      safeValueA: valor,
    }
    var fechauno = new Date(action[0].openDateA.value);
    var fechados = new Date(action[1].openDateA.value);
    var resultado = fechados.getTime() < fechauno.getTime();
    if (resultado) {
      respuesta.openDateA = action[1].openDateA.value;
    }
    if (parseInt(action[1].safeValueA.value) > parseInt(action[0].safeValueA.value)) {
      respuesta.entityA = action[1].entityA.value;
    }
    return respuesta;
  } catch (error) {
    console.error("function.mayor", error)
  }

}

function* consumeWsVivienda(action) {
  const { info, oportunidad, items, idFormulario } = action;
  const { step1, step2, step3, step4 } = items;
  const id = step1.datospostulante.numberId;
  const tipo = step1.datospostulante.documentType;
  const messageid = tipo + id;
  var date = "";
  var date = new Date();
  const urlConfenalco = process.env.REACT_APP_URL_COMFENALCO_ENDPOINT;
  const token = Buffer.from('FORMULARIOSWEB:Yk2190_w', 'utf8').toString('base64')
  const inthogarPostulan = [];

  const catalogo = yield select(getCatalogosCargados);
  var modalidad = "";
  const valModali = step3?.informacionbasica?.homeModeType;
  catalogo.homeModeType.forEach(element => {
    if (element.value === valModali) {
      modalidad = element.label;
    }
  });

  var laEMPname = '';
  var laEMPplace = '';
  var laEMPphone = '';

  if (step1?.informacionlaboral?.laborandoSelect) {
    if (step1.informacionlaboral.laborandoSelect === "otraEmpresa") {
      laEMPname = emptyObject(step1.informacionlaboral.companyJobName);
      laEMPplace = emptyObject(step1.informacionlaboral.companyJobAddress);
      laEMPphone = emptyObject(step1.informacionlaboral.companyPhoneJob);
    } else {
      laEMPname = emptyObject(step1.informacionlaboral.subsidioData.name);
      laEMPplace = emptyObject(step1.informacionlaboral.subsidioData.place);
      laEMPphone = emptyObject(step1.informacionlaboral.subsidioData.phone);
    }
  }

  if (step2?.integrantesHogar?.inthogarpostulantes) {
    step2.integrantesHogar.inthogarpostulantes.forEach(element => {
      //TODO: validar campos no obligatorios
      inthogarPostulan.push({
        "zNombreApellido": element.namePostu,
        "zFechaNacimiento": element.birthdatePostu,
        "zTipoDocumento": element.documentTypePostu,
        "zDocIdentididadAfiliado": element.idPostu,
        "zSexoAfiliado": homologaSexo(element.sexPostu),
        "zParentescoAfil": element.relativePostu,
        "zOcupacionAfil": element.jobPostu,
        "zEstadoCivilAfil": element.maritalstatusPostu,
        "zipoPostulanteAfil2": emptyObject(element.postulantePostu),
        "zIngresoMensualAfil2": {
          "value": conversion(element.ingresosMensual),
          "currencyCode": "COP"
        },
        "zCondicionEspecialAfil": emptyObject(element.specialPostu),
        "zNombreCaja": element.nombrecajaPostu
      });
    });
  }

  var modalidadvivienda = {}
  if (step3.modalidadvivienda && step3.modalidadvivienda.projecttype) {
    modalidadvivienda = {
      "Code": step3.modalidadvivienda.projecttype,
      "zPropiedadLote": step3.modalidadvivienda.propiedadlote,
      "zNumeroMatriculaInmob": step3.modalidadvivienda.predioaddress,
      "zDireccionLote": step3.modalidadvivienda.loteaddress,
      "zMunicipioMovil": step3.modalidadvivienda.cityModalidad,
      "zNombreUrbanizacion": step3.modalidadvivienda.urbanizacion,
      "zManzana": step3.modalidadvivienda.apple,
      "zNroLote": step3.modalidadvivienda.lotenumber,
      "zArealote": emptyCero(step3.modalidadvivienda.areaLoteM2),
      "zFrente": emptyCero(step3.modalidadvivienda.frenteLote),
      "zFondo": emptyCero(step3.modalidadvivienda.fondoLote),
      "zLicenciaConstruccion": step3.modalidadvivienda.licencia,
      "zVencimiento": step3.modalidadvivienda.vencimientoLicencia,
      "zEscritura": step3.modalidadvivienda.escritura,
      "zFechaEscritura": step3.modalidadvivienda.escriturafecha,
      "zNotaria": step3.modalidadvivienda.notariaEscritura,
      "zFechaRegistro": step3.modalidadvivienda.registertestdate
    }
  } else {
    modalidadvivienda = {
      "Code": null,
      "zPropiedadLote": null,
      "zNumeroMatriculaInmob": null,
      "zDireccionLote": null,
      "zMunicipioMovil": null,
      "zNombreUrbanizacion": null,
      "zManzana": null,
      "zNroLote": null,
      "zArealote": "0.00",
      "zFrente": "0.00",
      "zFondo": "0.00",
      "zLicenciaConstruccion": null,
      "zVencimiento": null,
      "zEscritura": null,
      "zFechaEscritura": null,
      "zNotaria": null,
      "zFechaRegistro": null
    }
  }
  var recursoseconomicos = {
    "zCuentaAhorroProg": {
      "value": "0.00",
      "currencyCode": "COP"
    },
    "zApotesPeriodicos": {
      "value": "0.00",
      "currencyCode": "COP"
    },
    "zCuentaAhorroContractual": {
      "value": "0.00",
      "currencyCode": "COP"
    },
    "zCesantias": {
      "value": "0.00",
      "currencyCode": "COP"
    },
    "zAporteLoteTerreno": {
      "value": "0.00",
      "currencyCode": "COP"
    },
    "zAporteAvance": {
      "value": "0.00",
      "currencyCode": "COP"
    },
    "zCuotaInicial": {
      "value": "0.00",
      "currencyCode": "COP"
    },
    "zOtrosRecursos": {
      "value": "0.00",
      "currencyCode": "COP"
    },
    "zTotalAhorroPrevio": {
      "value": "0.00",
      "currencyCode": "COP"
    },
    "zCreditoAprobado": {
      "value": "0.00",
      "currencyCode": "COP"
    },
    "zAportesSolitarios": {
      "value": "0.00",
      "currencyCode": "COP"
    },
    "zAportesEnteTerr": {
      "value": "0.00",
      "currencyCode": "COP"
    },
    "zDonacion": {
      "value": "0.00",
      "currencyCode": "COP"
    },
    "zOtrosRecursosComple": {
      "value": "0.00",
      "currencyCode": "COP"
    },
    "zTotalRecursos": {
      "value": "0.00",
      "currencyCode": "COP"
    },
    "zCuentaAhorroProgFecha": null,
    "zCuentaAhorroProgEntidadID": null,
    "zAportesPeriodicosFecha": null,
    "zAportesPeriodicosEntidadID": null,
    "zCuentaAhorroContractualFecha": null,
    "zCuentaAhorroContractualEntidadID": null,
    "zCesantiasFecha": null,
    "zCesantiasEntidadID": null,
    "zAporteLoteTerrenoFecha": null,
    "zAporteLoteTerrenoEntidadID": null,
    "zAporteAvanceFecha": null,
    "zAporteAvanceEntidadID": null,
    "zCuotaInicialFecha": null,
    "zCuotaInicialEntidadID": null,
    "zOtrosRecursosFecha": null,
    "zOtrosRecursosEntidadID": null
  }

  var informacionCuentas = {
    "zFechaAprobacion": null,
    "zFuentesFinanciamientoID": null,
    "zEntidadCreditoID": null
  }

  if (step4.ahorroprevio) {
    var aux1 = [];
    var aux2 = [];
    step4.ahorroprevio.forEach(element => {
      if (element.tiporecursoA.value === "1") {
        aux1.push(element);
      }
      if (element.tiporecursoA.value === "2") {
        aux2.push(element);
      }
    });

    if (aux1.length > 1) {
      var element1 = yield mayor(aux1);
      recursoseconomicos.zCuentaAhorroProg = {
        "value": element1.safeValueA,
        "currencyCode": "COP",
      }
      recursoseconomicos.zCuentaAhorroProgFecha = element1.openDateA;
      recursoseconomicos.zCuentaAhorroProgEntidadID = element1.entityA;
    } else if (aux1.length === 1) {
      recursoseconomicos.zCuentaAhorroProg = {
        "value": aux1[0].safeValueA.value,
        "currencyCode": "COP",
      }
      recursoseconomicos.zCuentaAhorroProgFecha = aux1[0].openDateA.value;
      recursoseconomicos.zCuentaAhorroProgEntidadID = aux1[0].entityA.value;
    }

    if (aux2.length > 1) {
      var element2 = yield mayor(aux2);
      recursoseconomicos.zCesantias = {
        "value": element2.safeValueA,
        "currencyCode": "COP",
      }
      recursoseconomicos.zCesantiasFecha = element2.openDateA;
      recursoseconomicos.zCesantiasEntidadID = element2.entityA;
    } else if (aux2.length === 1) {
      recursoseconomicos.zCesantias = {
        "value": aux2[0].safeValueA.value,
        "currencyCode": "COP",
      }
      recursoseconomicos.zCesantiasFecha = aux2[0].openDateA.value;
      recursoseconomicos.zCesantiasEntidadID = aux2[0].entityA.value;
    }

    step4.ahorroprevio.forEach(element => {

      if (element.tiporecursoA.value === "4") {
        recursoseconomicos.zCuentaAhorroContractual = {
          "value": element.safeValueA.value,
          "currencyCode": "COP",
        }
        recursoseconomicos.zCuentaAhorroContractualFecha = element.openDateA.value;
        recursoseconomicos.zCuentaAhorroContractualEntidadID = element.entityA.value;
      }
      if (element.tiporecursoA.value === "5") {
        recursoseconomicos.zApotesPeriodicos = {
          "value": element.safeValueA.value,
          "currencyCode": "COP",
        }
        recursoseconomicos.zAportesPeriodicosFecha = element.openDateA.value;
        recursoseconomicos.zAportesPeriodicosEntidadID = element.entityA.value;
      }

      if (element.tiporecursoA.value === "6") {
        recursoseconomicos.zCuotaInicial = {
          "value": element.safeValueA.value,
          "currencyCode": "COP",
        }
        recursoseconomicos.zCuotaInicialFecha = element.openDateA.value;
        recursoseconomicos.zCuotaInicialEntidadID = element.entityA.value;
      }
      if (element.tiporecursoA.value === "7") {
        recursoseconomicos.zAporteLoteTerreno = {
          "value": element.safeValueA.value,
          "currencyCode": "COP",
        }
        recursoseconomicos.zAporteLoteTerrenoFecha = element.openDateA.value;
        recursoseconomicos.zAporteLoteTerrenoEntidadID = element.entityA.value;
      }
      if (element.tiporecursoA.value === "8") {
        recursoseconomicos.zAporteAvance = {
          "value": element.safeValueA.value,
          "currencyCode": "COP",
        }
        recursoseconomicos.zAporteAvanceFecha = element.openDateA.value;
        recursoseconomicos.zAporteAvanceEntidadID = element.entityA.value;
      }
      if (element.tiporecursoA.value === "9") {
        recursoseconomicos.zOtrosRecursos = {
          "value": element.safeValueA.value,
          "currencyCode": "COP",
        }
        recursoseconomicos.zOtrosRecursosFecha = element.openDateA.value;
        recursoseconomicos.zOtrosRecursosEntidadID = element.entityA.value;
      }
    });
    recursoseconomicos.zTotalAhorroPrevio = {
      "value": step4.financiaciontotal.totalahorroprevio,
      "currencyCode": "COP"
    }

  }
  if (step4?.cuentasCredito) {
    informacionCuentas = {
      "zFechaAprobacion": emptyObject(step4?.cuentasCredito?.openDateFuente),
      "zFuentesFinanciamientoID": emptyObject(step4?.cuentasCredito?.fuentesFinanciamiento),
      "zEntidadCreditoID": emptyObject(step4?.cuentasCredito?.entityFuente),
    }
  }
  if (step4.recursoscomplementarios) {
    step4.recursoscomplementarios.forEach(element => {
      if (element.tiporecursoR.value === "1") {
        recursoseconomicos.zCreditoAprobado = {
          "value": element.safeValueR.value,
          "currencyCode": "COP"
        };
      }
      if (element.tiporecursoR.value === "2") {
        recursoseconomicos.zAportesSolitarios = {
          "value": element.safeValueR.value,
          "currencyCode": "COP"
        };
      }
      if (element.tiporecursoR.value === "3") {
        recursoseconomicos.zAportesEnteTerr = {
          "value": element.safeValueR.value,
          "currencyCode": "COP"
        };
      }
      if (element.tiporecursoR.value === "4") {
        recursoseconomicos.zDonacion = {
          "value": element.safeValueR.value,
          "currencyCode": "COP"
        };
      }
      if (element.tiporecursoR.value === "5") {
        recursoseconomicos.zOtrosRecursosComple = {
          "value": element.safeValueR.value,
          "currencyCode": "COP"
        };
      }

    });
    recursoseconomicos.zTotalRecursos = {
      "value": step4.financiaciontotal.totalrecursos,
      "currencyCode": "COP"
    }
  }
  var financiacion = {
    "zAhorroPrevioFinan": {
      "value": "0.00",
      "currencyCode": "COP"
    },
    "zRecursosComplementarios": {
      "value": "0.00",
      "currencyCode": "COP"
    },
    "zSolictarValorSubsidio": "2",
    "zSubsidioSolitado": {
      "value": "0.00",
      "currencyCode": "COP"
    },
    "zValorSFV": null,
    "zValorSubsidio": {
      "value": "0",
      "currencyCode": "COP"
    },
    "zValorTotalNuevaUsada": {
      "value": "0.00",
      "currencyCode": "COP"
    }
  }

  var totalIngresos = 0;
  var ingresosAfiliado = emptyCero(step2.condicionHogar.ingresos);
  if (step2.totalingresos) {
    var ttIngre = parseInt(step2.totalingresos?.totalingresos);
    var liqui = parseInt(step2.totalingresos?.salarioreportafiliacion);
    var ingre = parseInt(ingresosAfiliado);
    var ingreGrupo = ttIngre - ingre;
    totalIngresos = ingre + ingreGrupo;
    if (liqui > ingre) {
      totalIngresos = liqui + ingreGrupo;
      ingresosAfiliado = liqui.toString();
    }
  }

  if (step4.financiaciontotal) {
    financiacion = {
      "zAhorroPrevioFinan": {
        "value": emptyCero(step4.financiaciontotal.totalahorroprevio),
        "currencyCode": "COP"
      },
      "zRecursosComplementarios": {
        "value": emptyCero(step4.financiaciontotal.totalrecursos),
        "currencyCode": "COP"
      },
      "zSolictarValorSubsidio": "1",
      "zSubsidioSolitado": {
        "value": emptyCero(step4.financiaciontotal.valorsubcidiado),
        "currencyCode": "COP"
      },
      "zValorSFV": null,
      "zValorSubsidio": {
        "value": "0",
        "currencyCode": "COP"
      },
      "zValorTotalNuevaUsada": {
        "value": emptyCero(step4.financiaciontotal.valorvivienda),
        "currencyCode": "COP"
      }
    }
  }

  var valorSolucion = {
    "zValorPresupuesto": {
      "value": "0",
      "currencyCode": "COP"
    },
    "zValorLote": {
      "value": "0",
      "currencyCode": "COP"
    }
  }
  if (step3.valorconstruccion) {
    valorSolucion = {
      "zValorPresupuesto": {
        "value": step3.valorconstruccion.presupuesto,
        "currencyCode": "COP"
      },
      "zValorLote": {
        "value": step3.valorconstruccion.avaluocatastral,
        "currencyCode": "COP"
      }
    }
  }

  const headers = HEADERS_WS;

  const hoodId = yield select(getHoodId);

  const body = {

    "requestMessageIn": {
      "header": {
        "invokerDateTime": date,
        "moduleId": "VIVIENDA",
        "systemId": "PORTAL",
        "messageId": messageid,
        "logginData": {
          "sourceSystemId": "",
          "destinationSystemId": ""
        },
        "destination": {
          "namespace": "http://co/com/comfenalcovalle/esb/ws/InQueueCRMFormularioSubsidioVivienda",
          "name": "CRMFormularioSubsidioVivienda",
          "operation": "execute"
        },
        "classification": {
          "classification": ""
        }
      },
      "body": {
        "request": {
          "zCodigoOportunidad": emptyObject(oportunidad),
          "Importante": {
            "zOpcionSiNo": emptyObject(step3?.informacionbasica?.subsidio),
            "zNombreEntidad": emptyObject(step3?.informacionbasica?.entidadSubsidio)
          },
          "ModalidadVivienda": {
            "zModalidadVivienda2": emptyObject(step3?.informacionbasica?.homeModeType),
          },
          "TipoAfiliado": {
            "zTipoAfiliado": emptyObject(step3?.informacionbasica?.postulanteType),
          },
          "HogarPostulante": {
            "zNombreAfiliado": emptyObject(step2.condicionHogar.companyName),
            "zTipoDocumento": emptyObject(step1.datospostulante.documentType),
            "zDocumentoIdentidad": emptyObject(step1.datospostulante.numberId),
            "zExpedida": emptyObject(step1.datospostulante.idPlace),
            "zDireccionActual": emptyObject(step1.datospostulante.currentAddress),
            "zCorreoElectronico": emptyObject(step1.datospostulante.companyEmail),
            "zDepartamento": emptyObject(step1.datospostulante.departament),
            "zMunicipio": emptyObject(step1.datospostulante.city),
            "zBarrio": emptyObject(hoodId),
            "zTelfono1": emptyObject(step1.datospostulante.companyPhone),
            "zNIT": emptyObject(step1.informacionlaboral.subsidioData.nit),
            "zEmpresaAfiliacion": emptyObject(step1.informacionlaboral.subsidioData.name),
            "zDireccionEmpresa": emptyObject(step1.informacionlaboral.subsidioData.place),
            "zTelfono2": emptyObject(step1.informacionlaboral.subsidioData.phone),
            "zEmpresaTraba": laEMPname,
            "zDireccionTrabajo": laEMPplace,
            "zTelfono3": laEMPphone,
          },
          "DatoAfiliado": {
            "zNombreApellido": emptyObject(step2.condicionHogar.companyName),
            "zFechaNacimiento": emptyObject(step2.condicionHogar.birthdate),
            "zTipoDocumento": emptyObject(step2.condicionHogar.documentType),
            "zDocIdentididadAfiliado": emptyObject(step2.condicionHogar.numDocJefe),
            "zSexoAfiliado": homologaSexo(emptyObject(step2.condicionHogar.sex)),
            "zParentescoAfil": "1",
            "zOcupacionAfil": emptyObject(step2.condicionHogar.jobJefeHogar),
            "zEstadoCivilAfil": emptyObject(step2.condicionHogar.maritalstatusJefe),
            "zipoPostulanteAfil2": emptyObject(step2.condicionHogar.tipostulante),
            "zIngresoMensualAfil2": {
              "value": ingresosAfiliado,
              "currencyCode": "COP"
            },
            "zCondicionEspecialAfil": emptyObject(step2.condicionHogar.special),
            "zNombreCaja": "Comfenalco Valle"
          },
          "IntegrantesHogarPostulante": inthogarPostulan,

          "Totales": {
            "zObservaciones": emptyObject(step2.totalingresos.observaciones),
            "zTotalIngresos": {
              "value": totalIngresos.toString(),
              "currencyCode": "COP"
            }
          },
          "InformacionPostulacion": {
            "zNombreProyecto": emptyObject(step3?.informacionpostulacion?.projectname),
            "zNombreOferente": emptyObject(step3?.informacionpostulacion?.ofername),
            "zDepartamentoApli": emptyObject(step3?.informacionpostulacion?.departamentPostu),
            "zMunicipioApli": emptyObject(step3?.informacionpostulacion?.cityPostu)
          },
          //TODO: barrio no lo solicita
          "ModalidadViviendaV2": modalidadvivienda,
          "RecursoEconomico": recursoseconomicos,
          "FinanciacionTotal": financiacion,
          "ValorSolucionConstruccion": valorSolucion,
          "InformacionCuentas": informacionCuentas,
          "Autorizacion": {
            "zCorreoElectronico2": "true"
          }
        }
      }
    }

  }
  console.log("body vivienda ", JSON.stringify(body))
  var resul = {}
  try {
    console.time("inicio");


    const res = yield consumeServiceGenerico({ body, url: urlConfenalco })
    console.log(res)
    console.log("respuesta ws vivienda", res)
    var ban = false;
    var nota = "";
    if (res.data.responseMessageOut.header.responseStatus.statusCode === "SUCESS") {
      resul = res.data.responseMessageOut.body.response.registroSubsidioResponse;

      let newItems = items;
      const money = !emptyJson(resul.ValorSubsidio) ? parseInt(resul.ValorSubsidio) : step4.financiaciontotal.valorsubcidiado;
      const currency = function (number) {
        return new Intl.NumberFormat('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 2 }).format(number);
      };

      let salarios = "";
      var valorSub = currency(money);
      if (!emptyJson(resul.ValorSubsidio)) {
        const tempValor = resul.ValorSFV.split(",");
        salarios = tempValor[0];
        newItems.valorSFV = resul.ValorSFV;
      } else {
        const minimo = items.salarioMin ? items.salarioMin : "1160000";
        newItems.valorSFV = cuantosSalarios(minimo, money);
        const tempV = newItems.valorSFV.split(",");
        salarios = tempV[0];
      }


      newItems.valorSubsidioOtorgado = money.toString();

      yield pdfgenera({ id: idFormulario, items: newItems });
      let promise = new Promise((resolve, reject) => {
        setTimeout(() => resolve("¡Hecho!"), 2000)
      });
      let result = yield promise;
      yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_VIVIENDA).doc(idFormulario).set({ state: "finalizado", valorSFV: newItems.valorSFV, valorSubsidioOtorgado: newItems.valorSubsidioOtorgado }, { merge: true });
      yield put(reloadPage({ reload: true }));
      ban = true;


      yield put(contactoAdd({
        message: `La radicación de tu postulación ha sido satisfactoria y quedó registrada bajo el código:
         ${oportunidad} para un subsidio en la modalidad de ${modalidad} 
        por ${salarios} smmlv equivalente a ${valorSub}.
        En los próximos días se realizará la respectiva verificación de datos registrados y documentos anexos.
        De acuerdo al resultado de la verificación se te notificará al correo electrónico registrado en la radicación, si quedas en fase Postulado o presentas alguna novedad por resolver.
        Para cualquier inquietud adicional puedes llamar al 602 8862727 opción 2 o escribir a servicioalcliente@comfenalcovalle.com.co`,
        error: false
      }));
    } else {
      notificaFalloWBS({ body: body, webService: "Guarda formulario vivienda", response: res.data.responseMessageOut.body })
      resul = res.data.responseMessageOut.body.response && res.data.responseMessageOut.body.response.registroSubsidioResponse && res.data.responseMessageOut.body.response.registroSubsidioResponse.log ? res.data.responseMessageOut.body.response.registroSubsidioResponse.log : "Time out";
      nota = emptyObject(resul);
      //const respuesta = yield homologarRespuesta(resul);
      yield put(reloadPage({ reload: true }));
      yield put(contactoAdd({
        message: `De acuerdo al  registro realizado, se ha encontrado una inconsistencia que no permite
         realizar radicación de tu postulación en este momento.
        Para mayor información, te invitamos a comunicarte al la línea telefónica (602)  8862727 
          extensiones 2817 - 2320 - 2399`, error: true
      }));
    }
    var faseVenta = "Z01"

    if (ban === false) {
      faseVenta = "Z11"
    } else {
      yield put(generatePdf({ state: true, id: idFormulario }))
    }
    //yield put(generatePdf({ state: true, id: idFormulario }))
    yield consumeActualizaOportunidad({ tipo, id, faseVenta, idOportunidad: oportunidad, ban, nota })
    console.timeEnd("inicio");

  } catch (error) {
    yield put(reloadPage({ reload: true }));
    yield put(contactoAdd({
      message: "Un error inesperado no permite realizar radicación de postulación en este momento. Para mayor información favor comunicarse al 8862727 extensiones 2817 - 2320 - 2399",
      error: true
    }));
    console.error(error, "error")
  }
  return resul;
}

function* conversionSexo(action) {
  var res = ""
  if (action) {
    if (action === "M") {
      res = "2";
    }
    else if (action === "F") {
      res = "1";
    } else {
      res = "3";
    }
  }
  return res;
}

function homologaSexo(action) {
  var res = ""
  if (action) {
    if (action === "1") {
      res = "2";
    }
    else if (action === "2") {
      res = "1";
    } else {
      res = "3";
    }
  }
  return res;
}

function menorValido(action) {
  var ban = true;
  var hoy = new Date();
  var cumpleanos = new Date(action.fechaNacimiento);
  var edad = hoy.getFullYear() - cumpleanos.getFullYear();
  var m = hoy.getMonth() - cumpleanos.getMonth();

  if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
    edad--;
  }
  if (edad >= 18) {
    ban = false;
  }

  return { edad, valido: ban }
}

function* initFormStep1(action) {
  const catalogo = yield select(getCatalogosCargados);
  const { value } = action;
  console.log(action)

  let userid = "";
  let type = "";
  let id = "";
  try {
    if (value) {
      let currentFormValues = false;
      let idFormulario = false;


      // const type = "CC"
      // const id = "66982929"
      if (value.posType && value.posId) {
        userid = emptyObject(value['posId']);
        type = emptyObject(value['posType']);
        id = emptyObject(value['posId']);//
        yield put(saveUserColaborador(value));
      } else {
        userid = emptyObject(value['comfenApp/idUsua']);
        type = emptyObject(value['comfenApp/tipo_identificacion']);
        id = emptyObject(value['comfenApp/rest_id']);//
      }
      yield put(formInitialized({ userid, idFormulario }));
      const val = yield consumeValidador({ id: id, tipo: type });
      console.log(action);
      var integrantes = []
      var integranteObliga = []
      var banllenado = false;
      console.log(val)
      if (val?.xsi) {
        if (val?.grupoFamiliar && val?.grupoFamiliar?.informacionGrupo) {
          if (val.grupoFamiliar.informacionGrupo?.identificacion) {
            const element = val.grupoFamiliar.informacionGrupo;
            var temp = [];

            if (element.identificacion !== id) {
              const valEdad = menorValido(element);
              temp.push({ label: emptyObject(element.nombre) });
              temp.push({ label: emptyObject(element.abreviatura) });
              temp.push({ label: emptyObject(element.identificacion) });
              temp.push({ label: emptyObject(element.fechaNacimiento) });
              temp.push({ label: yield conversionSexo(emptyObject(element.sexo)) });
              temp.push({ label: emptyObject(element.parentesco) });
              integrantes.push(temp)
              if (emptyObject(element.idPare) === "3" || emptyObject(element.idPare) === "8" || (emptyObject(element.idPare) === "4" && valEdad.valido) || (emptyObject(element.idPare) === "5" && valEdad.valido)) {
                integranteObliga.push(temp)
                banllenado = true;
                //yield addInthogarPostulante({ value: { row: item, ban: true } });

              } else {
                integrantes.push(temp);
              }
              yield put(optionsTable({ field: "tableIntegrantes", data: integrantes }));
              yield put(enabledDisabledArray(0))
            }
          } else {
            var banConyugue = false;
            for (var element of val.grupoFamiliar.informacionGrupo) {
              if (element.identificacion !== id) {
                var temp = [];
                const valEdad = menorValido(element);
                temp.push({ label: emptyObject(element.nombre) });
                temp.push({ label: emptyObject(element.abreviatura) });
                temp.push({ label: emptyObject(element.identificacion) });
                temp.push({ label: emptyObject(element.fechaNacimiento) });
                temp.push({ label: yield conversionSexo(emptyObject(element.sexo)) });
                temp.push({ label: emptyObject(element.parentesco) });

                if (emptyObject(element.idPare) === "3" || emptyObject(element.idPare) === "8" || (emptyObject(element.idPare) === "4" && valEdad.valido) || (emptyObject(element.idPare) === "5" && valEdad.valido)) {
                  if (emptyObject(element.idPare) === "3" || emptyObject(element.idPare) === "8") {
                    banConyugue = true;
                  }
                  temp.push({ edad: valEdad.edad });
                  integranteObliga.push(temp)
                } else {
                  integrantes.push(temp)
                }
              }
            };
            if (integranteObliga.length > 0) {
              //ordenar por edad
              var tempInte = []
              if (integranteObliga.length > 1) {
                integranteObliga = integranteObliga.sort(ordenarEdad);
              }
              integranteObliga.forEach(element => {
                tempInte.push([
                  element[0],
                  element[1],
                  element[2],
                  element[3],
                  element[4],
                  element[5],
                ])
              });

              integranteObliga = tempInte;
              var newObliga = [];
              if (banConyugue) {

                integranteObliga.forEach(element => {
                  if (element[5].label === "CONYUGUE" || element[5].label === "COMPAÑERO") {
                    newObliga.push(element);
                  }
                });
                integranteObliga.forEach(element => {
                  if (element[5].label === "CONYUGUE" || element[5].label === "COMPAÑERO") {

                  } else {
                    newObliga.push(element);
                  }
                });

                integranteObliga = newObliga;
              }
              yield put(optionsTable({ field: "tableIntegrantes", data: integrantes }));
              banllenado = true;

            } else {
              yield put(optionsTable({ field: "tableIntegrantes", data: integrantes }));
              banllenado = true;
              //todo agregar al formulario
            }
            yield put(enabledDisabledArray(integranteObliga.length - 1))
          }
        }
        yield put(integrantesObligation(integranteObliga))
        var depto = '';
        const ciry = emptyObject(val.afiliado.idMuniResidencia);
        catalogo.cities.forEach(element => {
          if (element.value === ciry) {
            depto = element.id_depa;
            return;
          }
        });

        catalogo.deptos.forEach(element => {
          if (element.id_depa === depto) {
            depto = element.value;
            return;
          }
        });

        let marital = ""
        if (val?.afiliado?.estadoCivil) {
          if (val.afiliado.estadoCivil === "UNION LIBRE") {
            marital = "4";
          } else {
            catalogo.civil.forEach(element => {
              if (element.label === val.afiliado.estadoCivil) {
                marital = element.value;
                return;
              }
            });
          }
        }
        var phone = ''
        if (emptyObject(val?.afiliado?.telefonoResidencia).length > 0) {
          phone = `${val?.afiliado?.telefonoResidencia}`;
          if (emptyObject(val?.afiliado?.celular).length > 0) {
            phone = `-${phone}`;
          }
        }
        const auth0Data = {
          firstName: emptyObject(val.afiliado.primerNombre),
          middleName: emptyObject(val.afiliado.segundoNombre),
          lastName: emptyObject(val.afiliado.primerApellido) + " " + emptyObject(val.afiliado.segundoApellido),
          documentType: type,
          numberId: id,
          companyEmail: emptyObject(val?.afiliado?.email),
          companyPhone: `${emptyObject(val?.afiliado?.celular)}${phone}`,
          departament: depto,
          city: ciry,
          currentAddress: emptyObject(val.afiliado.direccionResidencia),
          hood: emptyObject(val.afiliado.barrioResidencia)
        }
        const hoodId = emptyObject(val.afiliado.idBarrResidencia);
        yield put(saveDataExt(hoodId));

        const nameCom = emptyObject(val.afiliado.nombre);
        const sexo = yield conversionSexo(emptyObject(val?.afiliado?.sexo));


        const emp = val?.empresas?.informacionEmpresa;
        var aux = [];
        var banEmp = false;
        if (emp?.length > 1) {
          aux = emp;
          banEmp = true;
        } else {
          aux.push(emp);
        }

        let optionsSub = []
        let optionsLab = []
        let infoLabor = {}

        var ingresos = 0;

        aux.forEach(element => {
          if (element?.idEsla === "1") {
            let id = emptyObject(element?.identificacion);
            let name = emptyObject(element?.razonSocial);
            let dir = emptyObjectEmp(element?.direccion, auth0Data.currentAddress);
            let phone = emptyObjectEmp(element?.telefono, auth0Data.companyPhone);
            const cad = id + ";" + name + ";" + dir + ";" + phone;
            ingresos = ingresos + parseInt(element?.ibc);
            optionsSub.push({
              label: name,
              value: cad,
            });
          }

        });

        aux.forEach(element => {
          if (element?.idEsla === "1") {
            let name = emptyObject(element?.razonSocial);
            let dir = emptyObjectEmp(element?.direccion, auth0Data.currentAddress);
            let phone = emptyObjectEmp(element?.telefono, auth0Data.companyPhone);
            const cad = name + ";" + dir + ";" + phone;

            optionsLab.push({
              label: name,
              value: cad
            });
          }
        });

        optionsLab.push({
          label: "Otra Empresa",
          value: "otraEmpresa"
        });

        yield put(optionsTable({ field: "subsidioSelect", data: optionsSub }));
        yield put(optionsTable({ field: "laborandoSelect", data: optionsLab }));



        if (optionsSub.length === 1) {
          infoLabor = { subsidioSelect: optionsSub[0].value, laborandoSelect: optionsLab[0].value }

          var tableS = []
          var tableL = []
          tableS.push(emptyObject(aux[0]?.identificacion));
          tableS.push(emptyObject(aux[0]?.razonSocial));
          tableS.push(emptyObjectEmp(aux[0]?.direccion, auth0Data.currentAddress));
          tableS.push(emptyObjectEmp(aux[0]?.telefono, auth0Data.companyPhone));
          tableL.push(emptyObject(aux[0]?.razonSocial));
          tableL.push(emptyObjectEmp(aux[0]?.direccion, auth0Data.currentAddress));
          tableL.push(emptyObjectEmp(aux[0]?.telefono, auth0Data.companyPhone));

          yield put(callDinamicDisable({ field: "subsidioSelect", state: true }));

          yield put(hiddenDisable({ state: false, field: "companyTable" }))
          yield put(optionsTable({ field: "companyTable", data: tableS }));
          yield put(hiddenDisable({ state: false, field: "companyTableLabor" }))
          yield put(optionsTable({ field: "companyTableLabor", data: tableL }));
        }

        const dataStep2 = {
          companyName: nameCom,
          documentType: emptyObject(type),
          numDocJefe: emptyObject(id),
          maritalstatusJefe: marital,
          parentezcoJefe: "1",
          birthdate: emptyObject(val.afiliado.fechaNacimiento),
          // jobJefeHogar: "6",
          sex: sexo,
          ingresos: emptyObject(ingresos.toString()),
        }

        const disable = REQUIRED_CONDICION_HOGAR;

        for (var item of disable) {
          if (!dataStep2[item]) {
            yield put(hiddenClose({ val: item, state: false }))
          }
        }

        const totalingresos = {
          salarioreportafiliacion: emptyObject(ingresos.toString()),
          totalingresos: emptyObject(ingresos.toString()),
        }

        if (userid) {
          const solicitudes = yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_VIVIENDA).where('userid', '==', userid).where('state', '==', 'borrador').get();

          yield solicitudes.docs.map((doc, i) => {
            idFormulario = doc.id;
            currentFormValues = doc.data();
          });
          yield put(formInitialized({ userid, idFormulario }));
        }
        if (currentFormValues) {
          const step6 = currentFormValues['step6'] ? currentFormValues['step6'] : {};
          yield put(initialize(FORM_NAME_STEP6, step6))
        }

        if (currentFormValues) {
          yield put(guardarItems({ item: currentFormValues, state: true }));

          if (currentFormValues?.refFiles?.refStorage?.length > 0) {
            const array = currentFormValues.refFiles.refStorage;
            yield anexosOrder(array)
          }
          if (currentFormValues?.refFirmas?.refStorage?.length > 0) {
            const firma = currentFormValues.refFirmas.refStorage;
            yield put(saveRefirmas({ data: firma }));
            yield firmasOrder(firma)
          }
          var laboCurrent = {}
          if (currentFormValues?.step1?.informacionlaboral) {
            var temp = currentFormValues.step1.informacionlaboral.subsidioData;
            var tableS = [];
            tableS.push(emptyObject(temp.nit));
            tableS.push(emptyObject(temp.name));
            tableS.push(emptyObject(temp.place));
            tableS.push(emptyObject(temp.phone));

            laboCurrent.subsidioSelect = tableS[0] + ";" + tableS[1] + ";" + tableS[2] + ";" + tableS[3];
            yield put(callDinamicDisable({ field: "subsidioSelect", state: true }));
            yield put(hiddenDisable({ state: false, field: "companyTable" }))
            yield put(optionsTable({ field: "companyTable", data: tableS }));

            if (currentFormValues?.step1?.informacionlaboral?.laborandoSelect) {
              const temL = currentFormValues.step1.informacionlaboral.laborandoSelect;

              if (temL === "otraEmpresa") {
                laboCurrent.laborandoSelect = temL;
                laboCurrent.companyJobAddress = currentFormValues.step1.informacionlaboral.companyJobAddress;
                laboCurrent.companyJobName = currentFormValues.step1.informacionlaboral.companyJobName;
                laboCurrent.companyPhoneJob = currentFormValues.step1.informacionlaboral.companyPhoneJob;

                yield put(hiddenDisable({ state: true, field: "companyTableLabor" }))
                yield put(hiddenDisable({ state: false, field: "companyJobName" }))
                yield put(hiddenDisable({ state: false, field: "companyJobAddress" }))
                yield put(hiddenDisable({ state: false, field: "companyPhoneJob" }))
              } else {
                const tempL = currentFormValues.step1.informacionlaboral.laborandoData;
                var tableL = []

                tableL.push(tempL.name);
                tableL.push(tempL.place);
                tableL.push(tempL.phone);
                laboCurrent.laborandoSelect = tableL[0] + ";" + tableL[1] + ";" + tableL[2];
                yield put(hiddenDisable({ state: false, field: "companyTableLabor" }))
                yield put(optionsTable({ field: "companyTableLabor", data: tableL }));
              }
            }
          }
          var basic = {};
          if (currentFormValues?.step3?.informacionbasica) {
            basic = currentFormValues.step3.informacionbasica;
          }
          const step6 = currentFormValues['step6'] ? currentFormValues['step6'] : {};
          yield put(initialize(FORM_NAME_STEP6, step6))

          const step1 = currentFormValues['step1'] ? currentFormValues['step1'] : {};
          const stepp1 = {
            datospostulante: auth0Data,
            informacionlaboral: laboCurrent
          };
          if (currentFormValues?.step1?.datospostulante) {
            stepp1.datospostulante.idPlace = emptyObject(currentFormValues.step1.datospostulante?.idPlace);
          }

          yield put(initialize(FORM_NAME_STEP1, stepp1));

          var step2 = currentFormValues['step2'] ? currentFormValues['step2'] : {};
          step2.condicionHogar.companyName = nameCom;
          step2.condicionHogar.maritalstatusJefe = marital;
          step2.condicionHogar.sex = sexo;
          step2.totalingresos.salarioreportafiliacion = emptyObject(ingresos.toString());
          step2.condicionHogar.ingresos = step2?.condicionHogar?.ingresos?.length > 0 ? step2.condicionHogar.ingresos : emptyObject(ingresos.toString());
          yield put(initialize(FORM_NAME_STEP2, step2));

          if (currentFormValues.ciclos) {
            var newCatalog = currentFormValues.ciclos;
            var tempCatalog = catalogo;
            tempCatalog.ciclos = newCatalog;

            yield put(cargarCatalogos(tempCatalog));
            yield put(saveCatalogoCiclos(currentFormValues.ciclos));

            var modalidad = []
            newCatalog.forEach(e => {
              if (e.ciclo === "Z03") {
                if (!exist(modalidad, "rural")) {
                  modalidad.push({
                    label: "Rural",
                    value: "rural",
                  });
                }
              }
              if (e.ciclo === "Z02") {
                if (!exist(modalidad, "urbano")) {
                  modalidad.push({
                    label: "Urbano",
                    value: "urbano",
                  });
                }
              }

            });
            const newData = {
              disable: false,
              options: modalidad.sort(ordenar),
              field: "homeType",
            };

            yield put(loadDocumentSuccess(newData));

            if (currentFormValues.step3?.informacionbasica?.homeModeType) {
              const mod = yield select(getCompanyValuesStep3);
              var homeType = yield crearHomeType(tempCatalog, currentFormValues.step3.informacionbasica.planHomeVivienda, currentFormValues.step3.informacionbasica.homeType);
              const ndata = {
                disable: false,
                options: homeType.sort(ordenar),
                field: "homeModeType",
              };
              yield put(loadDocumentSuccess(ndata));
            }

            if (currentFormValues.step3?.informacionbasica?.planHomeVivienda) {
              var planHome = yield crearPlanHome(tempCatalog, currentFormValues.step3.informacionbasica.homeType);
              const npdata = {
                disable: false,
                options: planHome.sort(ordenar),
                field: "planHomeVivienda",
              };
              yield put(loadDocumentSuccess(npdata));
            }



          }

        } else {

          var infousuario = {};
          infousuario.datospostulante = auth0Data;
          infousuario.informacionlaboral = infoLabor;


          var infousuario2 = {};
          infousuario2.condicionHogar = dataStep2;
          //infousuario2.integrantesHogar={inthogarpostulantes:integranteObliga};
          infousuario2.totalingresos = totalingresos;
          yield put(initialize(FORM_NAME_STEP2, infousuario2));
          if (banllenado) {
            for (var item of integranteObliga) {
              yield addInthogarPostulante({ value: { row: item, ban: true } });
            }

          }

          yield put(initialize(FORM_NAME_STEP1, infousuario));
        }
      } else {
        yield put(contactoAdd({ message: "No fue posible acceder al formulario refresque la página o intentelo mas tarde, Comuniquese a atención al cliente de Comfenalco Valle Delagente.", error: true }));
      }

    }
  } catch (error) {
    console.error(error);
  }

}

function* separarRefer(action) {
  try {
    const refes = [];
    var con = 0;
    var tem = [];
    action.forEach(element => {
      con = con + element.size;
      if (con <= 7000000) {
        tem.push(element)
      } else {
        con = element.size;
        refes.push(tem);
        tem = []
        tem.push(element)
      }
    });
    refes.push(tem);
    return refes;

  } catch (error) {
    console.error("function.separarRefer", error)
  }

}

function* bajarFiles(action) {
  try {
    const refes = yield separarRefer(action);

    const headers = HEADERS_WS;
    const urlservicio = process.env.REACT_APP_URL_CARGAR_FILES;
    const auxfiles = []
    for (var item of refes) {
      var body = { body: { refStorage: item, bucket: process.env.REACT_APP_FIREBASE_BUCKET } }

      const resp = yield call(axios.post, urlservicio, body, headers);
      if (resp.data && resp.data.length > 0) {
        yield resp.data.forEach(element => {
          auxfiles.push({
            nameFil: element.name,
            name: element.lastModified,
            doc: {
              nombreArchivo: element.nameArchivo,
              extensionArchivo: element.typeof,
              contenidoArchivo: element.base64,
            }
          })

        });

      }
    }
    yield put(base64Remove(auxfiles));
    const base = yield select(getbase64Add);

  } catch (error) {
    console.error("function.bajarFiles", error)
  }

}

function* initAllForms(action) {
  const { value } = action;
  try {
    if (value) {
      let currentFormValues = false;
      let idFormulario = false;

      const userid = value['comfenApp/idUsua'];
      if (userid) {
        const solicitudes = yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_VIVIENDA).where('userid', '==', userid).where('state', '==', 'borrador').get();

        yield solicitudes.docs.map((doc, i) => {
          currentFormValues = doc.data();
        });
        if (currentFormValues.refFiles) {
          //array
          const refFile = currentFormValues.refFiles.refStorage;
          yield put(saveRefFiles(refFile));

        }
        if (currentFormValues.step3) {
          if (currentFormValues.step3.informacionbasica) {
            if (currentFormValues.step3.informacionbasica.homeModeType) {
              const aux = currentFormValues.step3.informacionbasica.homeModeType;
              if (aux === "1" || aux === "4") {
                yield put(step3Completo(false));
                //yield put(hiddenDisable({ state: true, field: "valorconstruccion" }));
              }
            }
            if (currentFormValues.step3.informacionbasica.subsidio) {
              const sub = currentFormValues.step3.informacionbasica.subsidio;
              yield put(activateRadio({ state: sub }))
              if (sub === "2") {
                yield put(callDinamicDisable({ field: "entidadSubsidio", state: true }));
                yield put(change("postulacionStep3", "entidadSubsidio", ""));

              }
            }
          }
        }
      }

      if (currentFormValues) {
        const step3 = currentFormValues['step3'] ? currentFormValues['step3'] : {};
        const step4 = currentFormValues['step4'] ? currentFormValues['step4'] : {};
        const catalogo = yield select(getCatalogosCargados)

        if (step3 && step3.modalidadvivienda) {
          const depaM = idDeparta(catalogo.deptos, step3.modalidadvivienda.departamentoModalidad)
          const city2 = crearCities(catalogo.cities, depaM);
          yield put(loadDocumentSuccess({
            disable: false,
            options: city2.sort(ordenar),
            field: "cityModalidad",
          }));
        }
        if (step3 && step3.informacionpostulacion) {
          const depaT = idDeparta(catalogo.deptos, step3.informacionpostulacion.departamentPostu)
          const city = crearCities(catalogo.cities, depaT);

          yield put(loadDocumentSuccess({
            disable: false,
            options: city.sort(ordenar),
            field: "cityPostu",
          }));

        }

        yield put(initialize(FORM_NAME_STEP3, step3));

        if (step4 && step4.financiaciontotal) {
          yield put(change("recursosEconomicosStep4", "totalahorroprevio.totalAhorro", emptyObject(step4.financiaciontotal.totalahorroprevio)))
          yield put(change("recursosEconomicosStep4", "totalrecursoscomplementarios.totalComple", emptyObject(step4.financiaciontotal.totalrecursos)))
          yield put(change("recursosEconomicosStep4", "financiaciontotal.totalahorroprevio", emptyObject(step4.financiaciontotal.totalahorroprevio)))
          yield put(change("recursosEconomicosStep4", "financiaciontotal.totalrecursos", emptyObject(step4.financiaciontotal.totalrecursos)))
          yield put(change("recursosEconomicosStep4", "financiaciontotal.valorsubcidiado", emptyObject(step4.financiaciontotal.valorsubcidiado)))
          yield put(change("recursosEconomicosStep4", "financiaciontotal.valorvivienda", emptyObject(step4.financiaciontotal.valorvivienda)))

          if (step4.cuentasCredito) {
            yield put(change("recursosEconomicosStep4", "cuentasCredito.fuentesFinanciamiento", emptyObject(step4.cuentasCredito.fuentesFinanciamiento)))
            yield put(change("recursosEconomicosStep4", "cuentasCredito.entityFuente", emptyObject(step4.cuentasCredito.entityFuente)))
            yield put(change("recursosEconomicosStep4", "cuentasCredito.openDateFuente", emptyObject(step4.cuentasCredito.openDateFuente)))
          }
          if (step4.ahorroprevio) {
            if (step4.ahorroprevio.length > 0) {
              const catalogo = yield select(getCatalogosCargados)
              const ahorro = step4.ahorroprevio;
              let aux = []
              ahorro.forEach(element => {
                aux.push([element.tiporecursoA, element.entityA, element.openDateA, element.safeValueA])
              });

              let cataAhorro = []

              catalogo.tiporecursoA.forEach(element => {
                let bandera = false;
                aux.forEach(auxi => {
                  if (auxi[0].value === element.value) {
                    bandera = true;
                  }
                });
                if (bandera === false) {
                  cataAhorro.push(element);
                }
              });
              catalogo.tiporecursoA = cataAhorro;
              yield put(addRecursoAhorro({ field: "ahorro", data: aux, clave: "inicio" }));
              yield put(optionsTable({ field: "tiporecursoA", data: cataAhorro }));
              yield put(cargarCatalogos(catalogo));
              const data = yield select(getAddRecursoAhorro);
              yield put(optionsTable({ field: "tableAhorro", data: data }));
              yield put(change("recursosEconomicosStep4", "ahorroprevio", ""))

            }
          }
          if (step4.recursoscomplementarios) {
            if (step4.recursoscomplementarios.length > 0) {
              const catalogo = yield select(getCatalogosCargados)
              const comple = step4.recursoscomplementarios;

              let aux = []
              comple.forEach(element => {
                aux.push([element.tiporecursoR, element.entityR, element.openDateR, element.safeValueR])
              });

              let cataComple = []
              catalogo.tiporecursoR.forEach(element => {
                let bandera = false;
                aux.forEach(auxi => {
                  if (auxi[0].value === element.value) {
                    bandera = true;
                  }
                });
                if (bandera === false) {
                  cataComple.push(element);
                }
              });
              catalogo.tiporecursoR = cataComple;
              yield put(addRecursoAhorro({ field: "comple", data: aux, clave: "inicio" }));
              yield put(optionsTable({ field: "tiporecursoR", data: cataComple }));
              yield put(cargarCatalogos(catalogo));
              const data = yield select(getAddRecursoComplementario);
              yield put(optionsTable({ field: "tableRecurso", data: data }));


            }
          }

        }

      }
    }
    yield put(chargedValidator({ state: false }))
    yield put(change("recursosEconomicosStep4", "ahorroprevio", ""))
    yield put(change("recursosEconomicosStep4", "recursoscomplementarios", ""))
    const fil = yield select(getRefFiles);
    if (fil.length > 0) {
      yield bajarFiles(fil);
    }


  } catch (error) {
    console.error("Function.initAllForms", error);
  }
}
function crearCities(citys, idDepto) {
  var arrCitys = [];
  citys.forEach((element) => {
    if (element.id_depa === idDepto) {
      arrCitys.push({
        label: element.label,
        value: element.value,
      });
    }
  });
  return arrCitys;
}

function* consumeConsultaOportunidad(action) {
  /* Función que recibe un número de oportunidad y trae la informacion registrada de este mismo
   */

  const { tipo, id } = action;
  var date = "";
  var date = new Date();

  const urlConfenalco = process.env.REACT_APP_URL_COMFENALCO_ENDPOINT;

  const body = {
    "requestMessageIn": {
      "header": {
        "invokerDateTime": date,
        "moduleId": "CONSULTAOPORTUNIDAD",
        "systemId": "FORMULARIOSWEB",
        "messageId": `${tipo}|${id}`,
        "logginData": {

        },
        "destination": {
          "namespace": "http://co/com/comfenalcovalle/esb/ws/CRMConsultaOportunidadLanding",
          "name": "CRMConsultaOportunidadLanding",
          "operation": "execute"
        },
        "securityCredential": {

        },
        "classification": {

        }
      },
      "body": {
        "request": {
          "consultaOportunidadRequest": {
            "tipoIdentificacion": tipo,
            "identificacion": id,
            "estado": "1|2",
            "cicloVentas": "Z02|Z03"
          }
        }
      }
    }
  }
  var resul = false;
  try {
    const res = yield consumeServiceGenerico({ body, url: urlConfenalco });
    console.log(res)
    if (res.data.responseMessageOut.header.responseStatus.statusCode === "SUCESS") {
      resul = res.data.responseMessageOut.body.response.consultaOportunidadResponse?.Oportunidades?.oportunidad;
    }

    return resul;
  } catch (error) {
    console.error(error, "error")
    throw `Function.consumeConsultaOportunidad - saga.js -${error}`;
  }
}

function* consumeServiceGenerico(action) {
  try {
    const { body, url } = action;


    const request = { urlServicio: url, body: body }
    const headers = HEADERS_WS;

    const respues = yield call(axios.post, process.env.REACT_APP_FIREBASE_SEND_CONSUME_SERVICIOS_GENERICOS, request, headers);

    return respues;
  } catch (error) {
    console.error("Function.consumeServiceGenerico", error);
    throw `Function.consumeServiceGenerico - saga.js -${error}`;
  }


}

function* consumeToken(action) {
  /* Función que recibe el documento y tipo de documento del afiliado
      y consulta el validador de derechos y retorna la información del afiliado+
   */


  const urlEnpoint = process.env.REACT_APP_URL_TOKEN_LOGIN;
  let headers = HEADERS_WS;

  var resul = {}
  try {
    const response = yield axios.post(urlEnpoint, {});
    console.log("respuesta validador", response)

    return resul;
  } catch (error) {
    console.error(error, "error")
    return error;
  }
}

function* consumeEnpoint(action) {
  /* Función que recibe el documento y tipo de documento del afiliado
      y consulta el validador de derechos y retorna la información del afiliado+
   */
  const { app_id, token, bearer } = action;

  const urlEnpoint = process.env.REACT_APP_URL_COMFENALCO_API;

  let headers = {
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    "Access-Control-Allow-Origin": "*",
    "Authorization": 'Bearer ' + bearer,
    'Content-Type': 'application/json'

  };

  const body = {
    app_id,
    token
  }
  console.log(JSON.stringify(body));

  var resul = false;
  try {

    const res = yield call(axios.post, urlEnpoint, body, { headers });
    console.log(res);
    if (res.data.idToken)
      resul = res.data.idToken;

    return resul;
  } catch (error) {
    console.error("exception: ", error)
    return error;
  }
}
function getCoordinates() {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      location => resolve(location),
      error => reject(error),
    )
  })
}


function* getFirebaseUser(action) {
  try {

    const position = yield call(getCoordinates);

    const { accuracy, latitude, longitude } = position.coords;
    const browserId = navigator.userAgent.toLowerCase().replace(/\s+/g, "");
    const customSessionId = '' + accuracy + '-' + latitude + '-' + longitude + '-' + browserId;

    const body = {
      "appId": process.env.REACT_APP_ID,
      "sessionId": customSessionId
    };

    const { data } = yield call(axios.post, process.env.REACT_APP_URL_VERIFY_CUSTOM_SESSION_ID, body, {
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
      'Access-Control-Allow-Credentials': 'true',
      "Access-Control-Request-Headers": "*",
      'accept': "application/json, text/plain, /",
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    });

    if (data && data.decryptedUser && data.decryptedUser.idToken) {
      const tempProvider = data.decryptedUser.idToken["comfenApp/auth_id"].split("|")[0];
      const userInfo = {
        accessToken: data.refreshedToken,
        providerId: tempProvider,
        token: data.decryptedUser.idToken["firebase/userId"],
        user: data.decryptedUser.idToken["comfenApp/email"]
      }
      //saveInSession('authUserInfo', data.decryptedUser.idToken);
      yield put(setFirebaseUserSession({ userInfo, authUserInfo: data.decryptedUser.idToken }));
    } else {
      const userInfo = {
        accessToken: false
      }
      yield put(setFirebaseUserSession({ userInfo, authUserInfo: false }));
    }
    console.log("User", data)

  } catch (error) {
    throw `getFirebaseUser ${error}`
  }
}

function* searchUser() {
  try {
    const authUser = getFromSession('authUser');
    const authUserColaborador = getFromSession('authUserColaborador');
    if (authUserColaborador) {
      return authUserColaborador;
    } else {
      if (authUser && authUser.user) {
        return yield consumeEnpoint({ app_id: process.env.REACT_APP_ID, token: authUser.token, bearer: authUser.accessToken });
        //return yield select(getFirebaseUserInfo)
      }
    }
  } catch (error) {
    console.error("saga.searchUser", error);
  }
}



function* buildDynamicOptions(action) {
  try {
    
    yield put(chargedValidator({ state: true }))
    const userInfo = yield searchUser();
    console.log(userInfo)
    if (userInfo) {

      const dynamicOptions = {};
      const activateIP = yield getAsyncCollection("IP_AUTORIZED");
      const departament = yield getAsyncCatalog('DEPARTAMENTO_VIVIENDA');
      const cities = yield getAsyncCatalog('CITIES');
      const fuentes = yield getAsyncCatalog('FUENTES_FINANCIAMIENTO');
      const documentType = yield getAsyncCatalog('TIPOSDOC');
      const homeModeType = yield getAsyncCatalog('MODALIDAD_VIVIENDA');
      const postulanteType = yield getAsyncCatalog('TIPO_POSTULANTE_VI');
      const propiedadlote = yield getAsyncCatalog('PROPIEDAD_LOTE');
      const sexoJefe = yield getAsyncCatalog('SEXO');
      const parentezcoJefe = yield getAsyncCatalog('PARENTEZCO');
      const jobJefeHogar = yield getAsyncCatalog('OCUPACION');
      const special = yield getAsyncCatalog('CONDICION_ESPECIAL');
      const inthogar = yield getAsyncCatalog('INTHOGAR');
      const postulante = yield getAsyncCatalog('TIPO_POSTULANTE');
      const projecttype = yield getAsyncCatalog('TIPO_PROYECTO');
      const entidad = yield getAsyncCatalog('ENTIDADES_CREDITO');
      const entidadAho = yield getAsyncCatalog('ENTIDADES_AHORRO');
      const maritalstatus = yield getAsyncCatalog('ESTADO_CIVIL');
      const tiporecursoA = yield getAsyncCatalog('AHORRO_PREVIO_TIPO_REC');
      const tiporecursoR = yield getAsyncCatalog('RECURSOS_COMPLEMENTARIOS_TIPOS');
      const resHomolog = yield getAsyncCatalog('RESPUESTAS_HOMOLOGADOS');
      const tipoSolicitud = yield getAsyncCatalog('TIPO_SOLICITUD');
      const ipValid = yield getAsyncCatalog('IP_AUTORIZED');
      var autorized = true;
      if (activateIP.ACTIVATE) {
        autorized = yield obtenerIp({ ip: ipValid });
      }
      if (autorized) {
        const parent = []
        parentezcoJefe.forEach(element => {
          if (element.value !== "1") {
            parent.push(element)
          }
        });

        dynamicOptions.departamentoModalidad = departament.sort(ordenar);
        dynamicOptions.departamentPostu = departament.sort(ordenar);
        dynamicOptions.documentType = documentType.sort(ordenar);
        dynamicOptions.documentTypePostu = inthogar.sort(ordenar);
        dynamicOptions.fuentesFinanciamiento = fuentes.sort(ordenar);
        //dynamicOptions.homeModeType = homeModeType.sort(ordenar2);
        dynamicOptions.postulanteType = postulanteType.sort(ordenar2);
        dynamicOptions.departament = departament.sort(ordenar);
        dynamicOptions.propiedadlote = propiedadlote.sort(ordenar2);
        dynamicOptions.idPlace = cities.sort(ordenar);
        dynamicOptions.city = cities.sort(ordenar);
        dynamicOptions.sexPostu = sexoJefe.sort(ordenar);
        dynamicOptions.sex = sexoJefe.sort(ordenar);
        dynamicOptions.parentezcoJefe = parentezcoJefe.sort(ordenar2);
        dynamicOptions.relativePostu = parent.sort(ordenar2);
        dynamicOptions.jobJefeHogar = jobJefeHogar.sort(ordenar2);
        dynamicOptions.job = jobJefeHogar.sort(ordenar2);
        dynamicOptions.jobPostu = jobJefeHogar.sort(ordenar2);
        dynamicOptions.special = special.sort(ordenar2);
        dynamicOptions.specialPostu = special.sort(ordenar2);
        dynamicOptions.tipostulante = postulante.sort(ordenar2);
        dynamicOptions.postulantePostu = postulante.sort(ordenar2);
        dynamicOptions.projecttype = projecttype.sort(ordenar);
        dynamicOptions.tiporecursoA = tiporecursoA.sort(ordenar2);
        dynamicOptions.tiporecursoR = tiporecursoR.sort(ordenar2);
        dynamicOptions.entityA = entidadAho.sort(ordenar);
        dynamicOptions.entityR = entidadAho.sort(ordenar);
        dynamicOptions.entityFuente = entidad.sort(ordenar);
        dynamicOptions.maritalstatusPostu = maritalstatus.sort(ordenar2);
        dynamicOptions.maritalstatusJefe = maritalstatus.sort(ordenar2);

        yield put(cargarCatalogos({
          cities: cities.sort(ordenar), respuesta: resHomolog, deptos: departament.sort(ordenar), civil: maritalstatus,
          homeModeType: homeModeType
          , entity: entidadAho, entityFuente: entidad, tiporecursoA, tiporecursoR: tiporecursoR, tipoA: tiporecursoA, tipoR: tiporecursoR
        }));

        const res = yield put(loadCatalogSuccess(dynamicOptions));

        if (res) {
          yield initForms({ value: { ...userInfo } });
        }
      }
    } else {
      yield put(reloadPage({ reload: true }));
      yield put(contactoAdd({
        message: `La dirección IP desde la cual está intentando acceder a este formulario no se encuentra autorizada, 
        revise los escudos de bloqueo de página de su navegador e intente nuevamente. Si el problema persiste, por favor
        comuníquese al servicio al cliente de Comfenalco Valle Delagente para su autorización`,
        error: false
      }));
    }
    yield put(chargedValidator({ state: false }))
  } catch (error) {
    console.error("Function.buildDynamicOptions", error);
  }
}

function* initForms(action) {

  yield initFormStep1(action);
  yield initAllForms(action);

  sessionStorage.clear();
  saveInSession('formInitilizated', true);



}

function* submitFinalForm(action) {
  var file = false;
  if (action.value) {
    if (action.value.length > 0) {
      file = true;
    }
  }
  yield saveEraser({ final: true, files: file, formFiles: action.value });
}

function* submitSalveStep(action) {

  var file = false;
  var banDel = false;
  if (action.value) {
    if (action.value.length > 0) {
      file = true;
    }
  }
  if (action.ban) {
    banDel = true;
    yield put(increaseSiguiente({
      state: false,
      message: {
        error: false, errorMo: true,
        mes: "Verifique el nombre del anexo o soporte que está adjuntando. No se permiten caracteres especiales en el nombre del archivo. Actualice el nombre del archivo e intente subirlo nuevamente."
      }
    }))
  } else {
    yield put(increaseSiguiente({
      state: false,
      message: { error: true, errorMo: true, mes: "Guardando formulario ... Espere por favor." }
    }))
  }
  yield saveEraser({ final: false, files: file, salvar: true, formFiles: action.value, banDel });

}

function* saveEraser(action) {
  const { final, formFiles, files, salvar, banDel } = action;
  try {
    const va = yield select(getActiveStep);
    const cataCiclos = yield select(getCatalogoCiclos);
    const catalogo = yield select(getCatalogosCargados);
    const itemA = yield select(fullFormSelector);

    const respuesta = yield validateForm(itemA);
    if (respuesta.valid) {
      var banRecursos = true;
      if (va === 4) {
        if (parseInt(emptyCero(itemA.step4.financiaciontotal.totalahorroprevio)) <= 0 && parseInt(emptyCero(itemA.step4.financiaciontotal.totalrecursos)) <= 0) {
          banRecursos = false;
        }
      }
      if (banRecursos) {
        var item = yield select(getItemSaved);
        var newCatalog = [];
        if (va === 2) {
          if (cataCiclos && cataCiclos.length > 0 && parseInt(item.item?.step2?.totalingresos?.totalingresos) < 4500000 && parseInt(item.item?.step2?.totalingresos?.totalingresos) === parseInt(itemA.step2?.totalingresos?.totalingresos) && parseInt(item.item?.step2?.totalingresos?.salarioreportafiliacion) === parseInt(itemA.step2?.totalingresos?.salarioreportafiliacion)) {
          } else {
            var totalIngres = 0;

            if (itemA.step2?.totalingresos?.totalingresos || itemA.step2.totalingresos?.salarioreportafiliacion) {

              var ttIngre = parseInt(emptyCero(itemA.step2.totalingresos?.totalingresos));
              var liqui = parseInt(emptyCero(itemA.step2.totalingresos?.salarioreportafiliacion));
              var ingre = parseInt(emptyCero(conversion(itemA.step2.condicionHogar?.ingresos)));
              var ingreGrupo = ttIngre - ingre;
              totalIngres = ingre + ingreGrupo;
              if (liqui > ingre) {
                totalIngres = liqui + ingreGrupo;
              }

            }
            yield put(openModalEspera(true));
            const respuestaSalario = yield consumeWSConsultarPlanes({ id: itemA.step1?.datospostulante?.numberId, tipo: itemA.step1?.datospostulante?.documentType, ingreso: totalIngres });

            yield put(openModalEspera(false));
            if (respuestaSalario !== false) {
              var hType = "";
              respuestaSalario.plan.forEach(e => {
                if (e.planVivienda === "1") {
                  hType = "VIS";
                } else if (e.planVivienda === "2") {
                  hType = "VIP";
                }
                else if (e.planVivienda === "3") {
                  hType = "VIPA";
                }
                newCatalog.push({
                  ciclo: e.cicloVenta,
                  plan: e.planVivienda,
                  modalidad: e.modalidadSubsidio,
                  valor: e.valorSubsidio,
                  planCode: hType,
                  salarioMin: emptyObject(respuestaSalario.valorSalarioMinimo)
                })
              });
              var modalidad = []

              newCatalog.forEach(e => {
                if (e.ciclo === "Z03") {
                  if (!exist(modalidad, "rural")) {
                    modalidad.push({
                      label: "Rural",
                      value: "rural",
                    });
                  }
                }
                if (e.ciclo === "Z02") {
                  if (!exist(modalidad, "urbano")) {
                    modalidad.push({
                      label: "Urbano",
                      value: "urbano",
                    });
                  }
                }

              });
              const newData = {
                disable: false,
                options: modalidad.sort(ordenar),
                field: "homeType",
              };
              yield put(loadDocumentSuccess(newData));
              var tempCatalog = catalogo;
              tempCatalog.ciclos = newCatalog;
              yield put(saveCatalogoCiclos(newCatalog));
              yield put(cargarCatalogos(tempCatalog));

              //cargar catalogo a dynamicOptions
            } else {
              // salario no reporta planes 
              yield put(increaseSiguiente({ state: false, message: "Los ingresos mensuales registrados en la sección 2 no generan valor del subsidio. Verifique la información antes de continuar con la postulación." }))
              const newData = {
                disable: false,
                options: [],
                field: "homeType",
              };
              yield put(loadDocumentSuccess(newData));
              var tempCatalog = catalogo;
              tempCatalog.ciclos = [];
              yield put(saveCatalogoCiclos(newCatalog));
              yield put(cargarCatalogos(tempCatalog));
              yield put(changeStep(1));
            }
          }

        }
        if (va === 3) {

          const valCiclos = yield select(getFormValuesStep3);
          var subsidio = "0"
          for (var e of cataCiclos) {
            if (e.modalidad === valCiclos.informacionbasica.homeModeType) {
              if (e.ciclo === "Z03" && valCiclos.informacionbasica.homeType === "rural") {

                if (e.planCode === valCiclos.informacionbasica.planHomeVivienda) {
                  yield put(change("recursosEconomicosStep4", "financiaciontotal.valorsubcidiado", e.valor));
                }
              }

              if (e.ciclo === "Z02" && valCiclos.informacionbasica.homeType === "urbano") {
                if (e.planCode === valCiclos.informacionbasica.planHomeVivienda) {
                  yield put(change("recursosEconomicosStep4", "financiaciontotal.valorsubcidiado", e.valor));
                }
              }
            }
          }
        }
        var items = yield select(fullFormSelector);
        var refcon = yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_VIVIENDA).doc();
        yield put(increaseSiguiente({ state: true }))

        const userid = yield select(getUserId);
        var d = new Date();
        items.date = d;
        items.userid = userid;
        const temRef = yield select(getRefFiles);



        items.ciclos = cataCiclos.length > 0 ? cataCiclos : newCatalog;
        items.salarioMin = cataCiclos.length > 0 && cataCiclos[0].salarioMin ? emptyObject(cataCiclos[0].salarioMin) : "";
        var resStorage = []
        if (temRef) {
          resStorage = temRef;
          if (va === 5) {
            const elegido = [];
            const disable = ["certificadoConstructora", "certificadoIngresos", "certificadoIcbf", "cartaAprobacion"];

            if (temRef.length > 0) {
              disable.forEach(element => {
                temRef.forEach(elemt => {
                  if (elemt.name === element) {
                    elegido.push(element);
                  }
                });
              });
            }
            if (elegido.length > 0) {
              for (var item of disable) {
                for (var elem of elegido) {
                  if (elem !== item) {
                    yield put(hiddenClose({ val: item, state: false }))
                  }
                }
              }
            } else {
              for (var item of disable) {
                yield put(hiddenClose({ val: item, state: false }))
              }
            }

          }
        }


        const tempFir = yield select(getRefFirmas);
        var idFormulario = yield select(getIdFormulario);
        if (!idFormulario) {
          idFormulario = refcon.id;
        }

        items.step1 = items.step1 ? items.step1 : {};
        items.step2 = items.step2 ? items.step2 : {};
        items.step3 = items.step3 ? items.step3 : {};
        items.step4 = items.step4 ? items.step4 : {};
        items.step5 = items.step5 ? items.step5 : {};
        items.step6 = items.step6 ? items.step6 : {};
        items.refFiles = { refStorage: resStorage };
        items.userColaborador = yield select(getUserColaborador);
        yield firmasOrder(tempFir)

        if (tempFir.length > 0) {
          items.refFirmas = { refStorage: tempFir };
        }

        if (items?.step2?.condicionHogar?.ingresos) {
          items.step2.condicionHogar.ingresos = conversion(items.step2.condicionHogar.ingresos)
        }
        if (items.step1 && items.step1.datospostulante) {
          var data = items.step1.datospostulante;
          if (data.city) {
            data.namecity = yield nameCity(data.city);
          }
          if (data.idPlace) {
            data.nameidPlace = yield nameCity(data.idPlace);
          }
          if (data.departament) {
            data.namedepartament = yield nameDepa(data.departament);
          }
          items.step1.datospostulante = data;
        }

        if (items.step3 && items.step3.informacionpostulacion) {
          var data = items.step3.informacionpostulacion;
          if (data.cityPostu) {
            data.namecityPostu = yield nameCity(data.cityPostu);
          }
          if (data.departamentPostu) {
            data.namedepartamentPostu = yield nameDepa(data.departamentPostu);
          }
          items.step3.informacionpostulacion = data;
        }

        if (items.step3 && items.step3.modalidadvivienda) {
          var data = items.step3.modalidadvivienda;
          if (data.cityModalidad) {
            data.namecityModalidad = yield nameCity(data.cityModalidad);
          }
          if (data.departamentoModalidad) {
            data.namedepartamentoModalidad = yield nameDepa(data.departamentoModalidad);
          }
          items.step3.modalidadvivienda = data;
        }


        if (items.step1 && items.step1.informacionlaboral) {

          const inf = items.step1.informacionlaboral;
          if (inf.subsidioSelect && !inf.subsidioSelect.nit) {
            var aux = inf.subsidioSelect.split(";")
            var subsidio = {
              nit: aux[0],
              name: aux[1],
              place: aux[2],
              phone: aux[3],
            }
            items.step1.informacionlaboral.subsidioData = subsidio;
          }
          if (inf.laborandoSelect && !inf.laborandoSelect.name) {
            if (inf.laborandoSelect !== "otraEmpresa") {
              var aux2 = inf.laborandoSelect.split(";")
              var laborando = {
                name: aux2[0],
                place: aux2[1],
                phone: aux2[2],
              }
              items.step1.informacionlaboral.laborandoData = laborando;
            }
          }
        }


        const dataAhorro = yield select(getAddRecursoAhorro);
        const dataComple = yield select(getAddRecursoComplementario);
        if (dataAhorro.length > 0) {
          let aux = []
          dataAhorro.forEach(element => {
            aux.push({
              tiporecursoA: element[0],
              entityA: element[1],
              openDateA: element[2],
              safeValueA: element[3]
            });
          });
          items.step4.ahorroprevio = aux;

          yield put(change("recursosEconomicosStep4", "ahorroprevio", ""))
        }
        if (dataComple.length > 0) {
          var auxC = [];

          dataComple.forEach(element => {
            auxC.push({
              tiporecursoR: element[0],
              entityR: element[1],
              openDateR: element[2],
              safeValueR: element[3]
            })

          });
          items.step4.recursoscomplementarios = auxC;
          yield put(change("recursosEconomicosStep4", "recursoscomplementarios", ""))
        }


        if (items.step4 && items.step4.financiaciontotal) {
          const temp = items.step4.financiaciontotal;

          var fina = {
            totalahorroprevio: emptyObject(temp.totalahorroprevio),
            totalrecursos: emptyObject(temp.totalrecursos),
            valorsubcidiado: conversion(temp.valorsubcidiado),
            valorvivienda: conversion(temp.valorvivienda),
            cierre: "0",
          }
          const cierre = (0 + parseInt(emptyCero(fina?.totalahorroprevio)) + parseInt(emptyCero(fina?.totalrecursos)) + parseInt(emptyCero(fina?.valorsubcidiado))).toString();
          fina.cierre = cierre;
          items.step4.financiaciontotal = fina;
        }

        // if (items.step4 && items.step4.cuentasCredito) {
        //   const catalogo = yield select(getCatalogosCargados);

        //   const temp = items.step4.cuentasCredito;

        //   var fuente = {
        //     entityFuente:traerValue({ label: emptyObject(temp.entityFuente), catalogo: catalogo.entityFuente}),
        //     fuentesFinanciamiento: emptyObject(temp.fuentesFinanciamiento),
        //     openDateFuente: emptyObject(temp.openDateFuente),
        //   }
        //   items.step4.cuentasCredito = fuente;
        // }


        if (items.step3 && items.step3.valorconstruccion) {
          const temp = items.step3.valorconstruccion;
          var valor = {
            avaluocatastral: conversion(temp.avaluocatastral),
            presupuesto: conversion(temp.presupuesto),
            valortotal: temp.valortotal,
          }
          items.step3.valorconstruccion = valor;

        }

        if (final) {
          yield put(reloadPage({ reload: false }));
          yield put(contactoAdd({ message: "Procesando su petición, permanezca en línea", error: false }));
        }


        items.state = "borrador";
        firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_VIVIENDA).doc(idFormulario).set(items);

        if (va === 2) {

          const formFirmas = yield select(getFormFirmas);
          if (formFirmas.length > 0) {

            yield formFirmas.map(fileData => {
              const fireStorageRef = firebaseStorage.ref();
              const fileReference = fireStorageRef.child(`${idFormulario}/${fileData.formName}/${fileData.fieldName}/${fileData.fileName}`);
              fileReference.put(fileData.file);
              let anexo = {
                nameArchivo: fileData.fileName,
                name: fileData.fieldName,
                refFile: `https://storage.googleapis.com/${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}/${idFormulario}/${fileData.formName}/${fileData.fieldName}/${fileData.fileName}`
              }
              tempFir.push(anexo);
            });
            yield put(saveRefirmas({ data: tempFir, state: true }))
            const refFirm = {
              refStorage: tempFir,
            };

            firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_VIVIENDA).doc(idFormulario).set({ refFirmas: refFirm }, { merge: true });
            yield firmasOrder(tempFir)
          }
        }
        var banFil = true;
        let banName = false;
        if (files) {
          var refeFiles = [];
          for (var fileData of formFiles) {
            const refFil = `${idFormulario}/${fileData.formName}/${fileData.fieldName}/${fileData.fileName}`;

            if (items.refFiles.refStorage.length > 0) {
              refeFiles = items.refFiles.refStorage;
            }
            if (refeFiles.length > 0) {
              refeFiles.forEach(element => {
                if (!element.refFile.includes(fileData.fileName)) {
                  if (element.refFile === refFil || element.lastModified === fileData.file.lastModified) {
                    banFil = false;
                    return;
                  }
                } else {
                  banName = true;
                  return;
                }
              });
            }

            if (banFil && !banName) {

              const fireStorageRef = firebaseStorage.ref();
              const fileReference = fireStorageRef.child(`${idFormulario}/${fileData.formName}/${fileData.fieldName}/${fileData.fileName}`);
              yield fileReference.put(fileData.file);

              refeFiles.push({
                size: fileData.file.size,
                nameArchivo: fileData.fileName,
                lastModified: fileData.file.lastModified,
                type: fileData.file.type,
                name: fileData.fieldName,
                refFile: refFil,
              });
            }
            if (banName) {
              yield put(increaseSiguiente({ state: false, message: { mes: `El nombre del anexo ${fileData.fileName}  es invalido, no se permiten anexos con el mismo nombre. Cambie el nombre e intente adjuntarlo nuevamente o cambie de archivo.`, errorMo: false, error: false } }))
            }

          }

          yield firebaseDatabase.collection(process.env.REACT_APP_FIREBASE_COLECCION_VIVIENDA).doc(idFormulario).set({ refFiles: { refStorage: refeFiles } }, { merge: true });
          yield put(saveRefFiles(refeFiles));
          yield anexosOrder(refeFiles);

          if (salvar) {
            yield put(incrementDecrement(false));
            yield put(incrementDecrement(true));
            if (banDel) {
              yield put(increaseSiguiente({ state: false, message: { mes: "El nombre del anexo que esta adjuntado es invalido, no se permiten caracteres especiales  en los nombres de anexos. Cambie el nombre e intente adjuntarlo nuevamente o cambie de archivo.", errorMo: false, error: false } }))
            } else {
              if (banFil && !banName) {
                yield put(increaseSiguiente({ state: false, message: { mes: "Guardado con exito.", errorMo: false, error: true } }))
              }
            }
          }

        } else {
          if (salvar) {
            if (banDel) {
              yield put(incrementDecrement(false));
              yield sleep(100)
              yield put(increaseSiguiente({ state: false, message: { mes: "El nombre del anexo que esta adjuntado es invalido, no se permiten caracteres especiales  en los nombres de anexos. Cambie el nombre e intente adjuntarlo nuevamente o cambie de archivo.", errorMo: false, error: false } }))
              yield put(incrementDecrement(true));
            } else {
              if (banFil && !banName) {
                yield put(increaseSiguiente({ state: false, message: { mes: "Guardado con exito.", errorMo: false, error: true } }))
              }
            }
          }
        }


        if (final) {
          var info = {}
          const one = items?.step1?.datospostulante;
          const two = items?.step2?.condicionHogar;
          info = {
            documentType: one?.documentType,
            numberId: one?.numberId,
            firstName: one?.firstName,
            middleName: one?.middleName,
            lastName: one?.lastName,
            birthdate: two?.birthdate,
            sex: two?.sex,
            companyPhone: one?.companyPhone,
            phone: "0000000",
            companyEmail: one?.companyEmail,
          }

          const consultaOportunidad = yield consumeConsultaOportunidad({ tipo: info.documentType, id: info.numberId });
          if (consultaOportunidad && (consultaOportunidad.length > 0 || consultaOportunidad.nroOportunidad)) {
            yield put(reloadPage({ reload: true }));
            yield put(contactoAdd({
              message: "La petición ha finalizado, su solicitud no pudo ser procesada. El postulante se encuentra con una solicitud en proceso de validación. Para cualquier inquietud adicional puedes llamar al 602 8862727 opción 2 o escribir a servicioalcliente@comfenalcovalle.com.co",
              error: true
            }));


          } else {
            yield consumeOportunidad({ tipo: info.documentType, id: info.numberId, info, items: items, idFormulario, actualiza: false })
          }
        }
        yield put(saveEraserSuccess(idFormulario));
        yield put(limpiarAdjuntos(true));

      } else {
        yield put(increaseSiguiente({ state: false, message: "Si no ingresas algún ahorro previo, es obligatorio ingresar al menos un recurso complementario como lo es Crédito Aprobado. Ingrese por favor el recurso complementario que aplique, para su solicitud" }))
      }
    } else {
      yield put(increaseSiguiente({ state: false, message: "Debe diligenciar completamente la Sección:  " + respuesta.campoF + " son requeridos" }))
    }

  } catch (error) {
    console.error("Function.saveEraser", error);
  }

}

function* nameDepa(id) {
  const catalogo = yield select(getCatalogosCargados)
  var name = ""
  catalogo.deptos.forEach(element => {
    if (element.value === id) {
      name = element.label;
      return;
    }
  });
  return name;
}

function* nameCity(action) {

  const catalogo = yield select(getCatalogosCargados)
  var name = ""
  catalogo.cities.forEach(element => {
    if (element.value === action) {
      name = element.label;
      return name;
    }
  });
  return name;
}

export function* createCatalog(data) {

}


function* crearHomeType(catalogo, id, homeType) {

  var arrCitys = [];
  const ciclos = catalogo.ciclos;
  const hMtype = catalogo.homeModeType;
  const valid = yield select(getFormValuesStep3);
  if (valid?.informacionbasica?.homeModeType) {
    const ndata = {
      disable: false,
      options: [],
      field: "homeModeType",
    };
    yield put(loadDocumentSuccess(ndata));
    yield sleep(500);

  }
  ciclos.forEach((element) => {
    if (element.ciclo === "Z03" && "rural" === homeType && id === element.planCode) {
      hMtype.forEach(i => {
        if (i.value === element.modalidad) {
          arrCitys.push(i);
        }
      });
    }
    if (element.ciclo === "Z02" && "urbano" === homeType && id === element.planCode) {
      hMtype.forEach(i => {
        if (i.value === element.modalidad) {
          arrCitys.push(i);
        }
      });
    }
  });
  arrCitys = arrCitys.filter(onlyUnique);
  return arrCitys;
}

function* crearPlanHome(catalogo, id) {
  const ciclos = catalogo.ciclos;
  var arrCitys = [];
  const valid = yield select(getFormValuesStep3);
  if (valid?.informacionbasica?.planHomeVivienda) {
    const ndata = {
      disable: false,
      options: [],
      field: "planHomeVivienda",
    };
    const ndata2 = {
      disable: false,
      options: [],
      field: "homeModeType",
    };
    yield put(loadDocumentSuccess(ndata));
    yield sleep(500);
    yield put(loadDocumentSuccess(ndata2));
  }

  ciclos.forEach((element) => {
    if (id === "rural") {
      if (element.ciclo === "Z03" && "1" === element.plan) {
        if (!exist(arrCitys, "VIS")) {
          arrCitys.push({
            label: "Vivienda de interes social (VIS)",
            value: "VIS",
          });
        }
      }
      if (element.ciclo === "Z03" && "2" === element.plan) {
        if (!exist(arrCitys, "VIP")) {
          arrCitys.push({
            label: "Vivienda de interes prioritario (VIP)",
            value: "VIP",
          });
        }
      }
      if (element.ciclo === "Z03" && "3" === element.plan) {
        if (!exist(arrCitys, "VIPA")) {
          arrCitys.push({
            label: "Vivienda de interes prioritario para ahorro (VIPA)",
            value: "VIPA",
          });
        }
      }
    }
    if (id === "urbano") {
      if (element.ciclo === "Z02" && "1" === element.plan) {
        if (!exist(arrCitys, "VIS")) {
          arrCitys.push({
            label: "Vivienda de interes social (VIS)",
            value: "VIS",
          });
        }
      }
      if (element.ciclo === "Z02" && "2" === element.plan) {
        if (!exist(arrCitys, "VIP")) {
          arrCitys.push({
            label: "Vivienda de interes prioritario (VIP)",
            value: "VIP",
          });
        }
      }
      if (element.ciclo === "Z02" && "3" === element.plan) {
        if (!exist(arrCitys, "VIPA")) {
          arrCitys.push({
            label: "Vivienda de interes prioritario para ahorro (VIPA)",
            value: "VIPA",
          });
        }
      }
    }
  });
  return arrCitys;
}

function ordenar(a, b) {
  if (a.label > b.label) {
    return 1;
  }
  if (a.label < b.label) {
    return -1;
  }
  return 0;
}

function ordenarEdad(a, b) {
  if (a[6].edad > b[6].edad) {
    return 1;
  }
  if (a[6].edad < b[6].edad) {
    return -1;
  }
  return 0;
}

function ordenar2(a, b) {
  if (a.value > b.value) {
    return 1;
  }
  if (a.value < b.value) {
    return -1;
  }
  return 0;
}

function conversion(value) {
  var fin = "";
  if (value) {
    fin = value;
    var aux = value.split(" ");
    if (aux.length > 1) {
      fin = "";
      var tem = aux[1].split(",");
      tem.forEach(element => {
        fin = fin + element;
      });
    }
  }
  return fin;
}

function* sumaTotal(action) {

  const { meta: { field, form }, payload, } = action;

  try {
    const nameField = field.split('.');

    if (form === "conformacionHogarStep2") {

      if (nameField[2] === "ingresosMensual" || nameField[1] === "ingresos") {
        const totalAnte = yield select(getCompanyValuesStep2);


        if (totalAnte?.integrantesHogar?.inthogarpostulantes) {
          var aux = []
          var c = 0;
          var temp = []
          totalAnte.integrantesHogar.inthogarpostulantes.forEach(element => {
            element.ingresosMensual = conversion(element.ingresosMensual);
            temp.push(element);
          })
          temp.forEach(element => {
            aux.push({ field: "integrantesHogar.inthogarpostulantes[" + c + "].ingresosMensual", valor: element.ingresosMensual })
            c = c + 1;
          });
          yield put(updateIngreStep2({ clave: "inicio", value: aux }));
        }
        if (totalAnte.condicionHogar) {
          if (totalAnte.condicionHogar.ingresos) {

            yield put(updateIngreStep2({ field: "condicionHogar.ingresos", valor: conversion(totalAnte.condicionHogar.ingresos) }));
          }
        }


        const value = yield select(getUpdateIngreStep2);
        var con = 0;
        if (value.ingresos) {
          con = con + parseInt(value.ingresos.valor);
        }
        if (value.relative) {
          value.relative.forEach(element => {
            if (element.valor) {
              con = con + parseInt(element.valor, 10);
            }
          });

        }
        const total = con;
        yield put(change("conformacionHogarStep2", "totalingresos.totalingresos", total.toString()))
      }
    }
    if (form === "postulacionStep3") {

      if (nameField[1] === "presupuesto" || nameField[1] === "avaluocatastral") {
        const totalAnte = yield select(getCompanyValuesStep3);
        var c = 0;
        if (totalAnte.valorconstruccion) {
          if (totalAnte.valorconstruccion.presupuesto) {
            c = c + parseInt(conversion(totalAnte.valorconstruccion.presupuesto));
          }

        }
        if (totalAnte.valorconstruccion) {
          if (totalAnte.valorconstruccion.avaluocatastral) {
            c = c + parseInt(conversion(totalAnte.valorconstruccion.avaluocatastral));
          }
        }
        yield put(change("postulacionStep3", "valorconstruccion.valortotal", c.toString()))
      }
    }

  } catch (error) {
    console.error("Function.sumaTotal", error);
  }
}

function* suma(action) {

  const { value: { member } } = action;
  const step2 = yield select(getFormValuesStep2)
  try {
    var aux = member.split('[')
    var pos = aux[1].split("]");
    var ban = true;
    const idInth = step2.integrantesHogar.inthogarpostulantes[parseInt(pos)].idPostu;
    const inthObliga = yield select(getIntegrantesObligatorios);

    if (inthObliga !== false) {
      inthObliga.forEach(e => {
        if (parseInt(idInth) === parseInt(e[2].label)) {
          ban = false;
          return;
        }
      });
    }
    if (aux[0] === 'inthogarpostulantes' && ban) {

      var name = member + '.relative'
      yield put(updateDeleteStep2({ field: name }))
      yield sleep(2000);
      const value = yield select(getUpdateIngreStep2);
      var con = 0;

      if (value.ingresos.valor) {
        con = con + parseInt(value.ingresos.valor);
      }
      if (value.relative) {
        value.relative.forEach(element => {
          if (element.valor && element.field !== `integrantesHogar.${member}.ingresosMensual`) {
            con = con + parseInt(element.valor, 10);
          }
        });

      }
      const total = con;
      yield put(change("conformacionHogarStep2", "totalingresos.totalingresos", total.toString()))

    } else {
      yield put(increaseSiguiente({ state: false, message: "El integrante de su grupo familiar que desea borrar es obligatorio en la postulación .No puede ser eliminado" }))
    }
  } catch (error) {
    console.error("Function.suma", error);
  }

}

function idDeparta(catalogo, id) {
  var idDepa = id
  catalogo.forEach(element => {
    if (element.value === id) {
      idDepa = element.id_depa;
    }
  });
  return idDepa;
}

function* enableDisable(action) {
  const { meta: { field, form }, payload, } = action;

  try {
    const catalogo = yield select(getCatalogosCargados);

    var newData = {};
    var aux = field.split(".");
    var newDataCh = {};

    if (form === "informacionBasicaStep1") {
      const nameField = field.split('.');
      if (nameField[1] === "laborandoSelect" || nameField[1] === "subsidioSelect") {

        if (payload === "otraEmpresa" && nameField[1] === "laborandoSelect") {
          yield put(hiddenDisable({ state: true, field: "companyTableLabor" }))
          yield put(hiddenDisable({ state: false, field: "companyJobName" }))
          yield put(hiddenDisable({ state: false, field: "companyJobAddress" }))
          yield put(hiddenDisable({ state: false, field: "companyPhoneJob" }))


        } else {
          const aux = payload.split(';');
          if (nameField[1] === "subsidioSelect") {

            var tableS = []
            tableS.push(aux[0]);
            tableS.push(aux[1]);
            tableS.push(aux[2]);
            tableS.push(aux[3]);

            yield put(hiddenDisable({ state: false, field: "companyTable" }))
            yield put(optionsTable({ field: "companyTable", data: tableS }));

          } else {
            yield put(hiddenDisable({ state: true, field: "companyJobName" }))
            yield put(hiddenDisable({ state: true, field: "companyJobAddress" }))
            yield put(hiddenDisable({ state: true, field: "companyPhoneJob" }))
            let tableL = []
            tableL.push(aux[0]);
            tableL.push(aux[1]);
            tableL.push(aux[2]);
            yield put(hiddenDisable({ state: false, field: "companyTableLabor" }))
            yield put(optionsTable({ field: "companyTableLabor", data: tableL }));
          }
        }
      }
    }

    if (form === "recursosEconomicosStep4") {
      if (field === "ahorroprevio.tiporecursoA" || field === "recursoscomplementarios.tiporecursoR") {
        var aux = field.split(".");
        if (aux[1] === "tiporecursoA") {
          if (payload === "9") {
            yield put(hiddenClose({ state: false, val: "otrorecursoA" }));
          }
          if (payload !== "9") {
            yield put(hiddenClose({ state: true, val: "otrorecursoA" }));
            yield put(change("recursosEconomicosStep4", "otrorecursoA", ""));
          }
        }
        if (aux[1] === "tiporecursoR") {
          if (payload === "5") {
            yield put(hiddenClose({ state: false, val: "otrorecursoR" }));
          }

          if (payload !== "5") {
            yield put(hiddenClose({ state: true, val: "otrorecursoR" }));
            yield put(change("recursosEconomicosStep4", "otrorecursoR", ""));
          }
        }
      }


    }
    if (form === "recursosEconomicosStep4") {

      if (field === "financiaciontotal.totalrecursos" || field === "financiaciontotal.totalahorroprevio" || field === "financiaciontotal.valorsubcidiado") {
        const totalAnte = yield select(getCompanyValuesStep4);
        var newTotalVivienda = 0;

        if (totalAnte?.financiaciontotal?.totalahorroprevio) {
          newTotalVivienda = parseInt(conversion(totalAnte.financiaciontotal.totalahorroprevio)) + newTotalVivienda;
        }
        if (totalAnte?.financiaciontotal?.totalrecursos) {
          newTotalVivienda = parseInt(conversion(totalAnte.financiaciontotal.totalrecursos)) + newTotalVivienda;
        }
        if (totalAnte?.financiaciontotal?.valorsubcidiado) {
          newTotalVivienda = parseInt(conversion(totalAnte.financiaciontotal.valorsubcidiado)) + newTotalVivienda;
        }

        yield put(change("recursosEconomicosStep4", "financiaciontotal.valorvivienda", newTotalVivienda.toString()))
      }
    }

    if (form === "postulacionStep3" && field === 'informacionbasica.subsidio') {
      yield put(activateRadio({ state: payload }))
      if (payload === "1") {
        yield put(callDinamicDisable({ field: "entidadSubsidio", state: false }));
      } else {
        yield put(callDinamicDisable({ field: "entidadSubsidio", state: true }));
        yield put(change("postulacionStep3", "informacionbasica.entidadSubsidio", ""));

      }
    }

    if (form === "postulacionStep3" && field === 'informacionbasica.homeModeType') {
      // yield put(change("informacionBasicaStep1", "homeType", ""));
      if (payload === "1" || payload === "4") {
        // yield put(hiddenDisable({ state: true, field: "modalidadvivienda" }));
        // yield put(hiddenDisable({ state: true, field: "valorconstruccion" }));
        yield put(step3Completo(false));
      } else {
        yield put(step3Completo(true));
        // yield put(hiddenDisable({ state: false, field: "modalidadvivienda" }));
        // yield put(hiddenDisable({ state: false, field: "valorconstruccion" }));
      }

    }


    switch (aux[1]) {
      case "departament":
        const ciudades = catalogo.cities;
        const depa = idDeparta(catalogo.deptos, payload)
        var citiesP = crearCities(ciudades, depa);
        newData = {
          disable: false,
          options: citiesP.sort(ordenar),
          field: "city",
        };
        return yield put(loadDocumentSuccess(newData));

      case "departamentoModalidad":
        const ciudadesM = catalogo.cities;
        const depam = idDeparta(catalogo.deptos, payload)
        var citiesP = crearCities(ciudadesM, depam);
        newData = {
          disable: false,
          options: citiesP.sort(ordenar),
          field: "cityModalidad",
        };
        return yield put(loadDocumentSuccess(newData));

      case "departamentPostu":
        const depaP = idDeparta(catalogo.deptos, payload)
        const ciudadesD = catalogo.cities;
        var citiesP = crearCities(ciudadesD, depaP);
        newData = {
          disable: false,
          options: citiesP.sort(ordenar),
          field: "cityPostu",
        };
        return yield put(loadDocumentSuccess(newData));

      case "planHomeVivienda":
        const mod = yield select(getCompanyValuesStep3);
        var homeType = yield crearHomeType(catalogo, payload, mod.informacionbasica.homeType);
        const ndata = {
          disable: false,
          options: homeType.sort(ordenar),
          field: "homeModeType",
        };
        yield put(loadDocumentSuccess(ndata));
        return yield put(change("postulacionStep3", "informacionbasica.homeModeType", ""));

      case "homeType":
        var planHome = yield crearPlanHome(catalogo, payload);
        const npdata = {
          disable: false,
          options: planHome.sort(ordenar),
          field: "planHomeVivienda",
        };
        yield put(loadDocumentSuccess(npdata));
        return yield put(change("postulacionStep3", "informacionbasica.planHomeVivienda", ""));
      case "homeModeType":
        var stepe3 = yield select(getFormValuesStep3);
        if (stepe3.modalidadvivienda) {
          stepe3.modalidadvivienda = {};
        }
        if (stepe3.valorconstruccion) {
          stepe3.valorconstruccion = {};
        }
        return yield put(initialize(FORM_NAME_STEP3, stepe3));

      default:
        return;
    }
  } catch (error) {
    console.error("Function.enableDisable", error);
  }

}

function traerValue(action) {
  const { catalogo, label } = action;
  var value = "";
  catalogo.forEach(element => {
    if (element.label === label) {
      value = element.value;
    }
  });
  return value;
}
const sleep = (milliseconds) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds))
}

function* newRecurso(action) {
  try {
    const catalogo = yield select(getCatalogosCargados);
    const tipoA = catalogo.tiporecursoA;
    var value = yield select(getCompanyValuesStep4);
    const pos = yield select(getAddRecursoAhorro);

    if (value.ahorroprevio) {
      yield put(rebootForm(true))
      value.ahorroprevio.entityA = traerValue({ label: value.ahorroprevio.entityA, catalogo: catalogo.entity });
      const validA = yield validateFormResours({ data: value.ahorroprevio, tipo: "ahorro" });

      if (validA) {
        var cesant = false;
        var cahpr = false;
        pos.forEach(element => {
          if (element[0].value === "2") {
            cesant = true;
          }
          if (element[0].value === "1") {
            cahpr = true;
          }
        });



        const ah = value.ahorroprevio
        let aux = []
        let ahorroType = []
        if (value.ahorroprevio.tiporecursoA === "9") {
          aux.push({ label: value.ahorroprevio.otrorecursoA, value: value.ahorroprevio.tiporecursoA });
          catalogo.tiporecursoA.forEach(element => {
            if (element.value !== "9") {
              ahorroType.push(element);
            }
          });
        } else {
          catalogo.tiporecursoA.forEach(element => {
            if (element.value === ah.tiporecursoA) {
              aux.push(element)
              if (!cahpr && ah.tiporecursoA === "1") {
                ahorroType.push(element);
              }
              if (!cesant && ah.tiporecursoA === "2") {
                ahorroType.push(element);
              }
            } else {
              ahorroType.push(element);
            }
          });
        }
        catalogo.entity.forEach(element => {
          if (element.value === ah.entityA) {
            aux.push(element)
            return;
          }
        });

        aux.push({ label: ah.openDateA, value: ah.openDateA })
        aux.push({ label: ah.safeValueA, value: conversion(ah.safeValueA) })

        yield put(addRecursoAhorro({ field: "ahorro", data: aux }));
        const data = yield select(getAddRecursoAhorro);
        yield put(optionsTable({ field: "tiporecursoA", data: ahorroType }));
        catalogo.tiporecursoA = ahorroType.sort(ordenar2);
        yield put(cargarCatalogos(catalogo));
        yield put(optionsTable({ field: "tableAhorro", data: data }));
        yield put(change("recursosEconomicosStep4", "ahorroprevio.entityA", ""))
        yield put(change("recursosEconomicosStep4", "ahorroprevio", ""))
        yield updateTotal({ field: "ahorro", data: data });
        yield sleep(100);
        yield put(rebootForm(false))
      } else {
        yield put(increaseSiguiente({ state: false, message: "Debe diligenciar completamente la Sección, los campos con ( * ) son requeridos  " }))
        yield sleep(200);
        yield put(rebootForm(false))
      }
    }
    if (value.recursoscomplementarios) {
      yield put(rebootForm(true))
      value.recursoscomplementarios.entityR = traerValue({ label: value.recursoscomplementarios.entityR, catalogo: catalogo.entity });
      const validR = yield validateFormResours({ data: value.recursoscomplementarios, tipo: "recurso" });

      if (validR) {
        const rc = value.recursoscomplementarios
        let aux = []
        let recursoType = []

        if (value.recursoscomplementarios.tiporecursoR === "5") {
          aux.push({ label: value.recursoscomplementarios.otrorecursoR, value: "5" });
          catalogo.tiporecursoR.forEach(element => {
            if (element.value !== "5") {
              recursoType.push(element);
            }
          });
        } else {
          catalogo.tiporecursoR.forEach(element => {
            if (element.value === rc.tiporecursoR) {
              aux.push(element)
            } else {
              recursoType.push(element);
            }
          });
        }
        if (rc.entityR) {
          catalogo.entity.forEach(element => {
            if (element.value === rc.entityR) {
              aux.push(element)
              return;
            }
          });
        } else {
          aux.push({ label: "__", value: "__" })
        }

        if (rc.openDateR) {
          aux.push({ label: rc.openDateR, value: rc.openDateR })
        } else {
          aux.push({ label: "__", value: "__" })
        }

        aux.push({ label: rc.safeValueR, value: conversion(rc.safeValueR) })
        yield put(addRecursoAhorro({ field: "comple", data: aux }));
        const data = yield select(getAddRecursoComplementario);
        yield put(optionsTable({ field: "tiporecursoR", data: recursoType }));
        catalogo.tiporecursoR = recursoType.sort(ordenar2);
        yield put(cargarCatalogos(catalogo));
        yield put(optionsTable({ field: "tableRecurso", data: data }));

        yield put(change("recursosEconomicosStep4", "recursoscomplementarios", ""));
        yield put(change("recursosEconomicosStep4", "recursoscomplementarios.entityR", ""));
        yield updateTotal({ field: "comple", data: data });
        yield sleep(100);
        yield put(rebootForm(false))
      } else {
        yield put(increaseSiguiente({ state: false, message: "Debe diligenciar completamente la Sección, los campos con ( * ) son requeridos  " }))
        yield sleep(200);
        yield put(rebootForm(false))
      }
    }

  } catch (error) {
    console.error("Function.newRecurso", error);
  }

}

function* updateTotal(action) {
  const { field, data } = action;

  try {
    const dataComple = yield select(getAddRecursoComplementario);
    const dataAhorro = yield select(getAddRecursoAhorro);
    if (field) {
      if (field === "ahorro") {

        let totalAhorro = 0;
        data.forEach(element => {
          if (element && element[3]) {
            totalAhorro = totalAhorro + parseInt(conversion(element[3].value))
          }
        });
        yield put(change("recursosEconomicosStep4", "totalahorroprevio.totalAhorro", totalAhorro.toString()))
        yield put(change("recursosEconomicosStep4", "financiaciontotal.totalahorroprevio", totalAhorro.toString()))

      } else {
        let totalComple = 0;
        data.forEach(element => {
          if (element && element[3]) {
            totalComple = totalComple + parseInt(conversion(element[3].value))
          }
        });
        yield put(change("recursosEconomicosStep4", "totalrecursoscomplementarios.totalComple", totalComple.toString()))
        yield put(change("recursosEconomicosStep4", "financiaciontotal.totalrecursos", totalComple.toString()))
      }
    }
  } catch (error) {
    console.error("Function.updateTotal", error);
  }

}

function* cleanTable(action) {
  const { value } = action;
  try {
    if (value.event) {
      const catalogo = yield select(getCatalogosCargados);

      var banW = false;
      catalogo.tipoA.forEach(element => {
        if ((element.value === value.row[0].value && element.label === value.row[0].label) || (value.row[0].value === "9" && element.label !== value.row[0].label)) {
          banW = true;
        }
      });

      if (banW) {
        var bandera = true;
        var updateR1 = false;
        var updateR2 = false;
        if (value.row[0].value === "1") {
          catalogo.tiporecursoA.forEach(element => {
            if (element.value === "1") {
              updateR1 = true;
            }
          });
        }
        if (value.row[0].value === "2") {
          catalogo.tiporecursoA.forEach(element => {
            if (element.value === "2") {
              updateR2 = true;
            }
          });
        }

        var ban2 = false;
        catalogo.tipoA.forEach(element => {
          if (element.value === value.row[0].value) {
            bandera = false;
            if ((value.row[0].value === "2" && updateR2) || (value.row[0].value === "1" && updateR1)) {
              bandera = true;
              ban2 = true;
            }

          }
        });
        if (bandera === false && value.row[0].value !== "9") {
          catalogo.tiporecursoA.push(value.row[0]);
        } else {
          if (!ban2) {
            catalogo.tiporecursoA.push({ label: "Otros recursos", value: "9" });
          }

        }
        catalogo.tiporecursoA.sort(ordenar2)
        yield put(cargarCatalogos(catalogo));
        const data = yield select(getAddRecursoAhorro);
        yield put(optionsTable({ field: "tableAhorro", data: data }));
        yield updateTotal({ field: "ahorro", data: data })
      } else {
        let banderaR = true;
        catalogo.tipoR.forEach(element => {
          if (element.label === value.row[0].label) {
            banderaR = false;
          }
        });
        if (banderaR === false) {
          catalogo.tiporecursoR.push(value.row[0]);
        } else {
          catalogo.tiporecursoR.push({ label: "Otros recursos", value: "5" });
        }
        catalogo.tiporecursoR.sort(ordenar2)
        yield put(cargarCatalogos(catalogo));
        const data = yield select(getAddRecursoComplementario);
        yield put(optionsTable({ field: "tableRecurso", data: data }));
        yield updateTotal({ field: "comple", data: data })
      }
    }
  } catch (error) {
    console.error("Function.cleanTable", error);
  }

}

function* addInthogarPostulante(action) {
  const { value: { row, ban } } = action;
  try {
    var inth = {
      namePostu: row[0].label,
      documentTypePostu: row[1].label,
      idPostu: row[2].label,
      birthdatePostu: row[3].label,
      sexPostu: row[4].label,
      relativePostu: row[5].label,
    }

    const step2 = yield select(getCompanyValuesStep2);
    if (step2?.integrantesHogar?.inthogarpostulantes) {
      step2.integrantesHogar.inthogarpostulantes.push(inth)
    } else {
      if (step2 !== undefined) {
        var inthogarpostu = [];
        inthogarpostu.push(inth)
        const integrantesH = { inthogarpostulantes: inthogarpostu }
        step2.integrantesHogar = integrantesH;
      }

    }
    if (step2 !== undefined) {
      yield put(initialize(FORM_NAME_STEP2, step2));
    } else {
      var inthogarpostu = [];
      inthogarpostu.push(inth)
      const integrantesH = { inthogarpostulantes: inthogarpostu }
      yield put(initialize(FORM_NAME_STEP2, { integrantesHogar: integrantesH }));
    }


  } catch (error) {
    console.error("Function.addInthogarPostulante", error);
  }

}

function* anexosOrder(action) {
  try {
    const name = ["fotocopyCedula",
      "certificadoAhorro", "cartaLaboral", "certificadoCesantias", "resolucionPensional",
      "certificadoConstructora", "certificadoIngresos", "cartaAprobacion", "certificadoMedico",
      "certificadoTradicion", "certificadoIcbf", "otrosanexos"];

    for (var ele of name) {
      var aux = [];
      var bandera = false;
      for (var item of action) {
        if (ele === item.name) {
          bandera = true;
          var dat = yield cargarAnexos(item)
          aux.push(dat)
        }
      }
      if (bandera) {
        yield put(anexosStorage({ data: aux, field: ele, state: true }));
      }
    }
  } catch (error) {
    console.error("Function.anexosOrder", error);
  }

}

function* firmasOrder(action) {

  try {
    const name = ["firma2", "inthogarpostulantes[0].firma3",
      "inthogarpostulantes[1].firma3", "inthogarpostulantes[2].firma3",
      "inthogarpostulantes[3].firma3", "inthogarpostulantes[4].firma3"];
    for (var item of name) {
      var ban = false;
      var temp = []
      if (action.length > 0) {
        for (var ele of action) {
          if (ele.name === item) {
            ban = true;
            temp.push([ele.nameArchivo, ele.refFile]);
          }
        }
      }

      if (ban) {
        yield put(hiddenClose({ val: item, state: true }))
        yield put(anexosStorage({ data: temp, field: item, state: true, firma: true }));
      } else {
        yield put(anexosStorage({ data: [], field: item, state: false, firma: true }));
      }

    }

  } catch (error) {
    console.error("Function.firmasOrder", error);
  }
}

function* grabar(action) {

  const url = "https://us-central1-comfenalco-valle-firebase-lab.cloudfunctions.net/downloadCatalog";
  const url3 = "https://us-central1-comfenalco-valle-firebase-prod.cloudfunctions.net/downloadCatalog"
  const url2 = "http://localhost:5001/comfenalco-valle-firebase-prod/us-central1/downloadCatalog"

  const headers = HEADERS_WS;
  try {
    for (var element of action) {
      var body = { body: { data: element.data, name: element.name } }
      yield call(axios.post, url3, body, headers);
    }
  } catch (error) {
    console.error(error)
  }


}

function* enviarEmail(action) {
  try {
    const url = process.env.REACT_APP_URL_SEND_EMAIL;

    const headers = HEADERS_WS;
    action.to = "<jamu@comfenalcovalle.com.co>";
    action.subject = "Falla en la actualización de oportunidad de susbsidio Web";
    const body = action;

    const respues = yield call(axios.post, url, body, headers);

  } catch (error) {
    console.error("Function.enviarEmail", error);
  }

}

function* cargarAnexos(action) {
  try {
    const name = action.nameArchivo;
    const link = yield firebaseStorage.ref(action.refFile).getDownloadURL();
    var data = []
    data.push(name)
    data.push(link)
    return data;
  } catch (error) {
    console.error("Function.cargarAnexos", error);
  }

}


function* cargaArchivo(action) {

  const { value: { event: { files }, fileData } } = action;
  try {
    var valido = true;
    const nameArchivo = files[files.length - 1].name.split(".");
    valido = letras_numbers(nameArchivo[0]);
    console.log(files[files.length - 1].name, valido);
    if (valido) {
      var base = yield select(getbase64Add)
      var ban = {};
      var bandera = false;
      let ban_name = false;
      if (files) {
        var n = fileData.name;
        if (base.length > 0) {
          base.forEach(element => {
            if (element.name === files[files.length - 1].lastModified) {
              bandera = true;
            }
            if (element.doc.nombreArchivo.includes(files[files.length - 1].name)) {
              ban_name = true;
            }

          });
        }
        if (bandera === false) {
          yield put(saveToUploadFile({ files, fileData }));
          const res = yield getBase64(files[files.length - 1]);
          var aux = res.split(",");
          var mime = files[files.length - 1].type;
          ban = {
            size: files[files.length - 1].size,
            name: files[files.length - 1].lastModified,
            doc: {
              contenidoArchivo: aux[1],
              extensionArchivo: mime,
              nombreArchivo: files[files.length - 1].name
            }
          }
          if (ban_name) {
            ban.doc.nombreArchivo = `${ban.doc.nombreArchivo.length > 80 ? ban.doc.nombreArchivo.substr(0, 80) : ban.doc.nombreArchivo}-${ban.name}`
          }
          yield put(base64Add(ban))
        }
      }
    }
    else {
      // yield put(increaseSiguiente({
      //   state: false,
      //   message: {
      //     error: false, errorMo: false,
      //     mes: "Verifique el nombre del anexo o soporte que está adjuntando. No se permiten caracteres especiales en el nombre del archivo. Actualice el nombre del archivo e intente subirlo nuevamente."
      //   }
      // }))

      const value = yield select(getFormFiles);
      yield submitSalveStep({ value, ban: true })
    }

  } catch (error) {
    console.error("Function.cargaArchivo", error);
  }

}
function* deleteArchivo(action) {
  const { value: { event: { file } } } = action;
  try {
    var base = yield select(getbase64Add)
    var temp = []
    if (base) {
      base.forEach(element => {
        if (element.name === file.lastModified) {
        } else {
          temp.push(element);
        }
      });
      yield put(base64Remove(temp))
    }
  } catch (error) {
    console.error("Function.deleteArchivo", error);
  }

}


function* validateForm(action) {
  const { step1, step2, step3, step4, step5, step6 } = action

  try {
    const va = yield select(getActiveStep);
    var valid = true;
    var campoF = "";
    const requiredInformacionBasica = REQUIRED_INFORMACION_BASICA2;
    const requiredDatosPostulante = REQUIRED_DATOS_POSTULANTE2;
    const requiredInformacionLaboral = REQUIRED_INFORMACION_LABORAL2;
    const requiredCondicionHogar = REQUIRED_CONDICION_HOGAR2;
    const requiredInformacionPostulacion = REQUIRED_INFORMACION_POSTULACION2;
    const requiredModalidadVivienda = REQUIRED_MODALIDAD_VIVIENDA2;
    const requiredValorConstruccion = REQUIRED_VALOR_CONSTRUCCION2;
    const requiredInthogarPostulantes = REQUIRED_INTHOGAR_POSTULANTE2;
    const requiredFinanciacionTotal = REQUIRED_FINANCIACION_TOTAL2;
    const requiredCuentasCredito = REQUIRED_ENTIDAD_CREDITO2;
    if (va === 0) {
      if (step6?.juramentoPartes) {
        if (!step6.juramentoPartes["juramentoCheck"]) {
          valid = false;
        }
      } else {
        valid = false;
      }
    }
    if (va === 1) {


      if (step1?.datospostulante) {
        var ban2 = false;
        var campo = ' Sección "Datos del hogar postulante" campos: (';
        requiredDatosPostulante.forEach(element => {
          const elemen = element.split("-");
          if (!step1.datospostulante[elemen[0]]) {
            valid = false;
            ban2 = true;
            campo = campo + " " + elemen[1] + ",";
          }
        });

        if (ban2) {
          campo = campo + " ) ** ";
          campoF = campoF + campo;
        }
      } else {
        valid = false;
      }
      if (step1?.informacionlaboral) {
        var ban3 = false;
        var campo = ' Sección "Información Laboral" campos: (';
        if (!step1.informacionlaboral["subsidioSelect"]) {
          ban3 = true;
          valid = false;
          campo = campo + " Nombre empresa postulación subsidio,";
        }
        if (!step1.informacionlaboral["laborandoSelect"]) {
          ban3 = true;
          valid = false;
          campo = campo + " Nombre empresa en la que labora,";
        } else if (step1.informacionlaboral?.laborandoSelect === "otraEmpresa") {

          requiredInformacionLaboral.forEach(element => {
            const elemen = element.split("-");
            if (!step1.informacionlaboral[elemen[0]]) {
              ban3 = true;
              valid = false;
              campo = campo + " " + elemen[1] + ",";
            }
          });


        }
        if (ban3) {
          campo = campo + " ) ** ";
          campoF = campoF + campo;
        }
      } else {
        valid = false;
      }
    }
    if (va === 2) {
      if (step2?.condicionHogar) {
        var ban1 = false;
        var campo = ' Sección "Conformación y condición socio económica del hogar" campos: (';
        requiredCondicionHogar.forEach(element => {
          const elemen = element.split("-");
          if (!step2.condicionHogar[elemen[0]]) {
            valid = false;
            ban1 = true;
            campo = campo + " " + elemen[1] + ",";
          }
        });

        if (ban1) {
          campo = campo + " ) ** ";
          campoF = campoF + campo;
        }
      } else {
        valid = false;
      }

      if (step2?.integrantesHogar?.inthogarpostulantes) {
        var ban2 = false;
        var campo = ' Sección "Integrantes del hogar postulante" campos: (';
        step2.integrantesHogar.inthogarpostulantes.forEach(elementPostu => {
          requiredInthogarPostulantes.forEach(element => {
            const elemen = element.split("-");
            if (!elementPostu[elemen[0]]) {
              valid = false;
              campo = campo + " " + elemen[1] + ",";
              ban2 = true;
            }
          });
        });

        if (ban2) {
          campo = campo + " ) ** ";
          campoF = campoF + campo;
        }
      }
    }
    if (va === 3) {
      if (step3?.informacionbasica) {
        var ban1 = false;
        var campo = ' Sección "Información Básica" campos: (';
        if (step3.informacionbasica.subsidio === "1") {
          if (!step3.informacionbasica["entidadSubsidio"]) {
            valid = false;
            ban1 = true;
            campo = campo + " Entidad de la que recibio el subsidio,";
          }
        }
        requiredInformacionBasica.forEach(element => {
          const elemen = element.split("-");
          if (!step3.informacionbasica[elemen[0]]) {
            valid = false;
            ban1 = true;
            campo = campo + " " + elemen[1] + ",";
          }
        });
        if (ban1) {
          campo = campo + " ) ** ";
          campoF = campoF + campo;
        }

      } else {
        valid = false;
        var campo = ' Sección "Información Básica" campos: (';
        requiredInformacionBasica.forEach(element => {
          const elemen = element.split("-");
          campo = campo + " " + elemen[1] + ",";
        });
        campo = campo + " ) ** ";
        campoF = campoF + campo;
      }
      if (step3?.informacionpostulacion) {
        var ban1 = false;
        var campo = ' Sección "Información de la postulación" campos: (';
        requiredInformacionPostulacion.forEach(element => {
          const elemen = element.split("-");
          if (!step3.informacionpostulacion[elemen[0]]) {
            valid = false;
            ban1 = true;
            campo = campo + " " + elemen[1] + ",";
          }
        });

        if (ban1) {
          campo = campo + " ) ** ";
          campoF = campoF + campo;
        }
      } else {
        valid = false;
        var campo = ' Sección "Información de la postulación" campos: (';
        requiredInformacionPostulacion.forEach(element => {
          const elemen = element.split("-");
          campo = campo + " " + elemen[1] + ",";
        });
        campo = campo + " ) ** ";
        campoF = campoF + campo;
      }

      if (step3?.informacionbasica?.homeModeType === "2" || step3?.informacionbasica?.homeModeType === "3") {
        if (step3?.modalidadvivienda) {
          var ban2 = false;
          var campo = ' Sección "Modalidad de vivienda" campos: (';
          requiredModalidadVivienda.forEach(element => {
            const elemen = element.split("-");
            if (!step3.modalidadvivienda[elemen[0]]) {
              valid = false;
              ban2 = true;
              campo = campo + " " + elemen[1] + ",";
            }
          });

          if (ban2) {
            campo = campo + " ) ** ";
            campoF = campoF + campo;
          }
        } else {
          valid = false;
          var campo = ' Sección "Modalidad de vivienda" campos: (';
          requiredModalidadVivienda.forEach(element => {
            const elemen = element.split("-");
            campo = campo + " " + elemen[1] + ",";
          });
          campo = campo + " ) ** ";
          campoF = campoF + campo;
        }
        if (step3?.valorconstruccion) {
          var ban3 = false;
          var campo = ' Sección "Valor de la construcción" campos: (';
          requiredValorConstruccion.forEach(element => {
            const elemen = element.split("-");
            if (!step3.valorconstruccion[elemen[0]]) {
              valid = false;
              ban3 = true;
              campo = campo + " " + elemen[1] + ",";
            }
          });

          if (ban3) {
            campo = campo + " ) ** ";
            campoF = campoF + campo;
          }
        } else {
          valid = false;
          var campo = ' Sección "Valor de la construcción" campos: (';
          requiredValorConstruccion.forEach(element => {
            const elemen = element.split("-");
            campo = campo + " " + elemen[1] + ",";
          });
          campo = campo + " ) ** ";
          campoF = campoF + campo;
        }
      }



    }
    if (va === 4) {
      if (step4?.cuentasCredito) {
        var ban2 = false;
        var campo = ' Sección "Cuentas credito" campos: (';
        requiredCuentasCredito.forEach(element => {
          const elemen = element.split("-");
          if (!step4.cuentasCredito[elemen[0]]) {
            valid = false;
            ban2 = true;
            campo = campo + " " + elemen[1] + ",";
          }
        });

        if (ban2) {
          campo = campo + " ) ** ";
          campoF = campoF + campo;
        }
      } else {
        valid = false;
        var campo = ' Sección "Cuentas de credito" campos: (';
        requiredCuentasCredito.forEach(element => {
          const elemen = element.split("-");
          campo = campo + " " + elemen[1] + ",";
        });
        campo = campo + " ) ** ";
        campoF = campoF + campo;

      }
      if (step4?.financiaciontotal) {
        var ban1 = false;
        var campo = ' Sección "Financiación total para la adquisición de vivienda" campos: (';
        requiredFinanciacionTotal.forEach(element => {
          const elemen = element.split("-");
          if (!step4.financiaciontotal[elemen[0]]) {
            valid = false;
            ban1 = true;
            campo = campo + " " + elemen[1] + ",";
          }
        });

        if (ban1) {
          campo = campo + " ) ** ";
          campoF = campoF + campo;
        }
      } else {
        valid = false;
        var campo = ' Sección "Financiación total para la adquisición de vivienda" campos: (';
        requiredFinanciacionTotal.forEach(element => {
          const elemen = element.split("-");
          campo = campo + " " + elemen[1] + ",";
        });
        campo = campo + " ) ** ";
        campoF = campoF + campo;
      }

    }

    return { valid, campoF };
  } catch (error) {
    console.error("Function.validateForm", error);
  }

}

function* validateFormResours(action) {
  const { data, tipo } = action

  try {
    var valid = true;
    const requiredAhorroPrevio = REQUIRED_AHORRO_PREVIO;
    const requiredRecursoComplementarios = REQUIRED_RECURSO_COMPLEMENTARIOS;

    if (tipo === "ahorro") {

      requiredAhorroPrevio.forEach(element => {
        if (data?.tiporecursoA) {
          if (data.tiporecursoA === "9") {
            if (!data["otrorecursoA"]) {
              valid = false;
            }
          }
        }
        if (!data[element]) {
          valid = false;
        }
      });
    }
    if (tipo === "recurso") {
      if (data?.tiporecursoR) {
        if (data.tiporecursoR === "5") {
          if (!data["otrorecursoR"]) {
            valid = false;
          }
        }
      }
      requiredRecursoComplementarios.forEach(element => {
        if (!data[element]) {
          valid = false;
        }
      });
    }

    return valid;
  } catch (error) {
    console.error("Function.validateFormResours", error);
  }

}

function* deleteAnexos(action) {
  const { field: { name }, row } = action.value;
  try {
    const values = yield select(getDynamicOptions)
    const temRef = yield select(getRefFiles);
    const data = values[name]
    var aux = []
    data.forEach(element => {
      if (element[0] !== row[0]) {
        aux.push(element);
      }
    });

    const ref = []
    var archivo = {}

    temRef.forEach(element => {
      if (element.name === name && element.nameArchivo === row[0]) {
        archivo = element;
      } else {
        ref.push(element)
      }
    });

    yield put(anexosStorage({ data: aux, field: name }));
    yield put(hiddenClose({ val: name, state: false }))
    yield put(saveRefFiles(ref));
    yield deleteArchivo({ value: { event: { file: archivo } } })
    yield put(increaseSiguiente({ state: false, message: { mes: `El anexo "${row[0]} " fue eliminado con exito.`, errorMo: false, error: true } }))

  } catch (error) {
    console.error("Function.deleteAnexos", error);
  }

}

function* deleteFirmas(action) {

  const { specialName, row } = action.value;
  try {
    yield put(hiddenClose({ val: specialName, state: false }));
    yield put(optionsTable({ data: [], field: specialName }));

    const values = yield select(getRefFirmas);
    var aux = []
    values.forEach(element => {
      if (element.name !== specialName) {
        aux.push(element);
      }
    });
    yield put(saveRefirmas({ data: aux }));

  } catch (error) {
    console.error("Function.deleteFirmas", error);
  }

}

function* logoutAction(action) {
  yield logout();
}


export function* watchUsers() {

  yield takeLatest(LOAD_CATALOG, buildDynamicOptions);
  yield takeLatest(INIT_FORMS, initForms);
  yield takeLatest(UPDATE_FIELD_ATTRIBUTE, submitForm);
  yield takeLatest('UPDATE_SINGLE_FIELD', updateSingleField);
  yield takeLatest(INIT_FORM_SECTION_STEP1, initFormStep1);
  yield takeLatest(INIT_ALL_FORMS, initAllForms);
  yield takeLatest(SUBMIT_FORM, submitForm);
  yield takeLatest(ADD_INTHOGAR, addInthogarPostulante);
  yield takeLatest(REDUX_BLUR, sumaTotal);
  yield takeLatest(SUMAR_DATA, suma);
  yield takeLatest(INCREASE_STEP, saveEraser);
  yield takeLatest(SUBMIT_FINAL_FORM, submitFinalForm);
  yield takeLatest(CREATE_CATALOG, createCatalog);
  yield takeLatest(DELETE_ANEXO, deleteAnexos);
  yield takeLatest(DELETE_FIRMA, deleteFirmas);
  yield takeLatest(REDUX_CHANGE, enableDisable);
  yield takeLatest(NEW_RECURSO_ADD, newRecurso);
  yield takeLatest(DELETE_RECURSO_TABLE, cleanTable);
  yield takeLatest(UPLOAD_FILE, cargaArchivo);
  yield takeLatest(REMOVE_FILE, deleteArchivo);
  yield takeLatest(SALVAR_STEP, submitSalveStep);
  yield takeLatest(LOGOUT_SESION, logoutAction);
  yield takeLatest(GET_FIREBASE_USER_SESSION, getFirebaseUser);
}
