import { TrendingUpTwoTone } from "@material-ui/icons";
import { getCatalog, readCSV } from "../../controller/utils";


export const UPDATE_FIELD_ATTRIBUTE = '@vivienda/updateAttribute';
export const UPDATE_COMPANY_FIELD_ATTRIBUTE = '@vivienda/updateCompanyAttribute';
export const SELECT_FORM_FIELD = '@vivienda/updateFormType';
export const GET_USERS = '@vivienda/users';
export const SELECT_TAB = '@vivienda/tab';
export const SIGN_USER = '@vivienda/signUser';
export const LOG_USER = '@vivienda/logUser';
export const AUTH_SUCCESS = '@vivienda/authSuccess';
export const AUTH_ERROR = '@vivienda/authError';
export const AUTH_LOGIN_SUCCESS = '@vivienda/loginSuccess';
export const INCREASE_STEP = '@vivienda/increaseStep';
export const INCREASE_SIGUIENTE = '@vivienda/increaseSiguiente';
export const CLOSE_MODAL_VALID = '@vivienda/closeModalValid';
export const SALVAR_STEP = '@vivienda/salvarStep';
export const DECREASE_STEP = '@vivienda/decreaseStep';
export const HANDLE_STEP = '@vivienda/handleStep';
export const INCREASE_INTEGRANTES = '@vivienda/integrantesInt';
export const DEREASE_INTEGRANTES = '@vivienda/decreaseInt';
export const SUBMIT_SUCCESS = '@vivienda/submitSuccess';
export const FORM_NAME_STEP1 = 'informacionBasicaStep1';
export const FORM_NAME_STEP2 = 'conformacionHogarStep2';
export const FORM_NAME_STEP3 = 'postulacionStep3';
export const FORM_NAME_STEP4 = 'recursosEconomicosStep4';
export const FORM_NAME_STEP5 = 'archivosAdjuntosStep5';
export const FORM_NAME_STEP6 = 'juramentoStep6';
export const INIT_FORM_SECTION_STEP1 = '@vivienda/initSectionsStep1';
export const INIT_ALL_FORMS = '@vivienda/initAllForms';
export const CHARGED_CATALOG = '@vivienda/chargedCatalog';
export const SUBMIT_FORM = '@vivienda/submitForm';
export const REDUX_CHANGE = '@@redux-form/CHANGE';
export const REDUX_BLUR = '@@redux-form/BLUR';
export const CHARGE_CATALOG_DINAMIC = '@@redux-form/CHANGE';
export const LOAD_DOCUMENT_SUCCESS = '@vivienda/loadDocumentSuccess';
export const SAVE_ERASER_SUCCESS = '@vivienda/saveEraserSuccess';
export const SAVE_FINAL_SUCCESS = '@vivienda/saveFinalSuccess';
export const CARGAR_CATALOGOS = '@vivienda/cargarCatalogos'
export const SUMAR_DATA = '@vivienda/sumarData'
export const UPDATE_INGRE_STEP2 = '@vivienda/updateIngreStep2'
export const UPDATE_DELETE_STEP2 = '@vivienda/updateDeleteStep2'
export const UPDATE_TOTAL_STEP2 = '@vivienda/updateTotalStep2'
export const HIDDEN_DISABLE = '@vivienda/hiddenDisable'
export const INIT_FORMS = '@vivienda/initForms'
export const CHARGED_VALIDATOR = '@vivienda/chargedValidator'
export const CONTACTO_ADD = '@vivienda/contactoAdd';
export const RELOAD_PAGE = '@vivienda/reloadPage';
export const CLOSE_MODAL_F = '@vivienda/closeModalF';
export const SAVE_REFFILES = '@vivienda/saveRefFiles';
export const SAVE_REFFIRMAS = '@vivienda/saveRefirmas';
export const GENERATE_PDF = '@vivienda/generatePdf';
export const RENDER_PDF = '@vivienda/renderPdf';
export const GUARDAR_ITEMS = '@vivienda/guardarItems';
export const LIMPIAR_ADJUNTOS = '@vivienda/limpiarAdjuntos';
export const SET_FIREBASE_USER_SESSION = '@vivienda/setFirebaseUserSession';
export const GET_FIREBASE_USER_SESSION = '@vivienda/getFirebaseUserSession';

export const UPDATE_INGRE_STEP4 = '@vivienda/updateIngreStep4';
export const UPDATE_DELETE_STEP4 = '@vivienda/updateDeleteStep4';
export const UPDATE_TOTAL_STEP4 = '@vivienda/updateTotalStep4';
export const OPTIONS_INTHOGAR = '@vivienda/optionsInthogar';
export const INTEGRANTES_OBLIGATION = '@vivienda/integrantesObligation';
export const SAVE_TO_UPLOAD_FILE = '@vivienda/saveToUploadFile';

export const BASE64_ADD = '@vivienda/base64Add';
export const BASE64_REMOVE = '@vivienda/base64Remove';

export const OPEN_FINAL_MODAL = '@vivienda/openFinalModal';
export const SUBMIT_FINAL_FORM = '@vivienda/submitFinalForm';
export const SAVE_FORM_FILES = '@vivienda/saveFormFiles';
export const CALL_DINAMIC_DISABLE = '@vivienda/callDinamicDisable';
export const SAVE_DATA_EXT = '@vivienda/saveDataExt';
export const INIT_VALIDATE = '@vivienda/initValidate';
export const RELOAD_PDF = '@vivienda/reloadPdf';

export const INCREASE_PREVIOS = '@vivienda/integrantesPre';
export const DECREASE_PREVIOS = '@vivienda/decreasePre';
export const ADD_RECURSO_AHORRO = '@vivienda/addRecursoAhorro';
export const NEW_RECURSO_ADD = '@vivienda/newRecursoAdd';
export const DELETE_RECURSO_TABLE = '@vivienda/deleteRecursoTable';
export const ADD_INTHOGAR = '@vivienda/addInthogar';
export const CUENTAS_CREDITO = '@vivienda/cuentasCredito';
export const LOGOUT_SESION = '@vivienda/logoutSesion';

export const INCREASE_RECURSOS = '@vivienda/increaseRecursos';
export const DECREASE_RECURSOS = '@vivienda/decreaseRecursos';
export const LOAD_CATALOG_SUCCESS = '@vivienda/loadCatalogSuccess';
export const LOAD_CATALOG = '@vivienda/loadCatalog';
export const CREATE_CATALOG = '@vivienda/createCatalog';
export const FORM_INTIALIZED = '@vivienda/formInitialized';
export const OPTIONS_TABLE = '@vivienda/optionsTable';
export const ANEXOS_STORAGE = '@vivienda/anexosStorage'
export const DELETE_ANEXO = '@vivienda/deleteAnexo'
export const DELETE_FIRMA = '@vivienda/deleteFirma'
export const REFERENCE_FILES = '@vivienda/referenceFiles'
export const DATA_EMP_SUBSIDIO = '@vivienda/dataEmpSubsidio'
export const INCREMENT_DECREMENT = '@vivienda/incrementDecrement'
export const ENABLED_DISABLED_ARRAY = '@vivienda/enabledDisabledArray'
export const REBOOT_FORM = '@vivienda/rebootForm'
export const SAVE_CATALOGO_CICLOS = '@vivienda/saveCatalogoCiclos';
export const CHANGE_STEP = '@vivienda/changeStep';
export const STEP3_COMPLETO = '@vivienda/step3Completo';
export const OPEN_MODAL_ESPERA = '@vivienda/openModalEspera';
export const SAVE_USER_COLABORADOR = '@vivienda/saveUserColaborador';

//__________
export const AUTENTICATED_USER = '@vivienda/autenticatedUser';


export const JURAMENTO_TITLE = 'Juramento de cumplimiento de requisitos para postulación a subsidio de vivienda'
export const PEOPLE_FORM_TITLE = 'Información laboral';
export const PEOPLE_FORM_SUBTITLE = 'Información de tu solictud';
export const COMPANY_FORM_TITLE = 'Información básica';


export const ARCHIVOS_ADJUNTOS = 'Documentos anexos'
export const ARCHIVOS_ADJUNTOS_SUB_TITLE = 'Documentos que se deben anexar a esta postulación'

export const HOME_DATA_TITLE = 'Datos del hogar postulante';
export const TEXT_MODAL_VALIDADOR = 'Un momento por favor, estamos cargando su información personal en el formulario';


export const FORM_HEADER = 'Estructura Formulario Contáctanos'
export const FORM_PARAGRAPH = 'Tu opinión es muy importante para Comfenalco Valle, para nosotros es muy importante escucharte y resolver tus solicitudes, por eso queremos conocer tu experiencia con nuestros programas y servicios. Recuerda que la veracidad de tus datos nos facilita brindar una respuesta oportuna. Los campos señalados con el signo (*) son obligatorios.';
export const SUBMIT_CONFIRM_PARAGRAPH = 'Al finalizar el formlario no podra actualizarlo de nuevo. ¿Está seguro que desea finalizarlo?';
export const COMPANY_FORM_SUB_TITLE = 'Conformación y condición socio económica del hogar';
export const POSTULATION_FORM_SUB_TITLE = 'Información de la postulación';
export const TOTAL_MONEY_FORM_SUB_TITLE = 'Total ingresos del hogar';
export const MOVEMENT_HOME_SUB_TITLE = 'Modalidad de vivienda (para Construcción en Sitio Propio y Mejoramiento de Vivienda)';
export const INTEGRANTES_HOGAR_TITLE = 'Integrantes del Hogar Postulante';
export const INTEGRANTES_HOGAR_SUB_TITLE = 'Integrante No. ';

export const COMPANY_FORM_SOLICITUD = 'Información de tu solicitud';




export const DISABLED_GENERICOS = {
    "inthogarpostulantes[0].firma3": false,
    "inthogarpostulantes[1].firma3": false,
    "inthogarpostulantes[2].firma3": false,
    "inthogarpostulantes[3].firma3": false,
    "inthogarpostulantes[4].firma3": false,
    "inthogarpostulantes[5].firma3": false,
    "inthogarpostulantes[6].firma3": false,
    "inthogarpostulantes[7].firma3": false,
    "inthogarpostulantes[8].firma3": false,
    "inthogarpostulantes[9].firma3": false,
    "inthogarpostulantes[10].firma3": false,
    "inthogarpostulantes[11].firma3": false,
    "inthogarpostulantes[12].firma3": false,
    "inthogarpostulantes[13].firma3": false,
    "inthogarpostulantes[14].firma3": false,
    "firma2": false,
    certificadoConstructora: false,
    certificadoIngresos: false,
    certificadoIcbf: false,
    cartaAprobacion: false,
    otrorecursoA: true,
    otrorecursoR: true,
    totalingresos: true,
    maritalstatusJefe: true,
    totalAhorro: true,
    totalahorroprevio: true,
    totalrecursos: true,
    totalComple: true,
    entidadSubsidio: false,
    birthdate: true,
    salarioreportafiliacion: true,
    firstName: true,
    middleName: true,
    valorsubcidiado: true,
    valorvivienda: true,
    lastName: true,
    documentType: true,
    numberId: true,
    companyEmail: true,
    departament: true,
    city: true,
    currentAddress: true,
    hood: true,
    companyName: true,
    numDocJefe: true,
    parentescoJefe: true,
    jobJefeHogar: false,
    parentezcoJefe: true,
    sex: true,
    fuenteFinanciamiento: true,
    entityCredito: true,
    dateAprobation: true,
}
export const OPTIONS_FIRMAS = {
    "inthogarpostulantes[0].firma3": [],
    "inthogarpostulantes[1].firma3": [],
    "inthogarpostulantes[2].firma3": [],
    "inthogarpostulantes[3].firma3": [],
    "inthogarpostulantes[4].firma3": [],
    "inthogarpostulantes[5].firma3": [],
    "inthogarpostulantes[6].firma3": [],
    "inthogarpostulantes[7].firma3": [],
    "inthogarpostulantes[8].firma3": [],
    "inthogarpostulantes[9].firma3": [],
    "inthogarpostulantes[10].firma3": [],
    "inthogarpostulantes[11].firma3": [],
    "inthogarpostulantes[12].firma3": [],
    "inthogarpostulantes[13].firma3": [],
    "inthogarpostulantes[14].firma3": [],
    "firma2": [],
}

export const HEADERS_WS = {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
    'Access-Control-Allow-Credentials': 'true',
    "Access-Control-Request-Headers": "access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,auth,authorization,cache-control,content-type,mode",
    'accept': "application/json, text/plain, */*",
    "Authorization": 'Basic Rk9STVVMQVJJT1NXRUI6WWsyMTkwX3c=',
    "content-type": "application/json",
    "Cache-Control": "no-cache",
    'mode': "opaque ",
}

export const DISABLED = [
    "totalingresos",
    "maritalstatusJefe",
    "totalAhorro",
    "totalahorroprevio",
    "totalrecursos",
    "totalComple",
    "birthdate",
    "salarioreportafiliacion",
    "valorsubcidiado",
    "valorvivienda",
    "firstName",
    "middleName",
    "lastName",
    "documentType",
    "numberId",
    "companyEmail",
    "departament",
    "city",
    "idPlace",
    "currentAddress",
    "hood",
    "companyName",
    "numDocJefe",
    "parentescoJefe",
    "jobJefeHogar",
    "parentezcoJefe",
    "sex",
    "fuenteFinanciamiento",
    "entityCredito",
    "dateAprobation",
];
export const REQUIRED_INFORMACION_BASICA = [
    "subsidio",
    "planHomeVivienda",
    "homeType",
    "homeModeType",
    "postulanteType",
];
export const REQUIRED_DATOS_POSTULANTE = [
    "firstName",
    "lastName",
    "documentType",
    "numberId",
    "idPlace",
    "currentAddress",
    "departament",
    "city",
    "hood",
    "companyEmail",
    "companyPhone",
];
export const REQUIRED_INTHOGAR_POSTULANTE = [
    "namePostu",
    "birthdatePostu",
    "documentTypePostu",
    "idPostu",
    "sexPostu",
    "maritalstatusPostu",
    "relativePostu",
    "jobPostu",
    "ingresosMensual",
];
export const REQUIRED_INFORMACION_LABORAL = [
    "companyJobAddress",
    "companyJobName",
    "companyPhoneJob",
];
export const REQUIRED_CONDICION_HOGAR = [
    "companyName",
    "birthdate",
    "documentType",
    "numDocJefe",
    "sex",
    "maritalstatusJefe",
    "parentezcoJefe",
    "jobJefeHogar",
    "ingresos",
];
export const REQUIRED_ENTIDAD_CREDITO = [
    "fuentesFinanciamiento",
    "entityFuente"
];
export const REQUIRED_INFORMACION_POSTULACION = [
    "projectname",
    "ofername",
    "departamentPostu",
    "cityPostu",
];
export const REQUIRED_MODALIDAD_VIVIENDA = [
    "projecttype",
    "propiedadlote",
    "predioaddress",
    "loteaddress",
    "departamentoModalidad",
    "cityModalidad",
    // "urbanizacion",
    // "lotenumber",
    // "barrioModalidad",
    // "licencia",
    // "vencimientoLicencia",
    // "escritura",
    // "escriturafecha",
    // "notariaEscritura",
    // "registertestdate",
    // "areaLoteM2",
    // "frenteLote",
    // "fondoLote",
];
export const REQUIRED_VALOR_CONSTRUCCION = [
    "presupuesto",
    "avaluocatastral",
];
export const REQUIRED_AHORRO_PREVIO = [
    "tiporecursoA",
    "entityA",
    "openDateA",
    "safeValueA",
];
export const REQUIRED_RECURSO_COMPLEMENTARIOS = [
    "tiporecursoR",
    "entityR",
    "openDateR",
    "safeValueR",
];

export const REQUIRED_FINANCIACION_TOTAL = [
    "valorsubcidiado",
    "valorvivienda",
];

export const REQUIRED_INFORMACION_BASICA2 = [
    "subsidio-Ha recibido subsidio de vivienda",
    "planHomeVivienda-Planes de vivienda",
    "homeType-Tipo de vivienda",
    "homeModeType-Modalidad de vivienda",
    "postulanteType-Tipo de afiliado",
];
export const REQUIRED_DATOS_POSTULANTE2 = [
    "firstName-Primer nombre",
    "lastName-Apellidos",
    "documentType-Tipo de documento",
    "numberId-Número de documento",
    "idPlace-Expedida en",
    "currentAddress-Dirección / domicilio actual",
    "departament-Departamento",
    "city-Municipio",
    "hood-Barrio",
    "companyEmail-Correo electrónico",
    "companyPhone-Teléfono / celular",
];
export const REQUIRED_INTHOGAR_POSTULANTE2 = [
    "namePostu-Nombre completo integrante",
    "birthdatePostu-Fecha de nacimiento",
    "documentTypePostu-Tipo de documento",
    "idPostu-Número de documento",
    "sexPostu-Sexo",
    "maritalstatusPostu-Estado civil",
    "relativePostu-Parentesco",
    "jobPostu-Ocupación",
    "ingresosMensual-Ingresos mensuales",
];
export const REQUIRED_INFORMACION_LABORAL2 = [
    "companyJobAddress-Dirección del sitio de trabajo",
    "companyJobName-Empresa donde esta laborando",
    "companyPhoneJob-Teléfono / Celular",
];
export const REQUIRED_CONDICION_HOGAR2 = [
    "companyName-Nombre completo",
    "birthdate-Fecha de nacimiento",
    "documentType-Tipo de documento",
    "numDocJefe-Número de documento",
    "sex-Sexo",
    "maritalstatusJefe-Estado civil",
    "parentezcoJefe-Parentesco",
    "jobJefeHogar-Ocupación",
    "ingresos-Ingresos mensuales",
];
export const REQUIRED_ENTIDAD_CREDITO2 = [
    "fuentesFinanciamiento-Fuente de financiamiento",
    "entityFuente-Entidad de credito"
];
export const REQUIRED_INFORMACION_POSTULACION2 = [
    "projectname-Nombre del proyecto",
    "ofername-Nombre del oferente",
    "departamentPostu-Departamento",
    "cityPostu-Municipio",
];
export const REQUIRED_MODALIDAD_VIVIENDA2 = [
    "projecttype-Tipo de proyecto",
    "propiedadlote-Propiedad del lote",
    "predioaddress-Número matricula inmobiliaria",
    "loteaddress-Dirección del lote",
    "departamentoModalidad-Departamento",
    "cityModalidad-Municipio",
];
export const REQUIRED_VALOR_CONSTRUCCION2 = [
    "presupuesto-Valor presupuesto de obra",
    "avaluocatastral-Valor lote (Avalúo Catastral)",
];

export const REQUIRED_FINANCIACION_TOTAL2 = [
    "valorsubcidiado-Valor subsidio de vivienda",
    "valorvivienda-Valor de la vivienda a adquirir",
];

export const CREATED_PEOPLE_OK = 'Persona registrada exitosamente';
export const CREATED_COMPANY_OK = 'Empresa registrada exitosamente';

export const DATOS_POSTULANTE_TITLE = 'Datos del hogar postulante';
export const INFORMACION_LABORAL_TITLE = 'Información laboral';

export const UPLOAD_FILE = '@vivienda/uploadFile';
export const REMOVE_FILE = '@vivienda/removeFile';
export const UPLOAD_FIRMA = '@vivienda/uploadFirma';
export const REMOVE_FIRMA = '@vivienda/removeFirma';
export const SAVE_FIRMA = '@vivienda/saveFirma';


export const ACTIVATE_RADIO = '@vivienda/activateRadio';
export const HIDDEN_CLOSE = '@vivienda/hiddenClose';


export const JURAMENTO_P4 = `- Toda la información aquí suministrada es verídica y se entenderá presentada bajo la gravedad de juramento
- Mi hogar cumple con las condiciones para ser beneficiario del subsidio familiar de vivienda y no estoy(estamos) incurso(s) en las imposibilidades para solicitarlo. 
- Los ingresos familiares de mi hogar no son superiores al equivalente de (4) cuatro salarios mensuales mínimos legales vigentes (SMMLV).`;

const JURAMENTO_P5 = [
    "- Toda la información aquí suministrada es verídica y se entenderá presentada bajo la gravedad de juramento",
    "- Mi hogar cumple con las condiciones para ser beneficiario del subsidio familiar de vivienda y no estoy(estamos) incurso(s) en las imposibilidades para solicitarlo. ",
    "- Los ingresos familiares de mi hogar no son superiores al equivalente de (4) cuatro salarios mensuales mínimos legales vigentes (SMMLV).",

]




export const LOCAL_CITIES = [];


export const getInfoBasica = (handleRadio) => {
    const SECCION_INF_BASICA = [
        {
            placeholder: "En esta sección encuentras tu información básica para postulación al subsidio de vivienda.Diligencia los campos correspondientes",
            name: "instruccion",
            type: "label",
            typeForm: "label",
            value: false,
            disable: false,
            className: "rs-100 instruccion"
        },
        {
            label: "Has recibido subsidio de vivienda?*",
            name: "subsidio",
            placeholder: "",
            type: "radio",
            typeForm: "radio",
            value: false,
            options: [
                { label: 'SI', value: '1', className: "radioSi", handleRadio },
                { label: 'NO', value: '2', className: "radioNo", handleRadio },
            ],
            disable: false,
            className: "rs-25 ",
            tooltip: "Si ya has recibido subsidio de vivienda a través de caja de compensación, Fonvivienda, Inurbe o Instituto de Crédito Territorial marca Si de lo contrario marca No.",

        },
        {
            tooltip: "Si seleccionaste Si en el campo anterior debes especificar en este campo el nombre de la entidad a través de la cual recibiste el subsidio de vivienda.",
            label: "Entidad de la que recibio el subsidio*",
            name: "entidadSubsidio",
            placeholder: "Nombre de la entidad",
            type: "text",
            typeForm: "text",
            value: false,
            disable: false,
            className: "rs-70"
        }, {
            tooltip: "Seleccionar rural o urbana según el registro de la vivienda a construir o mejorar.  Si es postulación para adquisición de vivienda escoger urbana.",
            label: "Tipo de vivienda*",
            name: "homeType",
            placeholder: "Tipo de vivienda*",
            type: "select",
            typeForm: "select",
            value: false,
            options: [
                // {
                //     value: 'urbano',
                //     label: 'Urbano'
                // },
                // {
                //     value: 'rural',
                //     label: 'Rural'
                // },
            ],
            disable: false,
            className: "rs-25"
        }, {
            tooltip: "Si el valor de  la vivienda a construir, mejorar o comprar con el subsidio no supera los 90 SMMLV escoge la opción VIP (Vivienda de Interés Prioritario). Si es superior a ese valor, escoge VIS (Vivienda de Interés Social).",
            label: "Planes de vivienda*",
            name: "planHomeVivienda",
            placeholder: "Planes de vivienda*",
            type: "select",
            typeForm: "select",
            value: false,
            options: [
                // {
                //     value: 'VIS',
                //     label: 'Vivienda de interes social (VIS)'
                // },
                // {
                //     value: 'VIP',
                //     label: 'Vivienda de interes prioritario (VIP)'
                // },
                // {
                //     value: 'VIPA',
                //     label: 'Vivienda de interes prioritario para ahorro (VIPA)'
                // },
            ],
            disable: false,
            className: "rs-25"
        },
        {
            tooltip: "Selecciona la modalidad que corresponda según el proceso a realizar, sea construcción en sitio propio, mejoramiento o adquisición de vivienda nueva o usada (subsidio para compra de vivienda usada sólo aplica para madres comunitarias certificadas por el ICBF, si esa es tu elección).",
            label: "Modalidad de vivienda*",
            name: "homeModeType",
            placeholder: "Modalidad de vivienda*",
            type: "select",
            typeForm: "select",
            value: false,
            options: [
                // {
                //     value: '1',
                //     label: 'Adquisición de vivienda nueva'
                // },
                // {
                //     value: '2',
                //     label: 'Construcción en sitio propio'
                // },
                // {
                //     value: '3',
                //     label: 'Mejoramiento de vivienda'
                // },
                // {
                //     value: '4',
                //     label: 'Vivienda usada'
                // },

            ],
            disable: false,
            className: "rs-25"
        },
        {
            tooltip: "Selecciona tu tipo de afiliación de acuerdo a como estés registrado en la Caja de Compensación",
            label: "Tipo de afiliado*",
            name: "postulanteType",
            placeholder: "Tipo de afiliado*",
            type: "select",
            typeForm: "select",
            value: false,
            options: [
                {
                    value: '1',
                    label: 'Dependiente'
                },
                {
                    value: '4',
                    label: 'Independiente'
                },
                {
                    value: '3',
                    label: 'Madre comunitaria'
                },
                {
                    value: '2',
                    label: 'Pensionado'
                },


            ],
            disable: false,
            className: "rs-25"
        },
    ]
    return SECCION_INF_BASICA;
}

export const HOME_DATA_FORM = [
    {
        placeholder: "En esta sección encuentras tu información personal como afiliado, verifica que sea correcta, de lo contrario debes actualizar tus datos en nuestra Sucursal Virtual",
        name: "instruccion",
        type: "label",
        typeForm: "label",
        value: false,
        disable: false,
        className: "rs-100 instruccion"
    },
    {
        placeholder: `Para ingresar a la sucursal virtual debes dar clic en el siguiente botón y en donde se encuentra tu nombre debes dar clic en la opción Actualizar perfil. Actualiza tus datos y luego vuelve al formulario, presiona la tecla F5 y tu información personal se actualizará de inmediato.`,
        label: "Actualizar datos",
        name: "instruccion",
        type: "buttonA",
        typeForm: "buttonA",
        value: false,
        options: [
            // "https://pruebas.comfenalcovalle.com.co/delagentewebtest/profile"
            "https://delagentevirtual.com/delagenteweb/profile"
        ],
        disable: false,
        className: "rs-100 instruccion2"
    },
    {
        tooltip: "Verifica que tu primer nombre esté registrado tal como está en tu documento de identidad",
        label: "Primer nombre*",
        name: "firstName",
        placeholder: "Primer nombre*",
        hint: "",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disabled: false,
        className: "rs-30",
        hidden: false
    },
    {
        tooltip: "Verifica que tu segundo nombre (si aplica) esté registrado tal como está en tu documento de identidad",
        label: "Segundo nombre",
        name: "middleName",
        placeholder: "Segundo nombre",
        hint: "",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disabled: false,
        className: "rs-30"
    },
    {
        tooltip: "Verifica que tu(s) apellido(s)  esté(n) registrado(s) tal como está(n) en tu documento de identidad",
        label: "Apellidos*",
        name: "lastName",
        placeholder: "Apellidos*",
        hint: "",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disabled: false,
        className: "rs-30"
    },
    {
        tooltip: "Verifica que tu tipo de documento sea el que corresponde",
        label: "Tipo de documento*",
        name: "documentType",
        placeholder: "Tipo de documento*",
        type: "select",
        typeForm: "select",
        value: false,
        options: [
            {
                value: 'CC',
                label: 'Cédula de ciudadania'
            },
            {
                value: 'CE',
                label: 'Cédula de extranjería'
            },
            {
                value: 'PD',
                label: 'Pasaporte diplomático'
            },
            {
                value: 'PEP',
                label: 'Permiso especial de permanencia'
            },
            {
                value: 'SP',
                label: 'Salvoconducto de permanencia'
            },
        ],
        disabled: false,
        className: "rs-30"
    },
    {
        tooltip: "Verifica tu número de documento de identidad",
        label: "Número de documento*",
        name: "numberId",
        placeholder: "Número de documento*",
        hint: "",
        type: "number",
        typeForm: "text",
        value: false,
        options: [],
        disabled: false,
        className: "rs-30"
    },
    {
        tooltip: "Escoge el lugar de expedición del documento de identidad que corresponda",
        label: "Expedida en*",
        name: "idPlace",
        placeholder: "Municipio de Expedición*",
        hint: "",
        type: "select",
        typeForm: "select",
        value: false,
        options: [],
        disabled: false,
        className: "rs-30"
    },
    {
        tooltip: "Verifica que los datos de dirección de residencia sean los que corresponden",
        label: "Dirección / domicilio actual*",
        name: "currentAddress",
        placeholder: "Dirección / domicilio actual*",
        hint: "",
        type: "text",
        typeForm: "text",
        value: false,
        disabled: false,
        className: "rs-30"
    },
    {
        tooltip: "Verifica que el departamento de residencia sea el que corresponde",
        label: "Departamento*",
        name: "departament",
        placeholder: "Departamento*",
        type: "select",
        typeForm: "select",
        value: false,
        options: [],
        disabled: false,
        className: "rs-30"
    },
    {
        tooltip: "Verifica que el municipio de residencia sea el que corresponde",
        label: "Municipio*",
        name: "city",
        placeholder: "Municipio*",
        type: "select",
        typeForm: "select",
        value: false,
        options: [],
        disabled: false,
        className: "rs-30"
    },
    {
        tooltip: "Verifica que el barrio de residencia sea el que corresponde",
        label: "Barrio*",
        name: "hood",
        placeholder: "Barrio*",
        hint: "",
        type: "text",
        typeForm: "text",
        value: false,
        disabled: false,
        className: "rs-30"
    },
    {
        tooltip: "Verifica que el correo electrónico sea el que corresponde (correo personal o de alguno del grupo familiar y que sea de revisión constante, porque a través de este medio te llegarán las novedades sobre el estado de tu postulación)",
        label: "Correo electrónico*",
        name: "companyEmail",
        placeholder: "Correo electrónico*",
        hint: "",
        type: "text",
        typeForm: "text",
        value: false,
        disabled: false,
        className: "rs-30"
    },
    {
        tooltip: "Diligencia números de teléfono fijos o celulares para contactarte (puedes ingresar hasta 2 números separados por guión)",
        label: "Teléfono / celular*",
        name: "companyPhone",
        placeholder: "Teléfono / celular*",
        hint: true,
        type: "text",
        typeForm: "text",
        value: false,
        disabled: false,
        className: "rs-30"
    },
]

export const INFORMACION_LABORAL = [
    {
        placeholder: "En esta sección diligencias la información de la empresa para la cual trabajas en caso de ser dependiente o registrar tus datos en caso de ser independiente o pensionado.",
        name: "instruccion",
        type: "label",
        typeForm: "label",
        value: false,
        disable: true,
        className: "rs-100 instruccion"
    },
    {
        tooltip: "En caso de ser empleado, verifica que la carta de certificación de ingresos corresponda a la empresa aquí registrada. Si es independiente o pensionado en este campo estará registrado su nombre.",
        label: "Nombre empresa de afiliación*",
        name: "subsidioSelect",
        placeholder: "Nombre empresa de afiliación*",
        type: "select",
        typeForm: "select",
        value: false,
        options: [],
        disable: false,
        className: "rs-40-2"
    },
    {
        name: "companyTable",
        type: "table",
        typeForm: "table",
        value: false,
        disable: false,
        className: "rs-90",
        rows: ["Nit", "Nombre de la empresa", "Dirección", "Teléfono",],
        options: [],
        hidden: false,
    },
    {
        tooltip: "Si es empleado, coloca el nombre de la empresa o sucursal en la que laboras actualmente. Si es independiente coloca tu nombre completo como aparece en documento de identificación.  Si es independiente y presenta certificación de prestación de servicios coloque el nombre de la entidad donde labora.  Si es pensionado coloca nombre del fondo de pensiones",
        label: "Nombre empresa en la que labora*",
        name: "laborandoSelect",
        placeholder: "Nombre empresa en la que labora*",
        type: "select",
        typeForm: "select",
        value: false,
        options: [],
        disable: false,
        className: "rs-40-2"
    },
    {
        name: "companyTableLabor",
        type: "table",
        typeForm: "table",
        value: false,
        disable: false,
        className: "rs-90",
        rows: ["Nombre de la empresa", "Dirección", "Teléfono",],
        options: [],

    },
    {
        tooltip: "Registra en este campo el nombre de la empresa para la cual trabajas",
        label: "Empresa donde esta laborando*",
        name: "companyJobName",
        placeholder: "Ingrese el nombre de la empresa*",
        type: "text",
        typeForm: "text",
        value: false,
        disable: false,
        hidden: true,
        className: "rs-40"
    },
    {
        tooltip: "Registra en este campo la dirección de la empresa donde laboras actualmente",
        label: "Dirección del sitio de trabajo*",
        name: "companyJobAddress",
        placeholder: "Ingrese la dirección*",
        type: "text",
        typeForm: "text",
        value: false,
        disable: false,
        hidden: true,
        className: "rs-30"
    },
    {
        tooltip: "Registra en este campo el teléfono de la empresa",
        label: "Teléfono / Celular*",
        name: "companyPhoneJob",
        placeholder: "Ingrese teléfono*",
        type: "text",
        typeForm: "text",
        value: false,
        disable: false,
        hidden: true,
        className: "rs-30"
    }


]
export const JURAMENTO_PARTES = [

    {

        name: "document",
        placeholder: " ",
        type: "cardJura",
        typeForm: "cardJura",
        value: false,
        disable: false,
        options: JURAMENTO_P5,
        className: "spacerJuramento rs-100"
    },
    {
        label: "Aceptar Juramento*",
        name: "juramentoCheck",
        placeholder: "Aceptar Juramento*",
        type: "checkbox",
        typeForm: "checkbox",
        value: false,
        disable: false,
        className: "AceptarJuramento rs-50"
    },


]
export const JURAMENTO_ACEP = [
    {
        label: JURAMENTO_TITLE,
        name: "juramentoPartes",
        type: "section",
        typeForm: "section",
        className: "rs-100 juramentoPartes",
        fields: JURAMENTO_PARTES
    }
]

export const generarAdjuntos = (handleUpload, handleRemove) => {
    const ARCHIVOS_A_ADJUNTAR = [
        //         {
        //             placeholder: `En esta sección se cargan los documentos anexos para validar la postulación del subsidio familiar de vivienda tales como:
        //             -	Fotocopia de cédula de cada uno de los mayores de edad que estén registrados en la postulación
        // -	Carta(s) laboral(es) y/o certificación(es) de contador público y/o resolución pensional y comprobantes de pago de pensión de los últimos 2 meses de los mayores de edad que generen ingresos.
        // -	Certificaciones de ahorros previos (certificación de fondo de cesantías con valor y fecha de inmovilización de estos recursos y/o certificación de entidad financiera donde tiene el ahorro programado con fecha y monto de inmovilización de este recurso y/o certificación de pago a cuota inicial con fecha de primer abono y monto total pagado)
        // -	Copia de carta de aprobación o pre aprobación de crédito complementario (si aplica)
        // -	Certificación que acredite alguna condición especial si le aplica a algún miembro del hogar (Discapacitado(a): certificado médico de discapacidad, Madre comunitaria ICBF: certificado del ICBF que así lo acredite, Miembro de hogar afrocolombiano: certificado de Ministerio del Interior, Indígena: certificado de cabildo, Reubicado de archipiélago de San Andrés, Providencia y Santa Catalina: Certificado de reubicación, Víctima de atentado terrorista o damnificado de desastre natural o desplazado registrado en red: certificado de entidad correspondiente. Las condiciones Mujer/Hombre Cabeza de Hogar o Mayor de 65 años no requieren ningún soporte)
        // -	En el caso de postulación a subsidio para mejoramiento o construcción en sitio propio deberá anexar certificado de tradición con vigencia de 30 días y recibo de impuesto predial al día en pagos

        // `,
        //             name: "instruccion",
        //             type: "label",
        //             typeForm: "label",
        //             value: false,
        //             disable: false,
        //             className: "rs-100 instruccion"
        //         },
        {
            placeholder: "En esta sección se cargan los documentos anexos para validar la postulación del subsidio familiar de vivienda (dar clic en el icono + ) tales como:",
            options: [
                "-	Fotocopia de cédula de cada uno de los mayores de edad que estén registrados en la postulación",
                "-	Carta(s) laboral(es) y/o certificación(es) de contador público y/o resolución pensional y comprobantes de pago de pensión de los últimos 2 meses de los mayores de edad que generen ingresos.",
                "-	Certificaciones de ahorros previos (certificación de fondo de cesantías con valor y fecha de inmovilización de estos recursos y/o certificación de entidad financiera donde tiene el ahorro programado con fecha y monto de inmovilización de este recurso y/o certificación de pago a cuota inicial con fecha de primer abono y monto total pagado)",
                "-	Copia de carta de aprobación o pre aprobación de crédito complementario (si aplica)",
                "-	Certificación que acredite alguna condición especial si le aplica a algún miembro del hogar (Discapacitado(a): certificado médico de discapacidad, Madre comunitaria ICBF: certificado del ICBF que así lo acredite, Miembro de hogar afrocolombiano: certificado de Ministerio del Interior, Indígena: certificado de cabildo, Reubicado de archipiélago de San Andrés, Providencia y Santa Catalina: Certificado de reubicación, Víctima de atentado terrorista o damnificado de desastre natural o desplazado registrado en red: certificado de entidad correspondiente. Las condiciones Mujer/Hombre Cabeza de Hogar o Mayor de 65 años no requieren ningún soporte)",
                "-	En el caso de postulación a subsidio para mejoramiento o construcción en sitio propio deberá anexar certificado de tradición con vigencia de 30 días y recibo de impuesto predial al día en pagos"
            ],
            name: "instruccion",
            type: "cardText",
            typeForm: "cardText",
            value: false,
            disable: false,
            className: "rs-100 instruccion"
        },
        {
            name: "adjuntoone",
            type: "section",
            typeForm: "section",
            className: "rs-100 juramentoPartes",
            fields: [
                {
                    tooltip: "Adjunta en este campo la fotocopia del documento de identidad de todos los mayores de edad sean cédulas de ciudadanía o cédulas de extranjería",
                    label: "Fotocopia de cédula de todos los mayores de edad renombrando el archivo con las siglas CC y el número de documento (ejemplo: CC 123456789) antes de anexarlo",
                    name: "fotocopyCedula",
                    placeholder: "Subir copia cedula",
                    type: "fileA",
                    typeForm: "fileA",
                    value: false,
                    options: [],
                    disable: false,
                    handleUpload,
                    handleRemove,
                    multiple: false,
                    auto: true,
                    mode: true,
                    maxFileSize: 2000000,
                    className: "rs-50 adjuntar"
                },

                {
                    label: "Copia de certificado de la entidad en que realiza el ahorro programado con monto y fecha de inmovilización. Fecha de expedición no mayor a 30 días. Renombrar el archivo como Ahorro programado antes de anexarlo.-",
                    name: "certificadoAhorro",
                    placeholder: "Subir certificado ahorro",
                    type: "fileA",
                    typeForm: "fileA",
                    value: false,
                    options: [],
                    disable: false,
                    handleUpload,
                    handleRemove,
                    multiple: false,
                    auto: true,
                    mode: true,
                    maxFileSize: 2000000,
                    tooltip: "Adjunta en este campo la fotocopia del certificado de la entidad financiera donde cuenta con el ahorro programado con el monto y fecha de inmovilización, con fecha de expedición no mayor a 30 días",
                    className: "rs-50 adjuntar"
                },
            ]
        },
        {
            name: "adjuntoone",
            type: "section",
            typeForm: "section",
            className: "rs-100 juramentoPartes",
            fields: [
                {
                    label: "Carta laboral original con valor de ingresos mensual con fecha de expedición no mayor a 30 días y renombrar el archivo como Carta Laboral más número de cédula de quien corresponda (ejemplo: Carta Laboral 123456789) antes de anexarlo.",
                    name: "cartaLaboral",
                    placeholder: "Subir carta laboral",
                    type: "fileA",
                    typeForm: "fileA",
                    value: false,
                    options: [],
                    disable: false,
                    handleUpload,
                    handleRemove,
                    multiple: false,
                    auto: true,
                    mode: true,
                    maxFileSize: 2000000,
                    tooltip: "Adjunta en este campo la carta original de certificación laboral con el valor del ingreso mensual con fecha de expedición no mayor a 30 días.",
                    className: "rs-50 adjuntar2"
                },
                {
                    label: "Copia de certificado de cesantías con fecha de inmovilización y monto. Fecha de expedición no mayor a 30 días. Renombrar el archivo como Cesantías antes de anexarlo",
                    name: "certificadoCesantias",
                    placeholder: "Subir certificado cesantias",
                    type: "fileA",
                    typeForm: "fileA",
                    value: false,
                    options: [],
                    disable: false,
                    handleUpload,
                    handleRemove,
                    multiple: false,
                    auto: true,
                    mode: true,
                    maxFileSize: 2000000,
                    tooltip: "Adjunta en este campo el certificado de cesantías con fecha de inmovilización y monto, con fecha de expedición no mayor a 30 días",
                    className: "rs-50 adjuntar2"
                },

            ]
        },
        {
            name: "adjuntotwo",
            type: "section",
            typeForm: "section",
            className: "rs-100 juramentoPartes",
            fields: [
                {
                    label: "Resolución pensional y comprobantes de pago de los últimos dos meses renombrando el archivo como Resolución y comprobantes más cédula de quien corresponda (ejemplo: Resolución y comprobantes 123456789) antes de anexarlo.",
                    name: "resolucionPensional",
                    placeholder: "Subir resolución pensional",
                    type: "fileA",
                    typeForm: "fileA",
                    value: false,
                    options: [],
                    disable: false,
                    handleUpload,
                    handleRemove,
                    multiple: false,
                    auto: true,
                    mode: true,
                    maxFileSize: 2000000,
                    tooltip: "Adjunta en este campo la resolución pensional y comprobantes de pago de los dos meses anteriores a la fecha de postulación al subsidio familiar de vivienda",
                    className: "rs-50 adjuntar"
                },
                {
                    label: "Certificado de la constructora o certificado de fiducia con fecha de primer pago y monto total pagado en caso de haber pagado cuota inicial. Con expedición no mayor a 30 días. Renombrar archivo como Cuota Inicial o Fiducia según aplique antes de anexarlo",
                    name: "certificadoConstructora",
                    placeholder: "Subir certificado constructora",
                    type: "fileA",
                    typeForm: "fileA",
                    value: false,
                    options: [],
                    disable: false,
                    handleUpload,
                    handleRemove,
                    multiple: false,
                    auto: true,
                    mode: true,
                    maxFileSize: 2000000,
                    tooltip: "Adjunta en este campo el certificado de la constructora firmado por el representante legal y revisor fiscal, o certificado de fiducia con la fecha del primer pago y monto en caso de haber pagado cuota inicial, con fecha de expedición no mayor a 30 días. Solo se permite cargue de un archivo",
                    className: "rs-50 adjuntar"
                },
            ]
        },
        {
            name: "adjuntothree",
            type: "section",
            typeForm: "section",
            className: "rs-100 juramentoPartes",
            fields: [
                {
                    label: "Certificado de ingresos mensuales elaborado por contador público o certificación por prestación de servicios si es independiente, según corresponda, renombrado el archivo como Certificado ingresos más número de cédula de quien corresponda (ejemplo: Certificado ingresos 123456789) antes de anexarlo.",
                    name: "certificadoIngresos",
                    placeholder: "Subir certificado de ingresos",
                    type: "fileA",
                    typeForm: "fileA",
                    value: false,
                    options: [],
                    disable: false,
                    handleUpload,
                    handleRemove,
                    multiple: false,
                    auto: true,
                    mode: true,
                    maxFileSize: 2000000,
                    tooltip: "Si eres trabajador independiente, adjunta en este campo el certificado de ingresos mensuales por contador público o certificación por prestación de servicios.  Solo se permite cargue de un archivo",
                    className: "rs-50 adjuntar2"
                },
                {
                    label: "Copia de carta de aprobación o pre aprobación de crédito complementario renombrando el archivo como Carta Crédito antes de anexarlo.",
                    name: "cartaAprobacion",
                    placeholder: "Subir carta aprobación ",
                    type: "fileA",
                    typeForm: "fileA",
                    value: false,
                    options: [],
                    disable: false,
                    handleUpload,
                    handleRemove,
                    multiple: false,
                    auto: true,
                    mode: true,
                    maxFileSize: 2000000,
                    tooltip: "Adjunta en este campo la carta de aprobación o pre aprobación del crédito otorgado por la entidad financiera.  Solo se permite cargue de un archivo",
                    className: "rs-50 adjuntar2"
                },
            ]
        },
        {
            name: "adjuntofor",
            type: "section",
            typeForm: "section",
            className: "rs-100 juramentoPartes",
            fields: [
                {
                    label: "Certificado médico que acredite la discapacidad física o mental si aplica para alguno de los miembros de hogar renombrando el archivo como Certificado médico antes de anexarlo.",
                    name: "certificadoMedico",
                    placeholder: "Subir certificado médico",
                    type: "fileA",
                    typeForm: "fileA",
                    value: false,
                    options: [],
                    disable: false,
                    handleUpload,
                    handleRemove,
                    multiple: false,
                    auto: true,
                    mode: true,
                    maxFileSize: 2000000,
                    tooltip: "En caso de tener un miembro del hogar con discapacidad física o mental, adjunta en este campo el certificado médico que acredite esta condición",
                    className: "rs-50 adjuntar"
                },
                {
                    label: "En el caso de recurso complementario en terreno debe presentar copia del certificado de tradición con fecha de expedición no mayor a 30 días y recibo de impuesto predial al día en pagos. Renombrar el archivo como Certificado y predial antes de anexarlo.",
                    name: "certificadoTradicion",
                    placeholder: "Subir certificado tradición",
                    type: "fileA",
                    typeForm: "fileA",
                    value: false,
                    options: [],
                    disable: false,
                    handleUpload,
                    handleRemove,
                    multiple: false,
                    auto: true,
                    mode: true,
                    maxFileSize: 2000000,
                    tooltip: "Si cuenta con recurso complementario en terreno, adjunta en este campo la fotocopia del certificado de tradición con fecha de expedición no mayor a 30 días",
                    className: "rs-50 adjuntar"
                },
            ]
        },
        {
            name: "adjuntofive",
            type: "section",
            typeForm: "section",
            className: "rs-100 juramentoPartes",
            fields: [
                {
                    label: "Certificado expedido por el ICBF donde se acredite como madre comunitaria (si aplica) renombrando el archivo como Certificado ICBF antes de anexarlo.",
                    name: "certificadoIcbf",
                    placeholder: "Subir certificado ICBF",
                    type: "fileA",
                    typeForm: "fileA",
                    value: false,
                    options: [],
                    disable: false,
                    handleUpload,
                    handleRemove,
                    multiple: false,
                    auto: true,
                    mode: true,
                    maxFileSize: 2000000,
                    tooltip: "Si eres madre comunitaria, adjunta en este campo el certificado del ICBF donde se acredite esta labor.  Solo se permite cargue de un archivo",
                    className: "rs-50 adjuntar2"
                },
                {
                    label: "Certificaciones que acrediten condiciones especiales si le aplican como miembro de hogar afrocolombiano, indígena, reubicado Archipiélago de San Andrés, Providencia y Santa Catalina, Víctima de atentado terrorista, damnificado desastre natural, desplazado registrado en red, hogar objeto de programa de reubicación zona de alto riesgo",
                    name: "otrosanexos",
                    placeholder: "Subir otros anexos",
                    type: "fileA",
                    typeForm: "fileA",
                    value: false,
                    options: [],
                    disable: false,
                    handleUpload,
                    handleRemove,
                    multiple: false,
                    auto: true,
                    mode: true,
                    maxFileSize: 2000000,
                    tooltip: "Si registraste en formulario alguna condición especial de las aquí listadas, favor anexar certificación emitida por entidad correspondientex",
                    className: "rs-50 adjuntar2"
                },
            ]
        },

    ]
    return ARCHIVOS_A_ADJUNTAR;
}

export const TABLE_HEADERS = [
    { label: "Nit", column: "nitEmp" },
    { label: "Nombre empresa ", column: "nameEmp" },
    { label: "Dirección empresa", column: "dirEmp" },
    { label: "Teléfono Empresa", column: "telEmp" },
    { label: " ", column: " " },
];
export const adjuntosAnexosForm = (handleUpload, handleRemove) => {
    const ADJUNTOS_ANEXOS = [
        {
            label: ARCHIVOS_ADJUNTOS_SUB_TITLE,
            name: "archivosAdjuntos",
            type: "section",
            typeForm: "section",
            className: "rs-100 adjuntarArchivos",
            fields: generarAdjuntos(handleUpload, handleRemove)
        }
    ];
    return ADJUNTOS_ANEXOS;
}

export const getStep1Form = () => {
    const INFORMACION_BASICA = [
        {
            label: DATOS_POSTULANTE_TITLE,
            name: "datospostulante",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: HOME_DATA_FORM
        },
        {
            label: INFORMACION_LABORAL_TITLE,
            name: "informacionlaboral",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: INFORMACION_LABORAL
        },
    ]
    return INFORMACION_BASICA;
}

export const getConditionHome = (handleRemoveF, handleUploadF) => {
    const CONDITION_HOME_FORM = [
        {
            placeholder: "En esta sección encuentras o registras información socioeconómica de tu hogar. En caso de que algún dato requiera modificarse, favor realizar la actualización correspondiente a través del área de Registro y Aportes",
            name: "instruccion",
            type: "label",
            typeForm: "label",
            value: false,
            disable: false,
            className: "rs-100 instruccion"
        },
        {
            tooltip: "Verifica que los nombres y apellidos estén escritos como se registró en tu documento de identidad",
            label: "Nombre completo*",
            name: "companyName",
            placeholder: "Nombre del jefe de hogar",
            type: "text",
            typeForm: "text",
            value: false,
            options: [],
            disable: false,
            className: "rs-40"
        }, {
            tooltip: "Verifica que la fecha de nacimiento corresponde a la registrada en tu documento de identidad",
            label: "Fecha de nacimiento*",
            name: "birthdate",
            placeholder: " ",
            type: "date",
            typeForm: "date",
            value: false,
            options: [],
            disable: false,
            className: "rs-20"
        }, {
            tooltip: "Verifica que el tipo de documento de identidad sea el que corresponde",
            label: "Tipo de documento*",
            name: "documentType",
            placeholder: "Tipo de documento*",
            type: "select",
            typeForm: "select",
            value: false,
            options: [],
            disable: false,
            className: "rs-20"
        }, {
            tooltip: "Verifica que el número de documento de identidad sea el que corresponde",
            label: "Número de documento*",
            name: "numDocJefe",
            placeholder: "Ingrese documento*",
            type: "number",
            typeForm: "text",
            value: false,
            options: [],
            disable: false,
            className: "rs-20"
        },
        {
            tooltip: "Verifica que el sexo sea el que corresponde",
            label: "Sexo*",
            name: "sex",
            placeholder: "Sexo*",
            type: "select",
            typeForm: "select",
            value: false,
            options: [],
            disable: false,
            className: "rs-15"
        },
        {
            tooltip: "Verifica que el estado civil sea el que corresponde",
            label: "Estado civil*",
            name: "maritalstatusJefe",
            placeholder: "Estado civil*",
            type: "select",
            typeForm: "select",
            value: false,
            options: [],
            disable: false,
            className: "rs-15"
        },
        {
            tooltip: "Jefe de hogar por defecto de la postulación",
            label: "Parentesco*",
            name: "parentezcoJefe",
            placeholder: "Parentesco*",
            type: "select",
            typeForm: "select",
            value: false,
            options: [],
            disable: false,
            className: "rs-25-2"
        },
        {
            tooltip: "Ocupación del jefe del hogar",
            label: "Ocupación*",
            name: "jobJefeHogar",
            placeholder: "Ocupación*",
            type: "select",
            typeForm: "select",
            value: false,
            options: [],
            disable: false,
            className: "rs-20"
        },
        {
            tooltip: "Si presentas alguna de estas condiciones, regístrala y anexa el correspondiente documento para su verificación (Discapacitado(a): certificado médico de discapacidad, Madre comunitaria ICBF: certificado del ICBF que así lo acredite, Miembro de hogar afrocolombiano: certificado de Ministerio del Interior, Indígena:  certificado de cabildo, Reubicado de archipiélago de San Andrés, Providencia y Santa Catalina: Certificado de reubicación. Las condiciones Mujer/Hombre Cabeza de Hogar o Mayor de 65 años no requieren ningún soporte). Si no presentas ninguna condición especial debes dejar el espacio en blanco.",
            label: "Condición especial",
            name: "special",
            placeholder: "Condición especial",
            type: "select",
            typeForm: "select",
            value: false,
            options: [],
            disable: false,
            className: "rs-25-2"
        },
        {
            tooltip: "Si presentas alguna de estas características, regístrala y anexa el correspondiente documento para su verificación de lo contrario dejar en blanco",
            label: "Tipo postulante",
            name: "tipostulante",
            placeholder: "Tipo postulante",
            type: "select",
            typeForm: "select",
            value: false,
            options: [],
            disable: false,
            className: "rs-30"
        },
        {
            tooltip: "Registra en este campo el valor de los ingresos certificados en un documento a adjuntar (carta laboral, certificado de ingresos por contador, comprobantes de pago de pensión) teniendo en cuenta horas extras, comisiones, auxilios extralegales, etc.",
            label: "Ingresos mensuales*",
            name: "ingresos",
            placeholder: "Ingresos mensuales*",
            type: "number",
            typeForm: "numberN",
            value: false,
            options: [],
            disable: false,
            className: "rs-30",

        },
        {
            tooltip: "Adjuntar firma digital del jefe de hogar, las dimensiones recomendadas son 68px de ancho por 20px de alto, en formato JPG, JEPG, PNG",
            label: "Firma",
            name: "firma2",
            placeholder: "Subir",
            type: "fileF",
            typeForm: "fileF",
            value: false,
            options: [],
            disabled: false,
            handleUploadF,
            handleRemoveF,
            multiple: false,
            auto: true,
            mode: true,
            maxFileSize: 2000000,
            className: "rs-40 firmaClass"
        }
    ]
    return CONDITION_HOME_FORM;
}

export const getConditionHomeP = (handleRemove, handleUpload) => {
    const CONDITION_HOME_FORM = [
        {
            placeholder: "En este campo encontrarás el grupo familiar vinculado a tu afiliación a caja.  Para los que requieras agregar a la postulación deberás oprimir botón AGREGAR INTEGRANTE. Tener en cuenta que si tu estado civil es unión libre o casado(a) deberás incluir a tu compañero(a) o cónyuge. Si en tu grupo familiar hay menores de edad es obligatorio incluirlos en postulación.",
            name: "instruccionInth",
            type: "label",
            typeForm: "label",
            disable: false,
            className: "rs-100 instruccion"
        }, {
            name: "tableIntegrantes",
            type: "table3",
            typeForm: "table3",
            value: false,
            disable: false,
            className: "rs-100",
            rows: ["Nombre", "Tipo documento", "N° documento", " Fecha Nacimiento", "Sexo", "Parentesco"],
            options: [],
            hidden: false,

        },
        {
            label: "El usuario podrá ingresar alguien de su grupo familiar que NO esté afiliado o eliminar a mayores de edad siempre y cuando no sea cónyuge y/o compañero(a) u otro que no se encuentre afiliado.  Es obligatorio vincular en la postulación a todos los menores que conformen el grupo familiar.",
            name: "inthogarpostulantes",
            type: "array",
            typeForm: "array",
            disable: false,
            className: "rs-100 table",
            multiline: false,
            rows: 1,
            fields: getIntegrantes(handleUpload, handleRemove),
            addLabel: "Agregar Integrante",
            removeLabel: "X",
        },

    ]
    return CONDITION_HOME_FORM;
}

export const getIntegrantes = (handleUploadF, handleRemoveF) => {
    const INTEGRANTES =
        [
            {
                tooltip: "Registra primero apellidos luego nombres completos del integrante de grupo familiar tal y como están en el documento de identidad",
                label: "Nombre completo integrante*",
                name: "namePostu",
                placeholder: "Ingrese nombre completo*",
                type: "text",
                typeForm: "text",
                value: false,
                options: [],
                disable: false,
                className: "rs-20"
            },
            {
                tooltip: "Registra la fecha de nacimiento tal como está registrada en su documento de identidad",
                label: "Fecha de nacimiento*",
                name: "birthdatePostu",
                placeholder: " ",
                type: "date",
                typeForm: "date",
                value: false,
                options: [],
                disable: false,
                className: "rs-20"
            },
            {
                tooltip: "Registra el tipo documento de identidad que corresponda",
                label: "Tipo de documento*",
                name: "documentTypePostu",
                placeholder: "Tipo de documento*",
                type: "select",
                typeForm: "select",
                value: false,
                options: [],
                disable: false,
                className: "rs-20"
            },
            {
                tooltip: "Registra el número del documento de identidad",
                label: "Número de documento*",
                name: "idPostu",
                placeholder: "Ingrese documento*",
                type: "number",
                typeForm: "text",
                value: false,
                options: [],
                disable: false,
                className: "rs-20"
            },
            {
                tooltip: "Registra el sexo que corresponda",
                label: "Sexo*",
                name: "sexPostu",
                placeholder: "Seleccione un sexo*",
                type: "select",
                typeForm: "select",
                value: false,
                options: [],
                disable: false,
                className: "rs-20"
            },
            {
                tooltip: "Validar que el estado civil sea el que corresponde",
                label: "Estado civil*",
                name: "maritalstatusPostu",
                placeholder: "Estado civil*",
                type: "select",
                typeForm: "select",
                value: false,
                options: [],
                disable: false,
                className: "rs-20"
            },
            {
                tooltip: "Selecciona el parentesco que el miembro de la familia tenga con el jefe de hogar",
                label: "Parentesco*",
                name: "relativePostu",
                placeholder: "Parentesco",
                type: "select",
                typeForm: "select",
                value: false,
                options: [],
                disable: false,
                className: "rs-20"
            },
            {
                tooltip: "Selecciona la ocupación que corresponda",
                label: "Ocupación*",
                name: "jobPostu",
                placeholder: "Ocupación*",
                type: "select",
                typeForm: "select",
                value: false,
                options: [],
                disable: false,
                className: "rs-20"
            },
            {
                tooltip: "Si este integrante de tu grupo familiar presenta alguna de estas condiciones, regístrala y anexa el correspondiente documento para su verificación (Discapacitado(a): certificado médico de discapacidad, Madre comunitaria ICBF: certificado del ICBF que así lo acredite, Miembro de hogar afrocolombiano: certificado de Ministerio del Interior, Indígena:  certificado de cabildo, Reubicado de archipiélago de San Andrés, Providencia y Santa Catalina: Certificado de reubicación. Las condiciones Mujer/Hombre Cabeza de Hogar o Mayor de 65 años no requieren ningún soporte). Si no presenta ninguna condición especial debes dejar el espacio en blanco.",
                label: "Condición especial",
                name: "specialPostu",
                placeholder: "Condición especial",
                type: "select",
                typeForm: "select",
                value: false,
                options: [],
                disable: false,
                className: "rs-20"
            },
            {
                tooltip: "Si este integrante de tu grupo familiar presenta alguna de estas características, regístrala y anexa el documento soporte emitido por entidad correspondiente según el caso para su verificación, de lo contrario dejar en blanco.",
                label: "Tipo postulante",
                name: "postulantePostu",
                placeholder: "Tipo postulante",
                type: "select",
                typeForm: "select",
                value: false,
                options: [],
                disable: false,
                className: "rs-20"
            },
            {
                tooltip: "Si este integrante del grupo familiar genera ingresos certificados por carta laboral, contador público o comprobantes de pensión, debe registrarse el valor allí soportado, de lo contrario se debe colocar 0 (cero).",
                label: "Ingresos mensuales*",
                name: "ingresosMensual",
                placeholder: "Ingresos mensuales*",
                type: "number",
                typeForm: "numberN",
                value: false,
                options: [],
                disable: false,
                className: "rs-20"
            },
            {
                tooltip: "En caso de que este integrante de tu grupo familiar tenga afiliación a otra caja de compensación diferente a Comfenalco Valle Delagente, registra el nombre de la entidad.",
                label: "Caja de compensanción familiar",
                name: "nombrecajaPostu",
                placeholder: "Nombre Caja de compensanción",
                type: "text",
                typeForm: "text",
                value: false,
                options: [],
                disable: false,
                className: "rs-30"
            }, {
                tooltip: "Adjunte la firma digital para este integrante del hogar, las dimensiones recomendadas son 68px de ancho por 11px de alto, en formato JPG, JEPG, PNG",
                label: "Firma",
                name: "firma3",
                placeholder: "Subir",
                type: "fileF",
                typeForm: "fileF",
                value: false,
                options: [],
                disable: false,
                handleUploadF,
                handleRemoveF,
                multiple: false,
                auto: true,
                mode: true,
                maxFileSize: 2000000,
                className: "rs-30  firmaClass",

            },
        ];
    return INTEGRANTES;
}

export const TOTAL_MONEY_FORM = [
    {
        placeholder: "En esta sección se visualiza información de los ingresos económicos certificados del postulante más los de su grupo familiar (si aplica) contra lo reportado en su afiliación.",
        name: "instruccion",
        type: "label",
        typeForm: "label",
        value: false,
        disable: false,
        className: "rs-100 instruccion"
    },
    {
        tooltip: "Muestra el total de ingresos del jefe de hogar junto a los de su grupo familiar",
        label: "Total ingresos mensuales*",
        name: "totalingresos",
        id: "totalingresos",
        placeholder: "0.00",
        type: "number",
        typeForm: "numberN",
        value: false,
        options: [],
        disable: true,
        className: "rs-40"
    },
    {
        label: " ",
        name: "document",
        placeholder: " ",
        type: "spacer",
        typeForm: "spacer",
        value: false,
        options: [],
        disable: false,
        className: "spacer rs-20"
    },
    {
        tooltip: "Muestra el salario que reporta su empresa en afiliación",
        label: "Salario reportado en afiliación*",
        name: "salarioreportafiliacion",
        placeholder: "0.00",
        type: "number",
        typeForm: "numberN",
        value: false,
        options: [],
        disable: false,
        className: "rs-40"
    },
    {
        label: " ",
        name: "document",
        placeholder: " ",
        type: "spacer",
        typeForm: "spacer",
        value: false,
        options: [],
        disable: false,
        className: "spacer rs-50"
    },

]

export const getConformacionHogar = (handleUpload, handleRemove) => {
    const CONFORMACION_HOGAR = [
        {
            label: "Conformación y condición socio económica del hogar",
            name: "condicionHogar",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: getConditionHome(handleUpload, handleRemove),
        },
        {
            label: "Integrantes del hogar postulante",
            name: "integrantesHogar",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: getConditionHomeP(handleUpload, handleRemove),
        },
        {
            label: "Total ingresos del hogar",
            name: "totalingresos",
            id: "totalingresos",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: TOTAL_MONEY_FORM
        },
    ];

    return CONFORMACION_HOGAR;
}

export const POSTULATION_PROYECTO_FORM = [
    {
        placeholder: "En esta sección se registra la información del proyecto de vivienda para la modalidad de adquisición o compra de vivienda nueva",
        name: "instruccion",
        type: "label",
        typeForm: "label",
        value: false,
        disable: false,
        className: "rs-100 instruccion"
    },
    {
        tooltip: "Diligencia el nombre del proyecto de vivienda donde aplicarás el subsidio.  En caso de ser la postulación para mejoramiento o construcción en sitio propio coloca tu nombre completo.",
        label: "Nombre del proyecto*",
        name: "projectname",
        placeholder: "Ingrese el nombre del proyecto*",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-60"
    },
    {
        tooltip: "Diligencia el nombre de la constructora que desarrolla el proyecto.  En caso de ser la postulación para mejoramiento o construcción en sitio propio coloca Comfenalco Valle",
        label: "Nombre del oferente*",
        name: "ofername",
        placeholder: "Nombre del oferente*",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-40"
    }, {
        label: " ",
        name: "spacer",
        placeholder: "spacer",
        type: "spacer",
        typeForm: "spacer",
        value: false,
        options: [],
        disable: false,
        className: "spacer rs-30"
    },
    {
        tooltip: "Selecciona el departamento donde está ubicado el proyecto de vivienda",
        label: "Departamento*",
        name: "departamentPostu",
        placeholder: "Departamento*",
        type: "select",
        typeForm: "select",
        value: false,
        options: [],
        disable: false,
        className: "rs-30"
    },
    {
        tooltip: "Selecciona el municipio donde está ubicado el proyecto de vivienda",
        label: "Municipio*",
        name: "cityPostu",
        placeholder: "Municipio*",
        type: "select",
        typeForm: "select",
        value: false,
        options: [],
        disable: false,
        className: "rs-40"
    },
]

export const MOVEMENT_HOME = [
    {
        placeholder: "En esta sección se registra la información de la vivienda para las modalidades construcción en sitio propio o mejoramiento de vivienda para la postulación al subsidio",
        name: "instruccion",
        type: "label",
        typeForm: "label",
        value: false,
        disable: false,
        className: "rs-100 instruccion"
    },
    {
        tooltip: "Seleccionar tipo de proyecto",
        label: "Tipo de proyecto*",
        name: "projecttype",
        placeholder: "Tipo de proyecto*",
        type: "select",
        typeForm: "select",
        value: false,
        options: [],
        disable: false,
        className: "rs-30"
    },
    {
        tooltip: "Seleccionar propiedad del lote",
        label: "Propiedad del lote*",
        name: "propiedadlote",
        placeholder: "Propiedad del lote*",
        type: "select",
        typeForm: "select",
        value: false,
        options: [],
        disable: false,
        className: "rs-30"
    },
    {
        tooltip: "Diligencia en este campo el número de la matricula inmobiliaria del predio o propiedad",
        label: "Número matricula inmobiliaria*",
        name: "predioaddress",
        placeholder: "Número matricula inmobiliaria*",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-40"
    },
    {
        tooltip: "Diligencia la dirección de ubicación del lote",
        label: "Dirección del lote*",
        name: "loteaddress",
        placeholder: "Dirección del lote*",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-30"
    },
    {
        tooltip: "Seleccionar departamento",
        label: "Departamento*",
        name: "departamentoModalidad",
        placeholder: "Departamento*",
        type: "select",
        typeForm: "select",
        value: false,
        options: [],
        disable: false,
        className: "rs-30"
    },
    {
        tooltip: "Selecciona el municipio donde se ubica el lote o propiedad",
        label: "Municipio*",
        name: "cityModalidad",
        placeholder: "Municipio*",
        type: "select",
        typeForm: "select",
        value: false,
        options: [],
        disable: false,
        className: "rs-40"
    },
    {
        tooltip: "Diligencia el nombre de la urbanización del lote o propiedad",
        label: "Nombre de la urbanización ",
        name: "urbanizacion",
        placeholder: "Nombre de la urbanización",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-30"
    },
    {
        tooltip: "Diligencia el número o nombre de la manzana si aplica",
        label: "Manzana ",
        name: "apple",
        placeholder: "Manzana",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-30"
    },
    {
        tooltip: "Diligencia el número del lote si aplica",
        label: "No de lote ",
        name: "lotenumber",
        placeholder: "No de lote",
        type: "number",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-40"
    },
    {
        tooltip: "Diligencia el barrio donde se ubica el lote o propiedad",
        label: "Barrio",
        name: "barrioModalidad",
        placeholder: "Barrio",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-30"
    },
    // {
    //     label: "Area del lote m2. ",
    //     name: "areaLoteM2",
    //     placeholder: "Area del lote m2.",
    //     type: "number",
    //     typeForm:"text",
    //     value: false,
    //     options: [],
    //     disable: false,
    //     className: "rs-30"
    // },
    // {
    //     label: "Frente en mts ",
    //     name: "frenteLote",
    //     placeholder: "Frente en mts",
    //     type: "number",
    //     typeForm:"text",
    //     value: false,
    //     options: [],
    //     disable: false,
    //     className: "rs-40"
    // },{
    //     label: "Fondo en mts*",
    //     name: "fondoLote",
    //     placeholder: "Fondo en mts",
    //     type: "number",
    //     typeForm:"text",
    //     value: false,
    //     options: [],
    //     disable: false,
    //     className: "rs-30"
    // },
    {
        tooltip: "Diligencia en este campo el número de la licencia de construcción",
        label: "Licencia de Construcción No.",
        name: "licencia",
        placeholder: "Licencia de Construcción No.",
        type: "number",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-30"
    },
    {
        tooltip: "Diligencia en este campo el fecha de vencimiento de la licencia de construcción",
        label: "Vencimiento Licencia de Construcción ",
        name: "vencimientoLicencia",
        placeholder: " ",
        type: "date",
        typeForm: "date",
        value: false,
        options: [],
        disable: false,
        className: "rs-40"
    },
    {
        tooltip: "Diligencia en este campo el número de la escritura de compraventa",
        label: "Escritura de compraventa No. ",
        name: "escritura",
        placeholder: "Escritura de compraventa No. ",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-30"
    },
    {
        tooltip: "Diligencia en este campo la fecha de la escritura",
        label: "Fecha de escritura ",
        name: "escriturafecha",
        placeholder: " ",
        type: "date",
        typeForm: "date",
        value: false,
        options: [],
        disable: false,
        className: "rs-30"
    },
    {
        tooltip: "Diligencia en este campo el número de la notaria",
        label: "Notaria",
        name: "notariaEscritura",
        placeholder: "Notaria ",
        type: "text",
        typeForm: "text",
        value: false,
        options: [],
        disable: false,
        className: "rs-40"
    },
    {
        tooltip: "Diligencia en este campo la fecha de registro de la escritura",
        label: "Fecha registro escritura ",
        name: "registertestdate",
        placeholder: " ",
        type: "date",
        typeForm: "date",
        value: false,
        options: [],
        disable: false,
        className: "rs-30"
    },
]

export const VALOR_SOLUCION_FORM = [
    {
        placeholder: "En esta sección se registra la información de la vivienda para las modalidades construcción en sitio propio o mejoramiento de vivienda para la postulación al subsidio",
        name: "instruccion",
        type: "label",
        typeForm: "label",
        value: false,
        disable: false,
        className: "rs-100 instruccion"
    },
    {
        tooltip: "Diligencia en este campo el valor del presupuesto de la obra a desarrollar",
        label: "Valor presupuesto de obra*",
        name: "presupuesto",
        placeholder: "Valor presupuesto de obra*",
        type: "number",
        typeForm: "numberN",
        value: false,
        options: [],
        disable: false,
        className: "rs-30"
    },
    {
        tooltip: "Diligenciar el valor de avaluo catastral del lote",
        label: "Valor lote (Avalúo Catastral)*",
        name: "avaluocatastral",
        placeholder: "Valor avalúo Catastral*",
        type: "number",
        typeForm: "numberN",
        value: false,
        options: [],
        disable: false,
        className: "rs-30"
    },
    {
        label: "Valor total*",
        name: "valortotal",
        placeholder: "0.00",
        type: "number",
        typeForm: "numberN",
        value: false,
        options: [],
        disabled: true,
        className: "rs-30"
    },
];

export const getStep3Form = (handleRadio) => {
    return [
        {
            label: "Información de la postulación",
            name: "informacionpostulacion",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: POSTULATION_PROYECTO_FORM
        },
        {
            label: "Modalidad de vivienda (para Construcción en Sitio Propio y Mejoramiento de Vivienda)",
            name: "modalidadvivienda",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            disable: true,
            hidden: false,
            fields: MOVEMENT_HOME
        }, {
            label: "Valor de la solución para construcción en sitio propio o mejoramiento",
            name: "valorconstruccion",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: VALOR_SOLUCION_FORM,
        },
    ];
}

export const getStep3FormV2 = (handleRadio) => {
    return [
        {
            label: "Información de la postulación",
            name: "informacionpostulacion",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: POSTULATION_PROYECTO_FORM
        }
    ];
}
export const getAhorroPrevio = (handleAdd) => {
    const AHORRO_PREVIO = [
        {
            placeholder: "En esta sección se registran los valores de los recursos de ahorro previo para la postulación al subsidio familiar de vivienda. Cada vez que registre los datos de algún tipo de ahorro deberá dar clic en Añadir Recurso para que se cargue en su postulación",
            name: "instruccionInth",
            type: "label",
            typeForm: "label",
            disable: false,
            className: "rs-100 instruccion"
        },
        {
            tooltip: "Selecciona la modalidad de ahorro previo que presentas para postulación",
            label: "Tipo de recurso*",
            name: "tiporecursoA",
            placeholder: "Tipo de recurso*",
            type: "select",
            typeForm: "select",
            value: false,
            options: [],
            disable: false,
            className: "rs-25"
        },

        {
            tooltip: "Selecciona entidad que certifica el ahorro previo.  Si es pago a fiducia busca la entidad que corresponda y si es pago a cuenta de oferente escoge la opción CONSTRUCTORA",
            label: "Entidad*",
            name: "entityA",
            placeholder: "Entidad donde realiza el ahorro*",
            type: "autocomplete",
            typeForm: "autocomplete",
            value: false,
            options: [],
            disable: false,
            className: "rs-25"
        },
        {
            tooltip: "Registrar fecha de incio del ahorro previo (fecha de inmovilización de cesantías o ahorro programado o fecha de primer pago a cuota inicial según aplique).",
            label: "Fecha de ahorro*",
            name: "openDateA",
            placeholder: " ",
            type: "date",
            typeForm: "date",
            value: false,
            options: [],
            disable: false,
            className: "rs-25"
        },
        {
            tooltip: "Diligencia en este campo el valor de ahorro previo que tienes disponible y que certifiques por medio de un documento anexo",
            label: "Valor ahorro*",
            name: "safeValueA",
            placeholder: "Valor ahorro*",
            type: "number",
            typeForm: "numberN",
            value: false,
            options: [],
            disable: false,
            className: "rs-25",
        },
        {
            tooltip: "Solo si seleccionó otro recurso, en el campo 'Tipo de recurso'",
            label: "Otro recurso*",
            name: "otrorecursoA",
            placeholder: "Ingrese otro recurso*",
            type: "text",
            typeForm: "text",
            value: false,
            options: [],
            hidden: false,
            disabled: true,
            className: "rs-50",

        },
        {
            name: "addRecurso",
            placeholder: "Añadir Recurso*",
            type: "buttonX",
            typeForm: "buttonX",
            primary: true,
            value: false,
            options: [],
            hidden: false,
            disabled: false,
            className: "rs-20",
            onClick: handleAdd,
        },
        {
            name: "tableAhorro",
            type: "table2",
            typeForm: "table2",
            value: false,
            disable: false,
            className: "rs-100",
            rows: ["Tipo Recurso", "Entidad de Ahorro", "Fecha de ahorro", " Valor ahorro"],
            options: [],
            hidden: false,

        }

    ]

    return AHORRO_PREVIO;
}
export const getAhorroPrevio2 = (handleAdd) => {
    const AHORRO_PREVIO = [
        {
            placeholder: "En esta sección se registran los valores de los recursos de ahorro previo para la postulación al subsidio familiar de vivienda. Cada vez que registre los datos de algún tipo de ahorro deberá dar clic en Añadir Recurso para que se cargue en su postulación",
            name: "instruccionInth",
            type: "label",
            typeForm: "label",
            disable: false,
            className: "rs-100 instruccion"
        },
        {
            tooltip: "Selecciona la modalidad de ahorro previo que presentas para postulación",
            label: "Tipo de recurso*",
            name: "tiporecursoA",
            placeholder: "Tipo de recurso*",
            type: "select",
            typeForm: "select",
            value: false,
            options: [],
            disable: false,
            className: "rs-25"
        },

        {
            tooltip: "Selecciona entidad que certifica el ahorro previo.  Si es pago a fiducia busca la entidad que corresponda y si es pago a cuenta de oferente escoge la opción CONSTRUCTORA",
            label: "Entidad*",
            name: "entityA",
            placeholder: "Entidad donde realiza el ahorro*",
            type: "select",
            typeForm: "select",
            value: false,
            options: [],
            disable: false,
            className: "rs-25"
        },
        {
            tooltip: "Registrar fecha de incio del ahorro previo (fecha de inmovilización de cesantías o ahorro programado o fecha de primer pago a cuota inicial según aplique",
            label: "Fecha de ahorro*",
            name: "openDateA",
            placeholder: " ",
            type: "date",
            typeForm: "date",
            value: false,
            options: [],
            disable: false,
            className: "rs-25"
        },
        {
            tooltip: "Diligencia en este campo el valor de ahorro previo que tienes disponible y que certifiques por medio de un documento anexo",
            label: "Valor ahorro*",
            name: "safeValueA",
            placeholder: "Valor ahorro*",
            type: "number",
            typeForm: "numberN",
            value: false,
            options: [],
            disable: false,
            className: "rs-25",
        },
        {
            tooltip: "Solo si seleccionó otro recurso, en el campo 'Tipo de recurso'",
            label: "Otro recurso*",
            name: "otrorecursoA",
            placeholder: "Ingrese otro recurso*",
            type: "text",
            typeForm: "text",
            value: false,
            options: [],
            hidden: false,
            disabled: true,
            className: "rs-50",

        },
        {
            name: "addRecurso",
            placeholder: "Añadir Recurso*",
            type: "buttonX",
            typeForm: "buttonX",
            primary: true,
            value: false,
            options: [],
            hidden: false,
            disabled: false,
            className: "rs-20",
            onClick: handleAdd,
        },
        {
            name: "tableAhorro",
            type: "table2",
            typeForm: "table2",
            value: false,
            disable: false,
            className: "rs-100",
            rows: ["Tipo Recurso", "Entidad de Ahorro", "Fecha de ahorro", " Valor ahorro"],
            options: [],
            hidden: false,

        }

    ]

    return AHORRO_PREVIO;
}

export const getRecursosComplementarios = (handleAdd) => {
    const RECURSOS_COMPLEMENTARIOS = [
        {
            placeholder: "En esta sección se registran los valores de los recursos complementarios para la postulación al subsidio familiar de vivienda. Cada vez que registre los datos de algún tipo de recurso complementario deberá dar clic en Añadir Recurso para que se cargue en su postulación",
            name: "instruccionInth",
            type: "label",
            typeForm: "label",
            disable: false,
            className: "rs-100 instruccion"
        },
        {
            tooltip: "Selecciona la modalidad de recurso complementario que presentas para postulación",
            label: "Tipo de recurso*",
            name: "tiporecursoR",
            placeholder: "Tipo de recurso*",
            type: "select",
            typeForm: "select",
            value: false,
            options: [

            ],
            disable: false,
            className: "rs-25"
        },
        {
            tooltip: "Seleccionar entidad que certifica los recursos complementarios",
            label: "Entidad recurso",
            name: "entityR",
            placeholder: "Entidad donde realiza el ahorro*",
            type: "autocomplete",
            typeForm: "autocomplete",
            value: false,
            options: [],
            disable: false,
            className: "rs-25"
        },
        {
            tooltip: "Fecha certificada de los recursos complementarios",
            label: "Fecha recursos complementarios*",
            name: "openDateR",
            placeholder: " ",
            type: "date",
            typeForm: "date",
            value: false,
            options: [],
            disable: false,
            className: "rs-25"
        },
        {
            tooltip: "Diligencia en este campo el valor de recursos complementarios que tienes disponible y que certifiques por medio de un documento anexo",
            label: "Valor recurso complementario*",
            name: "safeValueR",
            placeholder: "Valor recurso ",
            type: "number",
            typeForm: "numberN",
            value: false,
            options: [],
            disable: false,
            className: "rs-25",
        },

        {
            tooltip: "Solo si seleccionó otro recurso, en el campo 'Tipo de recurso'",
            label: "Otro recurso*",
            name: "otrorecursoR",
            placeholder: "Ingrese otro recurso*",
            type: "text",
            typeForm: "text",
            value: false,
            options: [],
            hidden: false,
            disabled: true,
            className: "rs-40",

        },
        {
            name: "addRecurso",
            placeholder: "Añadir Recurso*",
            type: "buttonX",
            typeForm: "buttonX",
            primary: true,
            value: false,
            options: [],
            hidden: false,
            disabled: false,
            className: "rs-20 buttonAdd",
            onClick: handleAdd,
        },
        {
            name: "tableRecurso",
            type: "table2",
            typeForm: "table2",
            value: false,
            disable: false,
            className: "rs-100",
            rows: ["Tipo Recurso", "Entidad de ahorro", "Fecha de adquisición", " Valor ahorro"],
            options: [],
            hidden: false,

        }

    ]

    return RECURSOS_COMPLEMENTARIOS;
}

export const getRecursosComplementarios2 = (handleAdd) => {
    const RECURSOS_COMPLEMENTARIOS = [
        {
            placeholder: "En esta sección se registran los valores de los recursos complementarios para la postulación al subsidio familiar de vivienda. Cada vez que registre los datos de algún tipo de recurso complementario deberá dar clic en Añadir Recurso para que se cargue en su postulación",
            name: "instruccionInth",
            type: "label",
            typeForm: "label",
            disable: false,
            className: "rs-100 instruccion"
        },
        {
            tooltip: "Selecciona la modalidad de recurso complementario que presentas para postulación",
            label: "Tipo de recurso*",
            name: "tiporecursoR",
            placeholder: "Tipo de recurso*",
            type: "select",
            typeForm: "select",
            value: false,
            options: [

            ],
            disable: false,
            className: "rs-25"
        },
        {
            tooltip: "Seleccionar entidad que certifica los recursos complementarios",
            label: "Entidad recurso ",
            name: "entityR",
            placeholder: "Entidad donde realiza el ahorro*",
            type: "select",
            typeForm: "select",
            value: false,
            options: [],
            disable: false,
            className: "rs-25"
        },
        {
            tooltip: "Fecha certificada de los recursos complementarios",
            label: "Fecha recursos complementarios*",
            name: "openDateR",
            placeholder: " ",
            type: "date",
            typeForm: "date",
            value: false,
            options: [],
            disable: false,
            className: "rs-25"
        },
        {
            tooltip: "Diligencia en este campo el valor de recursos complementarios que tienes disponible y que certifiques por medio de un documento anexo",
            label: "Valor recurso complementario*",
            name: "safeValueR",
            placeholder: "Valor recurso",
            type: "number",
            typeForm: "numberN",
            value: false,
            options: [],
            disable: false,
            className: "rs-25",
        },

        {
            tooltip: "Solo si seleccionó otro recurso, en el campo 'Tipo de recurso'",
            label: "Otro recurso*",
            name: "otrorecursoR",
            placeholder: "Ingrese otro recurso*",
            type: "text",
            typeForm: "text",
            value: false,
            options: [],
            hidden: false,
            disabled: true,
            className: "rs-40",

        },
        {
            name: "addRecurso",
            placeholder: "Añadir Recurso*",
            type: "buttonX",
            typeForm: "buttonX",
            primary: true,
            value: false,
            options: [],
            hidden: false,
            disabled: false,
            className: "rs-20 buttonAdd",
            onClick: handleAdd,
        },
        {
            name: "tableRecurso",
            type: "table2",
            typeForm: "table2",
            value: false,
            disable: false,
            className: "rs-100",
            rows: ["Tipo Recurso", "Entidad de ahorro", "Fecha de adquisición", " Valor ahorro"],
            options: [],
            hidden: false,

        }

    ]

    return RECURSOS_COMPLEMENTARIOS;
}

const TOTAL_RECURSOS_COMPLMENTARIOS = [
    {
        label: " ",
        name: "spacer",
        placeholder: "spacer",
        type: "spacer",
        typeForm: "spacer",
        value: false,
        options: [],
        disable: false,
        className: "spacer rs-25"
    },
    {
        label: " ",
        name: "spacer",
        placeholder: "spacer",
        type: "spacer",
        typeForm: "spacer",
        value: false,
        options: [],
        disable: false,
        className: "spacer rs-25"
    },
    {
        label: " ",
        name: "spacer",
        placeholder: "spacer",
        type: "spacer",
        typeForm: "spacer",
        value: false,
        options: [],
        disable: false,
        className: "spacer rs-25"
    },
    {
        label: "Total*",
        name: "totalComple",
        placeholder: "0.00",
        type: "number",
        typeForm: "numberN",
        value: false,
        options: [],
        disable: true,
        className: "rs-25"
    },

]

const TOTAL_AHORRO_PREVIO = [
    {
        label: " ",
        name: "spacer",
        placeholder: "spacer",
        type: "spacer",
        typeForm: "spacer",
        value: false,
        options: [],
        disable: false,
        className: "spacer rs-25"
    },
    {
        label: " ",
        name: "spacer",
        placeholder: "spacer",
        type: "spacer",
        typeForm: "spacer",
        value: false,
        options: [],
        disable: false,
        className: "spacer rs-25"
    },
    {
        label: " ",
        name: "spacer",
        placeholder: "spacer",
        type: "spacer",
        typeForm: "spacer",
        value: false,
        options: [],
        disable: false,
        className: "spacer rs-25"
    },
    {
        label: "Total*",
        name: "totalAhorro",
        placeholder: "0.00",
        type: "number",
        typeForm: "numberN",
        value: false,
        options: [],
        disable: true,
        className: "rs-25"
    },

]

const FINANCIACION_VIVIENTA_FORM = [
    // {
    //     placeholder: "En esta sección se registran los valores de los recursos para la postulación al subsidio familiar de vivienda, para ver tabla de valores a tener en cuenta para la postulación presionar en ver tabla",
    //     label: "Ver tabla",
    //     name: "instruccionInth",
    //     type: "buttonA",
    //     typeForm: "buttonA",
    //     disable: false,
    //     options: [
    //         //"https://storage.googleapis.com/comfenalco-valle-firebase-lab.appspot.com/tablaData.jpg"
    //         //"https://storage.googleapis.com/comfenalco-forms.appspot.com/tablaData.jpg"
    //         "https://storage.googleapis.com/comfenalco-valle-firebase-prod.appspot.com/tablaData.jpg"
    //     ],
    //     className: "rs-100 instruccion2"
    // },
    {
        placeholder: "En esta sección se registran los valores de los recursos para la postulación al subsidio familiar de vivienda",
        name: "instruccion",
        type: "label",
        typeForm: "label",
        value: false,
        disable: false,
        className: "rs-100 instruccion"
    },
    {
        tooltip: "En este campo están registrados los recursos de ahorro previo para la adquisición de tu vivienda",
        label: "Total ahorro previo*",
        name: "totalahorroprevio",
        placeholder: "0.00",
        type: "number",
        typeForm: "numberN",
        value: false,
        options: [],
        disable: true,
        className: "rs-25"
    },
    {
        tooltip: "En este campo están registrados los recursos complementarios para la adquisición de tu vivienda",
        label: "Total recursos complementarios*",
        name: "totalrecursos",
        placeholder: "0.00",
        type: "number",
        typeForm: "numberN",
        value: false,
        options: [],
        disable: true,
        className: "rs-25"
    },
    {
        tooltip: "Modalidad e ingresos totales del grupo familiar",
        label: "Valor subsidio de vivienda*",
        name: "valorsubcidiado",
        placeholder: "Valor subsidio de vivienda*",
        type: "number",
        typeForm: "numberN",
        value: false,
        options: [],
        disable: true,
        className: "rs-25"
    },

    {
        tooltip: "Diligencia en este campo el valor de la vivienda a adquirir con subsidio teniendo en cuenta que no debe superar el valor de la sumatoria de recursos para compra (ahorro previo, recursos complementarios y subsidio)",
        label: "Cierre financiero*",
        name: "valorvivienda",
        placeholder: "Cierre financiero*",
        type: "number",
        typeForm: "numberN",
        value: false,
        options: [],
        disable: false,
        className: "rs-25"
    },
]

const CUENTAS_CREDITO_FORM = [
    {
        options: [
            "De acuerdo al tipo de recursos complementarios presentados en postulación y que registró en datos anteriores, por favor escoja la fuente de financiamiento: ",
            "-	Caja de Compensación Familiar: si tiene crédito aprobado por alguna caja de compensación ",
            "-	Fondo Nacional del Ahorro: si tiene crédito vigente con esta entidad ",
            "-	Fondos de empleados: si tiene crédito vigente por alguna de este tipo de entidades",
            "-	Recursos propios:  Cuando no requiere crédito hipotecario, porque tiene el valor restante en ahorros previos",
            "-	Sistema Financiero: Cuando tiene crédito hipotecario con alguna entidad financiera ",
            "-	No Aplica: cuando es registro para vivienda gratuita",
            "-	Otras Fuentes: Cuando los recursos complementarios son certificados por entidades diferentes a las mencionadas anteriormente ",

        ],
        name: "instruccionInth",
        type: "label2",
        typeForm: "label2",
        disable: false,
        className: "rs-100 cuentasIns instruccion"
    },
    {
        tooltip: "De acuerdo al tipo de recursos complementarios presentados en postulación por favor selecciona  la que se ajuste.",
        label: "Fuentes de financiamiento*",
        name: "fuentesFinanciamiento",
        placeholder: "Fuentes de financiamiento*",
        type: "select",
        typeForm: "select",
        value: false,
        options: [],
        disable: false,
        hidden: false,
        className: "rs-50",
    },
    {
        tooltip: "Selecciona entidad que certifica el recurso complementario, en caso de haber escogido como fuente de financiamiento recursos propios escoja la opción.",
        label: "Entidad que certifica el recurso*",
        name: "entityFuente",
        placeholder: "Seleccione la entidad*",
        type: "select",
        typeForm: "select",
        value: false,
        options: [],
        disable: false,
        className: "rs-25"
    },
    {
        tooltip: "En caso de ser certificado por alguna entidad registrar fecha correspondiente.  si es con recursos propios no registrar ninguna fecha.",
        label: "Fecha de aprobación de recurso",
        name: "openDateFuente",
        placeholder: " ",
        type: "date",
        typeForm: "date",
        value: false,
        options: [],
        disable: false,
        className: "rs-25"
    },
]

export const getRecursosEconomicos = (handleAdd, handleRadio) => {
    const RECURSOS_ECONOMICOS = [

        {
            label: "Ahorro previo",
            name: "ahorroprevio",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: getAhorroPrevio(handleAdd)
        },
        {
            label: "",
            name: "totalahorroprevio",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: TOTAL_AHORRO_PREVIO
        },

        {
            label: "Recursos Complementarios",
            name: "recursoscomplementarios",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: getRecursosComplementarios(handleAdd)
        },
        {
            label: "",
            name: "totalrecursoscomplementarios",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: TOTAL_RECURSOS_COMPLMENTARIOS
        },
        {
            label: "",
            name: "cuentasCredito",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: CUENTAS_CREDITO_FORM
        },
        {
            label: COMPANY_FORM_TITLE,
            name: "informacionbasica",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: getInfoBasica(handleRadio)
        },
        {
            label: "Financiación total para adquisición de la vivienda",
            name: "financiaciontotal",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: FINANCIACION_VIVIENTA_FORM
        },



    ];
    return RECURSOS_ECONOMICOS;
}

export const getRecursosEconomicos2 = (handleAdd, handleRadio) => {
    const RECURSOS_ECONOMICOS = [

        {
            label: "Ahorro previo",
            name: "ahorroprevio",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: getAhorroPrevio2(handleAdd)
        },
        {
            label: "",
            name: "totalahorroprevio",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: TOTAL_AHORRO_PREVIO
        },

        {
            label: "Recursos Complementarios",
            name: "recursoscomplementarios",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: getRecursosComplementarios2(handleAdd)
        },
        {
            label: "",
            name: "totalrecursoscomplementarios",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: TOTAL_RECURSOS_COMPLMENTARIOS
        },
        {
            label: "Cuentas credito",
            name: "cuentasCredito",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: CUENTAS_CREDITO_FORM
        },
        {
            label: COMPANY_FORM_TITLE,
            name: "informacionbasica",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: getInfoBasica(handleRadio)
        },
        {
            label: "Financiación total para adquisición de la vivienda",
            name: "financiaciontotal",
            type: "section",
            typeForm: "section",
            className: "rs-100",
            fields: FINANCIACION_VIVIENTA_FORM
        },



    ];
    return RECURSOS_ECONOMICOS;
}

export const STEPS_CONTENT = ['Juramento   ', 'Información básica', 'Conformación del hogar', 'Postulación', 'Recursos económicos', 'Archivos adjuntos',];

export const getInitButtons = (handleNext) => {
    return [

        {
            label: "Siguiente",
            className: "auth-btn-send",
            action: false,
            primary: true,
            style: "primary",
            onClick: handleNext,

        }
    ]
}

export const getSubmitButtonss = (handleratras, handleNext) => {
    return [
        {
            label: "Atras",
            className: "auth-btn-cancel",
            action: false,
            primary: false,
            style: "secondary",
            onClick: handleratras

        },
        {
            label: "Siguiente",
            className: "auth-btn-send",
            action: false,
            primary: true,
            style: "primary",
            onClick: handleNext,

        }
    ]
}

export const getAddResourse = (handleClean, handleAdd) => {
    return [
        {
            label: "Limpiar",
            className: "auth-btn-cancel",
            action: false,
            primary: false,
            style: "secondary",
            onClick: handleClean

        },
        {
            label: "Agregar",
            className: "auth-btn-send",
            action: false,
            primary: true,
            style: "primary",
            onClick: handleAdd,

        }
    ]
}

export const getModalButtons = (handlerBack, handleFinal) => {
    return [
        {
            label: "Volver",
            className: "auth-btn-cancel",
            action: false,
            style: "secondary",
            onClick: handlerBack

        },
        {
            label: "Finalizar",
            className: "auth-btn-send",
            action: false,
            primary: true,
            style: "primary",
        }
    ]
}

export const getSubmitButtons = (handlerBack, handleBorrador, handleFinal) => {
    return [
        {
            label: "Volver",
            className: "auth-btn-cancel",
            action: false,
            style: "secondary",
            onClick: handlerBack

        }, {
            label: "Guardar Postulación",
            className: "auth-btn-cancel",
            action: false,
            style: "primary",
            onClick: handleBorrador
        },
        {
            label: "Finalizar",
            className: "auth-btn-send",
            action: false,
            primary: true,
            style: "primary",
            onClick: handleFinal
        }
    ]
}