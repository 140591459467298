import shadows from "@material-ui/core/styles/shadows";
import { firebaseDatabase } from "./firebase";
import axios from "axios";
import { takeLatest, put, call, select, actionChannel } from "redux-saga/effects";

export const generateUUID = () => {
    var u = '', i = 0;
    var today = new Date();
    while (i++ < 36) {
        var c = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'[i - 1], r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        u += (c == '-' || c == '4') ? c : v.toString(16)
    }
    return u + "-" + today.getTime().toString();
}

export const getCatalog = async (id) => {
    const catalogRespose = firebaseDatabase.collection(`catalogos/${id}/data`);
    return catalogRespose;
};

export const onlyUnique = (value, index, self) => {
    return self.indexOf(value) === index;
}

export const exist = (array, value) => {
    var ban = false;
    array.forEach(element => {
        if (element.value === value) {
            ban = true;
            return;
        }
    });
    return ban;
}

export const sleep = (milliseconds) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

export const getAsyncCatalog = async (type) => {
    const catalogo = [];
    const catalogRespose = await firebaseDatabase.collection(`catalogos/${type}/data`);
    const collection = await catalogRespose.get();

    collection.docs.map(doc => {
        catalogo.push(doc.data());
    });

    return catalogo;
}

export const getAsyncCollection = async (type) => {
    var collection = false;
    const catalogRespose = await firebaseDatabase.collection(`catalogos`).doc(`${type}`).get();
    collection = catalogRespose.data();
    return collection;
}

export const emptyObject = (value) => {
    return typeof value !== 'object' && value !== undefined ? value : '';
}

export const validOportunidad = (value, items) => {
    let valid = false;
    if (value.consultaOportunidadResponse && value.consultaOportunidadResponse.Opportunity && value.consultaOportunidadResponse.Opportunity.LifeCycleStatusCode === "5") {
        const oportunidad = value.consultaOportunidadResponse.Opportunity;
        const codPlan = items.homeType === "urbano" ? "Z02" : "Z03";
        if (oportunidad.SalesCycle.SalesCycleCode !== codPlan) {
            valid = true;
        }
    }
    return valid;
}

export const emptyJson = (value) => {
    return JSON.stringify(value) === '{}' ? true : false;
}

export const emptyObjectEmp = (value, replace) => {
    return typeof value !== 'object' && value !== undefined ? value : replace;
}

export const emptyCero = (value) => {
    return value !== '' && value !== undefined && value !== null ? value : '0'
}

export const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

export const removeAccents = (str) => {
    const acentos = { 'á': 'a', 'é': 'e', 'í': 'i', 'ó': 'o', 'ú': 'u', 'Á': 'A', 'É': 'E', 'Í': 'I', 'Ó': 'O', 'Ú': 'U', '´': ' ', };
    console.log(str.split(''))
    return str.split('').map(letra => acentos[letra] || letra).join('').toString();
}

export const cortarMSM = (mens, limite) => {
    try {
        let newMsm = mens;
        if (mens && mens.length > limite) {
            newMsm = mens.substr(0, limite - 1)
        }
        return newMsm;
    } catch (error) {
        throw `cortarMSM - ${error}`

    }
}

export const letras_numbers = (str) => {
    //verifica si una caden esta compuesta solo por letras numeros y espacios
    var patt = new RegExp(/^[A-Za-z0-9\s]+$/g);
    return patt.test(str);
}

export const enviarEmail = (action) => {
    try {
        const { from, to, subject, message } = action
        const url = process.env.REACT_APP_URL_SEND_EMAIL_COMPANY;


        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            'Access-Control-Allow-Credentials': 'true',
            "Access-Control-Request-Headers": "access-control-allow-credentials,access-control-allow-headers,access-control-allow-methods,access-control-allow-origin,auth,authorization,cache-control,content-type,mode",
            'accept': "application/json, text/plain, */*",
            "Authorization": 'Basic Rk9STVVMQVJJT1NXRUI6WWsyMTkwX3c=',
            "content-type": "application/json;charset=iso-8859-1",
            "Cache-Control": "no-cache",
            'mode': "opaque ",
        };
        let body = {
            from,
            to,
            subject,
            message,
        }

        console.log(body, url)

        axios.create({
            headers,
        }).post(url, body)
            .then(response => {
                console.log(response.data)
            })
            .catch(err => {
                console.error('error invocando enviar email:' + err + " " + err.message)
                throw err;
            })

    } catch (error) {
        console.error("Function.enviarEmail", error);
    }
}

export const encode = (data) => {
    return Buffer.from(data, 'utf-8').toString('base64')
}


export const decode = (data) => {
    return Buffer.from(data, 'base64').toString('utf-8')
}

export const notificaFalloWBS = (action) => {
    try {
        const { body, webService, response } = action

        const from = "Notificaciones bandeja comercial";
        const to = `<jamu@comfenalcovalle.com.co>`;
        const subject = "Notificación de fallo web service formularios Comfenalco Valle Delagente";
        const message = `<div><p>El web service <b>${webService}</b> presento una respuesta inesperada, a lo cual se requiere tomar control de la acción esperada</p><p>Cuerpo del webservice</p><p>${JSON.stringify(body)}</p><p>Respuesta del webservice</p><p>${JSON.stringify(response)}</p> </div>`
        const res = enviarEmail({ from, to, subject, message })

    } catch (error) {
        throw `saga.js - notificaFalloWBS ${error}`;
    }
}

export const cuantosSalarios = (salario, monto) => {
    return `${(parseInt(monto) / parseInt(salario)).toFixed(2)}`;
}

export function* consumeServiceGenerico(action) {
    try {
        const { body, url } = action;

        const request = { urlServicio: url, body: body }
        const headers = {
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
            'Access-Control-Allow-Credentials': 'true',
            "Access-Control-Request-Headers": "*",
            'accept': "application/json, text/plain, */*",
            "Authorization": 'Basic Rk9STVVMQVJJT1NXRUI6WWsyMTkwX3c=',
            "content-type": "application/json;charset=iso-8859-1",
            "Cache-Control": "no-cache",
            'mode': "opaque ",
        };

        const respues = yield call(axios.post, process.env.REACT_APP_FIREBASE_SEND_CONSUME_SERVICIOS_GENERICOS, request, headers);

        return respues;
    } catch (error) {
        console.error("Function.consumeServiceGenerico", error);
        throw `Function.consumeServiceGenerico - saga.js -${error}`;
    }
}

export function idDeparta(catalogo, id) {
    var idDepa = id
    catalogo.forEach(element => {
        if (element.value === id) {
            idDepa = element.id_depa;
        }
    });
    return idDepa;
}

export function crearCities(citys, idDepto) {
    var arrCitys = [];
    citys.forEach((element) => {
        if (element.id_depa === idDepto) {
            arrCitys.push({
                label: element.label,
                value: element.value,
            });
        }
    });
    return arrCitys;
}

