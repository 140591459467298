import React, { Component } from 'react';
import { Card } from 'react-bootstrap';
import './form.css';
import MaterialUiForm from './form';
import { Button, ButtonGroup, InputLabel, Switch } from '@material-ui/core';
import { connect } from "react-redux";
import { compose } from 'redux'
import { reduxForm } from 'redux-form';
import KeyboardEventHandler from 'react-keyboard-event-handler';
import { activateRadio } from '../../pages/vivienda/actions';

class CustomReduxForm extends Component {
    render() {
        const { 
            items, 
            formTitle, 
            className, 
            formName, 
            buttons, 
            handleSubmit, 
            card, 
            formSubTitle, 
            submitable,
            anexosStorage, 
            validations,
            invalid, 
            initValid,
            pristine, 
            reset, 
            submitting,
            dynamicOptions,
            optionsInthogar,
            dynamicDisable,
            hiddenDisable,
            hiddenClose,
            charged,
            deleteAnexo,
            deleteFirma,
            handleUpload,
            handleRemove,
            handleUploadF,
            handleRemoveF,
            handleSuma,
            enableDisableArray,
            Hclear,
            activateRadio,
            optionsTable,
            deleteRecurso,
            addInthogar,
            rebootFiles,
        } = this.props;

        return (
                card ? 
                <Card key={`card-${formName}`} className={`card-${className}`}>
                    <Card.Header>
                        {formTitle}
                        <p>{formSubTitle}</p>
                    </Card.Header>
                    <Card.Body>
                        <form tabIndex={0} className={`form-container ${className}`} onSubmit={handleSubmit}>
                            <KeyboardEventHandler
                                handleKeys={['enter']}
                                onKeyEvent={(items)=>handleSubmit({items})}>
                                <MaterialUiForm 
                                    submitable={submitable} 
                                    items={items} 
                                    validations={validations} 
                                    formName={formName}
                                    className={className}
                                    handleSubmit={handleSubmit} 
                                    buttons={buttons}
                                    invalid={invalid}
                                    activateRadio={activateRadio}
                                    hiddenClose={hiddenClose}
                                    deleteAnexo={deleteAnexo}
                                    deleteFirma={deleteFirma}
                                    optionsInthogar={optionsInthogar}
                                    dynamicOptions={dynamicOptions}
                                    dynamicDisable={dynamicDisable}
                                    anexosStorage={anexosStorage}
                                    hiddenDisable={hiddenDisable}
                                    deleteRecurso={deleteRecurso}
                                    rebootFiles={rebootFiles}
                                    handleSuma={handleSuma}
                                    enableDisableArray={enableDisableArray}
                                    addInthogar={addInthogar}
                                    handleUpload={handleUpload}
                                    handleRemove={handleRemove}
                                    handleUploadF={handleUploadF}
                                    handleRemoveF={handleRemoveF}
                                    optionsTable={optionsTable}
                                    Hclear={Hclear}
                                    
                                />
                            </KeyboardEventHandler>
                            {
                            buttons ?   
                            <div className="button-wrapper">
                                <ButtonGroup color="primary" aria-label="primary button group">
                                    {
                                        buttons.map((button,i) => {
                                           
                                            return (
                                            <Button
                                                key={`button-${button.label}`} 
                                                variant={button.variant}
                                                color={button.style}
                                                type={`${submitable ? 'submit' : 'button'}`}
                                                className={button.className}
                                                onClick={button.primary ? (items)=>handleSubmit({items}) : button.onClick}
                                                //disabled={button.disabled}
                                            >
                                                {button.label}
                                            </Button>
                                            )
                                        }) 
                                    }
                                </ButtonGroup>
                            </div> :""
                            // <div>
                            //     <button type="submit" disabled={pristine || submitting}>
                            //     Submit
                            //     </button>
                            //     <button type="button" disabled={pristine || submitting} onClick={reset}>
                            //     Clear Values
                            //     </button>
                            // </div>
                            }
                        </form>
                    </Card.Body>
                </Card>
                : <React.Fragment>
                        <div className="form-wrapper">
                        { formTitle ? 
                            <h2 className="form-title">
                                {formTitle}
                            </h2>
                        : '' }
                        <p className="form-sub-title">
                            {formSubTitle}
                        </p>
                        <form tabIndex={0} className={`form-container ${className}`} onSubmit={handleSubmit}>
                            <KeyboardEventHandler
                                handleKeys={['enter']}
                                onKeyEvent={(items)=>handleSubmit({items})}>
                                <MaterialUiForm 
                                    submitable={submitable} 
                                    items={items} 
                                    validations={validations} 
                                    formName={formName}
                                    className={className}
                                    handleSubmit={handleSubmit} 
                                    buttons={buttons}
                                    optionsInthogar={optionsInthogar}
                                    dynamicOptions={dynamicOptions}
                                    hiddenClose={hiddenClose}
                                    anexosStorage={anexosStorage}
                                    dynamicDisable={dynamicDisable}
                                    hiddenDisable={hiddenDisable}
                                    handleUpload={handleUpload}
                                    optionsTable={optionsTable}
                                    handleSuma={handleSuma}
                                    rebootFiles={rebootFiles}
                                    enableDisableArray={enableDisableArray}
                                    deleteAnexo={deleteAnexo}
                                    deleteFirma={deleteFirma}
                                    invalid={invalid}
                                    deleteRecurso={deleteRecurso}
                                    addInthogar={addInthogar}
                                    activateRadio={activateRadio}
                                    handleRemove={handleRemove}
                                    handleUploadF={handleUploadF}
                                    handleRemoveF={handleRemoveF}
                                    Hclear={Hclear}
                                />
                            </KeyboardEventHandler>
                            {
                            buttons ?   
                            <div className="button-wrapper"> 
                                <ButtonGroup color="primary" aria-label="primary button group">
                                    {

                                        buttons.map((button,i) => {
                                            button.disabled =dynamicDisable && dynamicDisable[button.name] !== undefined ? dynamicDisable[button.name]
                                            : button.disabled;
                                            return (
                                            <Button
                                                key={`button-${button.label}`} 
                                                variant={button.variant}
                                                name={button.name}                                                
                                                type={`${submitable ? 'submit' : 'button'}`}
                                                className={button.className} 
                                                onClick={button.primary ? ()=>handleSubmit({event:button}) : button.onClick}
                                                disabled={button.disabled}
                                               
                                            >   
                                                {button.label}
                                                <InputLabel className="label-button">{button.options}</InputLabel>
                                            </Button>
                                            )
                                        }) 
                                    }
                                </ButtonGroup>
                            </div> :""
                            // <div>
                            //     <button type="submit" disabled={pristine || submitting}>
                            //     Submit
                            //     </button>
                            //     <button type="button" disabled={pristine || submitting} onClick={reset}>
                            //     Clear Values
                            //     </button>
                            // </div>
                            }
                        </form>
                        </div>
                </React.Fragment>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {
        form: props.formName,
        validate: props.validations,
        // other props...
    }
}

export default compose(
    connect(mapStateToProps),
    reduxForm({    
        //other redux-form options...
    })
)(CustomReduxForm);