import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import { Input, Select, Switch, MenuItem, TextField, Button  } from '@material-ui/core';
import { VALIDATE_FIELD } from './constant';
import { connect } from "react-redux";
import { SelectButton } from 'primereact/selectbutton';
import InputLabel from "@material-ui/core/InputLabel";
import { Field } from 'redux-form';

class CustomField extends Component {

    constructor(props) {
        super(props);

        this.changeInput = React.createRef();

        this.state = {
            fieldValue: props.value || '',
            fieldName: props.name || '',
            submitted: false
        }
    }

    componentDidUpdate(nextProps) {
        if(nextProps.fieldValue) {
            this.setState({
                fieldValue: nextProps.fieldValue,
                fieldName: nextProps.fieldName
            });
        }
    };
    
    onChange = (e) => {
        let targetValue = e.target.value;

        this.setState({
            fieldValue: targetValue,
            fieldName: e.target.name
        })
    };

    onSelectionChange = (e) => {
        let targetValue = e.target.value;

        this.setState({
            fieldValue: targetValue,
            fieldName: e.target.name
        })

        this.props.updateAction({
            fieldValue: targetValue,
            fieldName: e.target.name
        })
    };

    onBlur = (e) => {
        const { fieldValue, fieldName } = this.state;
        const flagSubmit = this.props.submitted ? true : false;
        
        if (!flagSubmit) {
            this.props.updateAction({
                fieldValue,
                fieldName
            })
        }
    };

    onToggleChange = (e) => {
        let targetValue = e.target.value;
        const targetName = e.target.name;

        targetValue = typeof targetValue === "boolean" ? targetValue : targetValue === "true";

        this.setState({
            fieldValue: !targetValue,
            fieldName: targetName
        })

        this.props.updateAction({
            fieldValue: !targetValue,
            fieldName: targetName
        })
    }

    onFilterValue = (e) => {
        const { items } = this.props;
        const targetValue = e.target.value;
        const targetName = e.target.name;

        this.setState({
            fieldValue: targetValue,
            fieldName: targetName
        })

        this.props.updateAction({
            fieldValue: targetValue,
            fieldName: targetName, 
            items: items
        })
    }

    selectInput = (type, typeForm, options, value, label, placeholder, name, key, disable, submitted) => {
        let formControl = false;
        const customKey = `input-${label}-${key}`;

        if (type === "toggle" && typeof value !== "boolean") {
            value = value === "true";
        }

        if (submitted) {
            value = '';
        }

        switch (typeForm) {
            case "date":
                formControl =
                    <TextField
                        className={"custom-input calendar"}
                        key={customKey}  
                        name={name} 
                        label={placeholder}
                        value={value} 
                        type={type} 
                        onChange={this.onFilterValue}
                        disabled={disable}
                    />
                break;
            case "spacer":
                    formControl =
                        <div 
                            className={"custom-input spacer"}
                            key={customKey}
                        />
                    break;
            case "filter":
                formControl = 
                    <TextField 
                        className={"custom-input"}
                        key={customKey}  
                        name={name} 
                        label={placeholder}
                        value={value} 
                        type={type} 
                        onChange={this.onFilterValue}
                        disabled={disable}
                    />;
                break;  
            case "area":
                formControl = 
                    <TextField
                        key={customKey} 
                        className="custom-input area-text" 
                        name={name} 
                        multiline
                        rows={4} 
                        as="textarea" 
                        label={label} 
                        type={type} 
                        value={value} 
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                        disabled={disable}
                    />;
                break;    
            case "selections":
                formControl = 
                    <SelectButton 
                        size="medium" 
                        checked={value}
                        name={name}
                        value={value} 
                        label={placeholder}
                        optionValue="code"
                        options={options}
                        onChange={this.onSelectionChange}
                        disabled={disable}
                    >
                        <InputLabel>{options.placeholder}</InputLabel>
                    </SelectButton>;
                break;
            case "toggle" :
                formControl = 
                    <Switch 
                        size="medium" 
                        checked={value}
                        name={name}
                        value={value} 
                        label={placeholder}
                        onChange={this.onToggleChange}
                        disabled={disable}
                    />;
                return formControl;            
            case "select":
                formControl = 
                <Select
                    name={name}
                    key={customKey} 
                    as="select"
                    className="custom-input custom-select mr-sm-2"
                    id="inlineFormCustomSelect"
                    value={value} 
                    onBlur={this.onBlur}
                    onChange={this.onChange}
                    ref={this.changeInput}
                    disabled={disable}
                >   
                    {options.map((type, i) => {
                        return (
                            <MenuItem 
                                key={`option-${i}`}
                                value={type.value}
                                id={`option-${i}`}>
                                    {type.label}
                            </MenuItem>
                        )
                    })}
                </Select>
                break; 
            case "file":
                formControl = 
                <div className="file-container">
                    <label htmlFor="contained-button-file">
                        <Button className="button-file" variant="contained" color="primary" component="span">
                                {placeholder}
                        </Button>
                    </label> 
                    <input
                        className="custom-file"
                        id="contained-button-file"
                        name={name}
                        value={value}
                        multiple
                        type="file"
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                    />
                </div>
            case "multiple":
                
                break;      
            default:
                formControl = 
                    <TextField 
                        className="custom-input"
                        key={customKey}  
                        name={name} 
                        label={placeholder}
                        value={value} 
                        type={type} 
                        onBlur={this.onBlur}
                        onChange={this.onChange}
                        disabled={disable}
                    />;
                break;
        }

        return formControl;
    }

    render() {
        const { 
            placeholder, 
            label, 
            type, 
            typeForm, 
            options,
            key, 
            name,
            disable,
            className,
            submitted,
            updateFields,
            value
        } = this.props;

        const customKey = label ? label.replace(/\s/g, "").toLowerCase() : "";
        const { fieldValue } = this.state;

        return (
            <div key={customKey} className={`form-custom-group ${className}`}>
                <label className="label-name">
                    <span className="content-name">{label}</span>
                </label>
                {this.selectInput(
                    type,
                    typeForm,
                    options,
                    fieldValue,
                    label,
                    placeholder,
                    name,
                    key,
                    disable,
                    submitted,
                    updateFields,
                    value
                )}
            </div >
        );
    }
}


const mapDispachToProps = (dispatch) => {
    return { 
        updateFields: (value) => dispatch({ type: VALIDATE_FIELD, value }),
    };
};

export default connect(
    mapDispachToProps
)(CustomField);