import React from 'react';
import { getFromSession, saveInSession, logout } from '../../controller/session';
import { Button, ButtonGroup } from '@material-ui/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import './auth.css';
import { Redirect } from "react-router-dom";

export function salirForm() {
  logout()
  window.location.replace("https://www.comfenalcovalle.com.co/");///`${process.env.REACT_APP_URL_LOGOUT}${process.env.REACT_APP_ID}`;
  return;

}

function renderForm(callbackPath, urlRedirect, children,) {

  return (
    <div className="auth-wrapper">
      <Redirect to={callbackPath}></Redirect>
      {
        children
      }
      <div className="button-wrapper">
        <ButtonGroup color="primary" aria-label="primary button group">
          <Button onClick={() => salirForm()}>
            Salir
          </Button>
        </ButtonGroup>
      </div>
    </div>
  );
}



function LoginButton({ urlRedirect, callbackPath, children, firebaseUser, colaboradorUser }) {
  const params = window.location.search.slice(1).split('&').reduce((acc, s) => {
    const [k, v] = s.split('=')
    return Object.assign(acc, { [k]: v })
  }, {})

  console.log(" parametros del login", params, colaboradorUser);
  // if (firebaseUser && firebaseUser.user) {
  //   sessionStorage.clear();
  //   saveInSession('authUser', firebaseUser);
  // }
  // else 
  if (params.user && !colaboradorUser) {
    sessionStorage.clear();
    saveInSession('authUser', params);
  }

  if (colaboradorUser) {
    saveInSession('authUserColaborador', colaboradorUser);
  }


  const isAuthenticated = getFromSession('authUser');
  const isInitializatedForm = getFromSession('formInitilizated');

  if ((isAuthenticated && isAuthenticated.user) || isInitializatedForm) {
    return renderForm(callbackPath, urlRedirect, children);
    // } else if (firebaseUser.accessToken === "charging") {
    //   return (
    //     <div className="loading-wrapper">
    //       <LinearProgress />
    //       <div>
    //         Cargando información del usuario ...
    //       </div>
    //     </div>
    //   );
  } else {
    //sessionStorage.clear();
    if (colaboradorUser) {
      return renderForm(callbackPath, urlRedirect, children);
    } else {
      return window.location.href = `${process.env.REACT_APP_URL_LOGIN}${process.env.REACT_APP_ID}`;
    }
  }


}



export default LoginButton;