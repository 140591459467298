import React, { Component } from 'react';
import "./style.css";
import { connect } from "react-redux";
import MyDocument from '../exportpdf/mydocument';

import { getConstruirBuscarOportunidad, getCurrentTab, getDataGraph1, getDataGraph2, getDataGraph3, getError, getEvent, getFormValid, getHiddenDisable, getItemsPdf, getOcultaBoton, getOpenNewPostulation, getShowSnackBar, getVisualizarPdf } from './selector';
import { LOGIN_ACTION, CHART_DATA, data, dataPhone, CARGAR_EVENT, energyConsumption, contactEstadisticForm, getLoginButton, ACTION_FILTRAR, CLOSE_MODAL_VALID, TABLE_OPTIONS_EMPRESA, TITLE_TABLE_CONTACT, TITLE_GRAF2_CONTACT, TITLE_PIE_CONTACT, TITLE_GRAF1_CONTACT, TITLE_GRAF2_VIVIENDA, TITLE_GRAF1_VIVIENDA, TITLE_GRAF3_VIVIENDA, TITLE_GRAF4_VIVIENDA, TITLE_GRAF5_VIVIENDA, TABLE_OPTIONS_VIVIENDA, TITLE_TABLE_VIVIENDA, viviendaEstadisticForm, oportunidadForm, BUSCAR_OPORTUNIDAD, VISUALIZAR_PDF, ACTUALIZAR_OPORTUNIDAD, getButtonLogin, CLOSE_DIALOG_PDF, newPostulationForm, NEW_POSTULATION_VIVIENDA, FORM_NAME_NEW_VIVIENDA_POSTULATION } from "./constants";

import {
    BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip,
} from 'recharts';
import {
    LineSeries,
    Legend,

} from '@devexpress/dx-react-chart-material-ui';

import {
    curveCatmullRom,
    curveStep,
    line,
} from 'd3-shape';
import { scalePoint } from 'd3-scale';

import { withStyles } from '@material-ui/core/styles';
import CustomReduxForm from "../../components/materialform/customform";
import CircularProgress from '@material-ui/core/CircularProgress';
import { LineChartDemo } from './chartPrime';
import { DataTableCrudDemo } from './tableReact';
import { PieChartDemo } from './pierChartPrime';
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Modal from '@material-ui/core/Modal';
import { Dialog } from 'primereact/dialog';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Snackbar from "@material-ui/core/Snackbar";
import { encode } from '../../controller/utils';

class DashVivienda extends Component {
    componentDidMount() {
    }

    Line = props => (
        <LineSeries.Path
            {...props}
            path={line()
                .x(({ arg }) => arg)
                .y(({ val }) => val)
                .curve(curveStep)}
        />
    );

    validate = (values) => {
        let errors = {
            personalizadoVivienda: {
                fechaEnd: "",
                fechaStart: ""
            }

        };

        if (values.personalizadoVivienda) {
            if (!values.personalizadoVivienda.fechaEnd) {
                errors.personalizadoVivienda.fechaEnd = "Campo obligatorio";
            }
            else if (values.personalizadoVivienda.fechaEnd) {
                var date = new Date();
                const fechaSis = Date.parse(values.personalizadoVivienda.fechaEnd);

                if (
                    fechaSis < Date.parse("2021-06-01") ||
                    fechaSis > date
                ) {
                    errors.personalizadoVivienda.fechaEnd = "Fecha seleccionada invalida";
                }
            }

            if (!values.personalizadoVivienda.fechaStart) {
                errors.personalizadoVivienda.fechaStart = "Campo obligatorio";
            } else if (values.personalizadoVivienda.fechaStart) {
                var date = new Date();
                const fechaSis = Date.parse(values.personalizadoVivienda.fechaStart);

                if (
                    fechaSis < Date.parse("2021-06-01") ||
                    fechaSis > date
                ) {
                    errors.personalizadoVivienda.fechaStart = "Fecha seleccionada invalida";
                }
            }



        }
        return errors;
    }

    showSnackBar(open, message) {
        return (
            <Snackbar open={open} autoHideDuration={1} className="ocultar">
                <Alert severity="success">{message}</Alert>
            </Snackbar>
        );
    }

    modalSubmitValid(props) {
        const { formValid, closeModalValid, error, ocultaBoton } = props;
        return (
            <Modal
                disablePortal
                disableEnforceFocus
                disableAutoFocus
                open
            >
                <div className="messageOportunidad">
                    <React.Fragment>
                        {error ?
                            <Alert className="alertOportunidadDanger" >
                                {formValid}

                            </Alert>
                            : <Alert className="alertOportunidadSuccess">
                                {formValid}
                            </Alert>
                        }
                    </React.Fragment>

                    <div>
                        {ocultaBoton ?
                            ""
                            : <Button
                                className='buttonFinalizar'
                                variant="text"
                                color="default"
                                onClick={() => closeModalValid()}
                            >
                                Continuar
                            </Button>
                        }


                    </div>

                </div>
            </Modal>
        );
    }

    renderModal(open, body, onHide, headerTitle, id) {
        return (
            <Dialog
                header={headerTitle}
                visible={open}
                modal
                closeOnEscape
                id={id}
                onHide={onHide}
                style={{ width: "50vw" }}
            >
                {body}
            </Dialog>
        );
    }

    renderFormEditar() {
        const { itemsPdf, actualizarPdf } = this.props

        return (
            <div className="Dialog-pdfOportunidad">

                {itemsPdf !== false ?
                    <div>
                        <TableContainer className="tableEmpresa">
                            <Table
                                className={"classes.table"}
                                aria-label="simple table"
                            >
                                <TableHead >
                                    <TableRow>
                                        <TableCell align="right">Tipo y número de documento</TableCell>
                                        <TableCell align="right">Nombre completo</TableCell>
                                        <TableCell align="right">Fecha de solicitud</TableCell>
                                        <TableCell align="right">N° Oportunidad</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="right">{`${itemsPdf?.step1?.datospostulante?.documentType} ${itemsPdf?.step1?.datospostulante?.numberId}`}</TableCell>
                                        <TableCell align="right">{`${itemsPdf?.step1?.datospostulante?.firstName} ${itemsPdf?.step1?.datospostulante?.middleName} ${itemsPdf?.step1?.datospostulante?.lastName}`}</TableCell>
                                        <TableCell align="right">{itemsPdf?.fecha}</TableCell>
                                        <TableCell align="right">{itemsPdf.oportunidad}</TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    : ""}
                <div>
                    <Button
                        //variant={field.variant}
                        color={"primary"}
                        type={`${true ? "submit" : "button"}`}
                        className={"button-wrapper"}
                        onClick={() => window.open(`exportpdf/nuevopdf`, "Popup", "toolbar=no, location=no, statusbar=no, menubar=no, scrollbars=1, resizable=0,")}
                    >{`Previsualizar PDF`}</Button>
                    <Button
                        //variant={field.variant}
                        color={"primary"}
                        type={`${true ? "submit" : "button"}`}
                        className={"button-wrapper"}
                        onClick={() => actualizarPdf()}
                    >{`Anexar PDF a oportunidad`}</Button>
                </div>
            </div>
        )

    }


    render() {
        const {
            actionFilter,
            dataGraph2,
            hiddenDisable,
            formValid,
            searhOportunidad,
            visualizarPdf,
            actualizarPdf,
            abrirVerPdf,
            closeDialogPdf,
            showSnackBar,
            construirBuscarOportunidad,
            itemsPdf,
            newPostulationAction,
            openNewPostulation,
        } = this.props;


        openNewPostulation && window.open(`${window.location.origin}/vivienda?${encode("postulacion=colaborador" + "&data=" + openNewPostulation)}`)
        return (

            <div className='contacto-wrapper'>
                {this.showSnackBar(
                    showSnackBar,
                    "Sucess"
                )}
                {/* <div>
                    {abrirVerPdf ? window.open(`exportpdf/${JSON.stringify(itemsPdf)}`, "_blank") : ""}
                </div> */}
                {this.renderModal(
                    abrirVerPdf,
                    this.renderFormEditar(),
                    closeDialogPdf,
                    'Información oportunidad CRM',
                    "infoOportunidad"
                )}



                {formValid ? this.modalSubmitValid(this.props) : ''}
                <div className="section-Contacto">
                    <React.Fragment>
                        <CustomReduxForm
                            formName={FORM_NAME_NEW_VIVIENDA_POSTULATION}
                            items={newPostulationForm(newPostulationAction)}
                            submitable={false}
                            validations={this.validate}
                            hiddenDisable={hiddenDisable}
                            handleSubmit={() => newPostulationAction()}
                            buttons={false}
                        />
                    </React.Fragment>
                </div>
                {construirBuscarOportunidad ?
                    <div className="section-Contacto">
                        <React.Fragment>
                            <CustomReduxForm
                                formName="buscandoOportunidadForm"
                                items={oportunidadForm(searhOportunidad)}
                                submitable={false}
                                validations={this.validate}
                                hiddenDisable={hiddenDisable}
                                handleSubmit={() => searhOportunidad()}
                                buttons={false}

                            />
                        </React.Fragment>
                    </div>
                    : ""}
                <div className="section-Contacto">
                    <React.Fragment>
                        <CustomReduxForm
                            formName="viviendaEstadisticForm"
                            items={viviendaEstadisticForm(actionFilter
                            )}
                            submitable={false}
                            actionFilter={actionFilter}
                            validations={this.validate}
                            hiddenDisable={hiddenDisable}
                            handleSubmit={() => actionFilter()
                            }
                            buttons={false}

                        />
                    </React.Fragment>
                </div>
                <div className="section-Contacto">
                    <LineChartDemo
                        titleLine={TITLE_GRAF1_VIVIENDA}
                        dataGraph1={dataGraph2 ? dataGraph2.graph1 : false}
                    />
                </div>
                <div className="section-Contacto">
                    <LineChartDemo
                        titleLine={TITLE_GRAF2_VIVIENDA}
                        dataGraph1={dataGraph2 ? dataGraph2.graph2 : false}
                    />
                </div>
                <div className="section-Contacto">
                    <PieChartDemo
                        titlePie={TITLE_GRAF3_VIVIENDA}
                        dataGraph1={dataGraph2 ? dataGraph2.pie : []}
                    />
                </div>
                <div className="section-Contacto">
                    <LineChartDemo
                        titleLine={TITLE_GRAF4_VIVIENDA}
                        dataGraph1={dataGraph2 ? dataGraph2.graph4 : false}
                    />
                </div>
                <div className="section-Contacto">
                    <LineChartDemo
                        titleLine={TITLE_GRAF5_VIVIENDA}
                        dataGraph1={dataGraph2 ? dataGraph2.graph5 : false}
                    />
                </div>
                <div className="section-Contacto">
                    <DataTableCrudDemo
                        dataTable={TABLE_OPTIONS_VIVIENDA}
                        dataGraph1={dataGraph2 ? dataGraph2.table : []}
                        titleTable={TITLE_TABLE_VIVIENDA}
                    />

                </div>
            </div>




        );
    }
}

const mapStateToProps = state => {
    return {
        dataGraph2: getDataGraph2(state),
        hiddenDisable: getHiddenDisable(state),
        formValid: getFormValid(state),
        abrirVerPdf: getVisualizarPdf(state),
        itemsPdf: getItemsPdf(state),
        error: getError(state),
        ocultaBoton: getOcultaBoton(state),
        showSnackBar: getShowSnackBar(state),
        construirBuscarOportunidad: getConstruirBuscarOportunidad(state),
        openNewPostulation: getOpenNewPostulation(state),
    };

};

const mapDispachToProps = dispatch => {
    return {

        searhOportunidad: (value) => dispatch({ type: BUSCAR_OPORTUNIDAD, value }),
        visualizarPdf: (value) => dispatch({ type: VISUALIZAR_PDF, value }),
        actualizarPdf: (value) => dispatch({ type: ACTUALIZAR_OPORTUNIDAD, value }),
        loginAction: (value) => dispatch({ type: LOGIN_ACTION, value }),
        actionFilter: (value) => dispatch({ type: ACTION_FILTRAR, value }),
        closeModalValid: (value) => dispatch({ type: CLOSE_MODAL_VALID, value }),
        closeDialogPdf: (value) => dispatch({ type: CLOSE_DIALOG_PDF, value }),
        newPostulationAction: (value) => dispatch({ type: NEW_POSTULATION_VIVIENDA, value }),

    };
};

export default connect(
    mapStateToProps,
    mapDispachToProps
)(DashVivienda);