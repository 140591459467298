import React, { Component } from 'react';
import "./style.css";
import { connect } from "react-redux";
import LoginComponent from "./login";
import { getLoginFormValues, getCurrentTab, getMessage, getSessionUser, getShowSnackBar } from './selector';
import { getFromSession } from '../../controller/session';
import { LOGIN_ACTION, LOGOUT_ACTION, CHANGE_TAB_ACTION, LOAD_CATALOG, CLOSE_MODAL_VALID, VALIDATE_TYPE_USER } from "./constants";
import Dashboard from './dashboard';
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Modal from '@material-ui/core/Modal';
import LoginButton from "../../components/authlogin/authlogin2";

class AdminComponent extends Component {
  componentDidMount() {
    const { loadCatalog } = this.props;
    loadCatalog();
  }

  modalSubmitValid(props) {
    const { message, closeModalValid } = props;

    return (
      <Modal
        disablePortal
        disableEnforceFocus
        disableAutoFocus
        open
      >
        <div className="messageSucess">
          <React.Fragment>
            <Alert className="alert danger" >
              {message}
            </Alert>
          </React.Fragment>

          <div>
            <Button
              className='buttonFinalizar'
              variant="text"
              color="default"
              onClick={() => closeModalValid()}
            >
              Continuar
            </Button>

          </div>

        </div>
      </Modal>
    );
  }
  showSnackBar(open, message) {
    return (
      <Snackbar open={open} autoHideDuration={2} className="ocultar">
        <Alert severity="success">{message}</Alert>
      </Snackbar>
    );
  }

  render() {
    const { loginAction, cuerrentTab, logoutAction, handleChange, message, sessionUser, showSnackBar, validarTipoUser } = this.props;
    const authUser = getFromSession('authUserColaborador');
    if (authUser?.email) {
      console.log(authUser, "usuario")
      validarTipoUser(authUser);
    }

    return (
      <div>
        <LoginButton
          urlRedirect={`${window.location.origin}/informes`}
          callbackPath="/informes"
        >
          <div>{message ? this.modalSubmitValid(this.props) : ''}</div>
          {this.showSnackBar(
            showSnackBar,
            "Sucess"
          )}
          <Dashboard handleChange={handleChange} tab={cuerrentTab} logoutAction={logoutAction} />
        </LoginButton>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loginFormValues: getLoginFormValues(state),
    cuerrentTab: getCurrentTab(state),
    message: getMessage(state),
    sessionUser: getSessionUser(state),
    showSnackBar: getShowSnackBar(state),
  };
};

const mapDispachToProps = dispatch => {
  return {
    loadCatalog: (value) => dispatch({ type: LOAD_CATALOG, value }),
    loginAction: (value) => dispatch({ type: LOGIN_ACTION, value }),
    logoutAction: (value) => dispatch({ type: LOGOUT_ACTION, value }),
    handleChange: (event, newValue) => dispatch({ type: CHANGE_TAB_ACTION, data: { event, newValue } }),
    closeModalValid: (value) => dispatch({ type: CLOSE_MODAL_VALID, value }),
    validarTipoUser: (value) => dispatch({ type: VALIDATE_TYPE_USER, value }),
  };
};

export default connect(
  mapStateToProps,
  mapDispachToProps
)(AdminComponent);