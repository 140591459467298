import {
    CHANGE_TAB_ACTION,
    LOGIN_ERROR,
    LOGIN_SUCESS,
    CARGAR_EVENT,
    DATA_GRAPH1,
    DATA_GRAPH2,
    DATA_GRAPH3,
    HIDDEN_DISABLES,
    CLOSE_MODAL_VALID,
    FORM_VALID,
    LOAD_CATALOG_SUCCESS,
    CARGANDO_MODAL,
    SAVE_ITEMS_PDF,
    BUSCAR_OPORTUNIDAD,
    VISUALIZAR_PDF,
    SAVE_BASE64_PDF,
    CLOSE_DIALOG_PDF,
    CONSTRUIR_BUSCAR_OPORTUNIDAD,
    SAVE_CONTEXT_AUTH0,
    OPEN_NEW_POSTULATION_VIVIENDA
} from './constants';

export const initialState = {
    message: false,
    messageType: false,
    currentTab: 0,
    cargarEvent: false,
    dataGraph1: false,
    dataGraph2: false,
    dataGraph3: false,
    hiddenDisable: {
        personalizadoFiltro: true,
        personalizadoVivienda: true,
        personalizadoDeportes: true,
    },
    formValid: false,
    dynamicOptions: {},
    sessionUser: false,
    cargandoModal: false,
    construirBuscarOportunidad: true,
    visualizarPdf: false,
    openNewPostulation: false,
    itemsPdf: false,
    base64Pdf: false,
    error: true,
    ocultaBoton: false,
    catalogo: false,
    showSnackBar: false,
    contextAuth0: false,
};

const reducer = (state = initialState, action) => {
    const newState = { ...state };
    const { payload, type, value } = action;
    newState.message = false;
    newState.messageType = false;


    switch (type) {
        case LOGIN_SUCESS:
            newState.message = payload.message;
            newState.messageType = LOGIN_SUCESS;
            newState.sessionUser = payload.state;

            return {
                ...newState
            };

        case LOGIN_ERROR:
            newState.message = payload;
            newState.messageType = LOGIN_ERROR;

            return {
                ...newState
            };

        case CARGAR_EVENT:
            newState.cargarEvent = payload;

            return { ...newState };

        case CARGANDO_MODAL:
            newState.cargandoModal = payload;
            return { ...newState };

        case CONSTRUIR_BUSCAR_OPORTUNIDAD:
            newState.construirBuscarOportunidad = payload;
            return { ...newState };

        case SAVE_CONTEXT_AUTH0:
            console.log("si, llega aca")
            newState.contextAuth0 = payload;
            return { ...newState };

        case DATA_GRAPH1:
            newState.dataGraph1 = payload;

            return { ...newState };

        case DATA_GRAPH2:
            newState.dataGraph2 = payload;

            return { ...newState };

        case DATA_GRAPH3:
            newState.dataGraph3 = payload;

            return { ...newState };

        case HIDDEN_DISABLES:
            const item = payload.field;
            newState.hiddenDisable[item] = payload.state;
            newState.showSnackBar = payload.state;

            return {
                ...newState
            }

        case CHANGE_TAB_ACTION:
            const { data: { newValue } } = action;
            newState.currentTab = newValue;
            return {
                ...newState
            }

        case LOAD_CATALOG_SUCCESS:
            newState.catalogo = payload;
            newState.dynamicOptions = payload;
            return {
                ...newState
            }

        case FORM_VALID:
            if (payload?.state) {
                newState.error = payload.error;
                newState.formValid = payload.state;
                newState.ocultaBoton = payload.ocultaBoton;
                if (payload.finaliza) {
                    newState.itemsPdf = false;
                    newState.visualizarPdf = false;
                }

            } else {
                newState.formValid = payload;
            }
            return {
                ...newState
            }

        case SAVE_ITEMS_PDF:
            newState.itemsPdf = payload;
            newState.visualizarPdf = true;
            return {
                ...newState
            }

        case VISUALIZAR_PDF:
            newState.visualizarPdf = true;
            return {
                ...newState
            }

        case OPEN_NEW_POSTULATION_VIVIENDA:
            newState.openNewPostulation = payload;
            return { ...newState }

        case CLOSE_DIALOG_PDF:
            newState.visualizarPdf = false;
            return {
                ...newState
            }

        case SAVE_BASE64_PDF:
            newState.base64Pdf = payload;
            return {
                ...newState
            }

        case CLOSE_MODAL_VALID:
            newState.formValid = false;
            newState.message = false;
            return {
                ...newState
            }

        default:
            return {
                ...newState
            };
    }
}

export default reducer;